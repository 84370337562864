import {APP_DATA_STATE_NAME} from '@app/core/store/app-data.selector';
import {CommonReducer} from '@lib/cms/store/common/common.reducer';
import {COMMON_STATE_NAME} from '@lib/cms/store/common/common.selector';
import {ICommonState} from '@lib/cms/store/common/common.state';
import {routerReducer, RouterReducerState} from '@ngrx/router-store';
import {SHARED_STATE_NAME} from '@lib/store/shared.selector';
import {SharedReducer} from '@lib/store/shared.reducer';
import {ISharedState} from '@lib/store/shared.state';
import {CMS_STATE_NAME} from '@lib/cms/store/cms/cms.selector';
import {CmsReducer} from '@lib/cms/store/cms/cms.reducer';
import {ICmsState} from '@lib/cms/store/cms/cms.state';
import {IAppDataState} from '@app/core/store/app-data.state';
import {AppDataReducer} from '@app/core/store/app-data.reducer';

export interface AppState {
    [CMS_STATE_NAME]?: ICmsState;
    [SHARED_STATE_NAME]?: ISharedState;
    [APP_DATA_STATE_NAME]?: IAppDataState;
    [COMMON_STATE_NAME]?: ICommonState;
    router: RouterReducerState;
}

export const appReducer = {
    [CMS_STATE_NAME]: CmsReducer,
    [SHARED_STATE_NAME]: SharedReducer,
    [APP_DATA_STATE_NAME]: AppDataReducer,
    [COMMON_STATE_NAME]: CommonReducer,
    router: routerReducer
};
