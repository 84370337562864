import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollToService {
  constructor() {
  }

  scrollTo(el: string | HTMLElement) {
    let elementRef: HTMLElement;

    if (typeof el === 'string') {
      elementRef = window.document.querySelector(el) as HTMLElement;
    } else {
      elementRef = el;
    }

    if (elementRef) {
      elementRef.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
