import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from '../form/base-component/base-form.component';
import { NgxSignaturePadComponent, NgxSignatureOptions } from '@eve-sama/ngx-signature-pad';
import { EntryFields } from 'contentful';
import Object = EntryFields.Object;

@Component({
  selector: 'lib-esign',
  templateUrl: './esign.component.html',
  styleUrls: ['./esign.component.scss']
})
export class EsignComponent extends BaseFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('signature') signature: NgxSignaturePadComponent;
  public options: NgxSignatureOptions = {} as NgxSignatureOptions;
  showClear: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.options = Object.assign({}, this.item?.extendedParams.options);
  }

  clear() {
    this.signature.clear();
    this.showClear = false;
  }

  onBeginSign(): void {
      this.showClear = !this.signature.isEmpty();
  }

  /**
   *
   */
  onEndSign(): void {
    this.formControl.setValue(this.signature.toDataURL());
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
