<lib-alerts [msgObj]="(alert$ | async)"></lib-alerts>
<div class="modal-content" *ngIf="data as d">
    <div class="modal-header">
        <h4>
            <i class="{{d.cms.titleIcon}}" *ngIf="d.cms.titleIcon"></i>
            {{d.cms.title}}
        </h4>
        <i class="pi pi-times modal-close-icon" (click)="close(d.data.actions)" (keyup.enter)="close(d.data.actions)"
           tabindex="0"></i>
    </div>
    <form (ngSubmit)="onSubmit('', d.data.actions)" [formGroup]="fg" autocomplete="off" class="form">
        <markdown
                [data]="data.data.data?.schedulePaymentExists ? d.cms.extendedParams['noPaymentScheduleExistsMessage'] :d.cms.body"
                *ngIf="d.cms.body" class="modal-description"></markdown>
        <div class="modal-form-fields">
            <lib-lookup class="form-field" [group]="fg" [item]="paymentMethodOption"
                        *ngIf="paymentMethodOption?.option?.values"></lib-lookup>
        </div>
        <div class="data" *ngIf="data?.data as d">
            <ng-container *ngIf="d.data?.type">
                <div>
                    <div class="data-label">{{d.parentCms['type'].label}}</div>
                    <span>{{d.data?.type}}</span>
                </div>
                <div>
                    <div class="data-label">{{d.parentCms['accountNumber'].label}}</div>
                    <span>{{d.data?.accountNumber  | dataFormat: {
                        model: {
                            format: 'mask:-4:*'
                        }
                    } }}</span>
                </div>
                <div>
                    <div class="data-label">{{d.parentCms['routingOrCard'].label}}</div>
                    <span>{{d.data?.routingOrCard}}</span>
                </div>
            </ng-container>
        </div>
        <div class="modal-actions">
            <lib-button
                    [label]="d.cms.formFields['submit'].label"
                    class="action"
                    [klass]="d.cms.formFields['submit'].klass"
                    [loading]="loading"
            ></lib-button>
        </div>
    </form>
</div>
