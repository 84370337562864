import {RouterStateUrl} from './custom-serializer';
import {RouterReducerState} from '@ngrx/router-store';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const getCurrentRoute = createSelector(getRouterState, (router) => {
    return router.state;
});

export const getCurrentUrl = createSelector(getRouterState, (router) => {
    return router && router.state && router.state.url.substring(1);
});

export const getRouteParams = createSelector(getRouterState, (router) => {
    return router && router.state && router.state.params;
});

export const getRouteQueryParams = createSelector(getRouterState, (router) => {
    return router && router.state && router.state.queryParams;
});
