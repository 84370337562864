import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IApplicationResponse} from '@lib/models/page.model';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import { APP_PAGE_ROUTES } from '@app/core/models/core.model';

@Injectable({
    providedIn: 'root'
})
export class AppApiService {
    constructor(private http: HttpClient, private api: ApiService) {
    }

    getApplication(id: string): Observable<any> {
        const url = this.api.buildUrl('application');
        return this.http.get(`${url}/${id}`);
    }

    postApplication(formData): Observable<IApplicationResponse> {
        const url = this.api.buildUrl('application');
        return this.http.post(url, formData);
    }

    postPage(name, formData): Observable<IApplicationResponse> {
        const url = this.api.buildUrl(name);
        return this.http.post(url, formData);
    }

    pageSchema(action: string, verb: string, item?: string, domain = 'application') {
        const url = this.api.buildUrl('');
        const fragment = action ? item ? `${action}/item/${verb}` : `${action}/${verb}` : `${verb}`;
        return this.http.get(`${url}schemas/${domain}/${fragment}`);
    }

    getPageData(id: number | string, action: string, itemId?: string) {
        const url = this.api.buildUrl('application');
        if (itemId) {
            return this.http.get(`${url}/${id}/${action}/${itemId}`);
        } else {
            return this.http.get(`${url}/${id}/${action}`);
        }
    }

    submitPage(id: number | string, action: string, body: object, verb: string) {
        const url = this.api.buildUrl('application');
        return this.http[verb](`${url}/${id}/${action}`, body);
    }

    getData(path: string, ignoreApiHost = false): Observable<any> {
        const url = ignoreApiHost ? path : this.api.buildLookupUrl(path);
        return this.http.get(`${url}`);
    }

    getDataWithHeaders(path: string, ignoreApiHost : boolean, headers?: { [header: string] : string }) : Observable<any> {
        if (!headers) return this.getData(path, ignoreApiHost);
        
        const url = ignoreApiHost ? path : this.api.buildLookupUrl(path);
        return this.http.get(`${url}`,  { headers });

    }

    getUrl(path: string, ignoreApiHost = false): string {
        return ignoreApiHost ? path : this.api.buildLookupUrl(path);
        // return this.http.get(`${url}`);
    }

    getDocumentByUrl(url, isAbsolute = true, isBlob = true): any {
        const base = isAbsolute ? '' : this.api.buildUrl('');
        if (isBlob) {
            return this.http.get(`${base}${url}`, {responseType: 'blob', observe: 'response'});
        } else {
            return this.http.get(`${base}${url}`, {responseType: 'json', observe: 'response'});
        }
    }

    getHtml(url, isAbsolute?: boolean) {
        const base = isAbsolute ? '' : this.api.buildUrl('');
        return this.http.get(`${base}${url}`, {responseType: 'text'});
    }

    getDocument(appId, docId) {
        const url = this.api.buildUrl('application');
        return this.http.get(`${url}/${appId}/review-docs/${docId}`, {responseType: 'blob'});
    }

    putDocument(appId, docId, body) {
        const url = this.api.buildUrl('application');
        return this.http.put(`${url}/${appId}/review-docs/${docId}`, body);
    }

    putDocumentStatus(appId, docId, urlParam, body) {
        const url = this.api.buildUrl('application');
        return this.http.put(`${url}/${appId}/documents/${docId}/${urlParam}`, body);
    }

    getActiveApp(oDataQuery?: string) {
        const url = this.api.buildUrl('odata/applications');
        return this.http.get(`${url}${oDataQuery}`);
    }
}
