import {Injectable, Injector} from '@angular/core';
import {ContentfulService} from '../handles/contentful.service';
import {StrapiService} from '../handles/strapi.service';
import {ConfigService} from '../../services/config.service';
import {Observable} from 'rxjs';
import {IGlobal} from '../models/cms.model';
import {CmsVendor} from '../models/cmsVendor.model';

/**
 * Key should be an exact match and configurable
 */
const cmsInstanceMap = {
    contentful: ContentfulService,
    strapi: StrapiService
};

@Injectable({
    providedIn: 'root'
})
export class CmsService {
    constructor(private injector: Injector, private cs: ConfigService) {
    }

    /**
     * Get instance configured from an available CMS Instances
     */
    getInstance(): CmsVendor {
        return this.injector.get<any>(cmsInstanceMap[this.cs.get('cmsHandle')]);
    }

    /**
     * Get content from CMS hangle
     */
    getGlobalContent(type?: string, isCollection?: boolean): Observable<IGlobal> {
        return this.getInstance().getGlobalContent(type, isCollection);
    }

    /**
     * Get content from CMS hangle
     */
    getContent(type?: string, isCollection?: boolean): Observable<IGlobal> {
        return this.getInstance().getContent(type, isCollection);
    }

    getPathDetails(pathName: string, nestedParams?: string[]) {
        return this.getInstance().getPathDetails(pathName, nestedParams);
    }

    getModalDetails(pathName: string, nestedParams?: string[]) {
        return this.getInstance().getModalDetails(pathName, nestedParams);
    }
}
