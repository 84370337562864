import {Injectable} from '@angular/core';
import {AppState} from '@app/app.state';
import {
    loadDocumentsContent,
    loadDocumentsContentSuccess,
    loadOffersContent,
    loadOffersContentSuccess
} from '@app/core/store/app-data.actions';
import {getDocumentsContent, getOffersContent} from '@app/core/store/app-data.selector';
import {CMS} from '@lib/cms/models/cms.model';
import {CmsService} from '@lib/cms/services/cms.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {switchMap, map, withLatestFrom} from 'rxjs';

@Injectable()
export class AppDataEffects {
    getDocumentsContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadDocumentsContent),
            withLatestFrom(this.store.select(getDocumentsContent)),
            // map(() => loadGlobalContentSuccess({}))
            switchMap(() => {
                return this.cmsService.getContent(CMS.DOCUMENTS_CONTENT, true).pipe(
                    map((content) => {
                        return loadDocumentsContentSuccess({content});
                    })
                );
            })
        );
    });

    getOffersContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadOffersContent),
            withLatestFrom(this.store.select(getOffersContent)),
            // map(() => loadGlobalContentSuccess({}))
            switchMap(() => {
                return this.cmsService.getContent(CMS.OFFERS_CONTENT).pipe(
                    map((content) => {
                        return loadOffersContentSuccess({content});
                    })
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private cmsService: CmsService,
        private store: Store<AppState>
    ) {
    }
}
