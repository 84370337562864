import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private source = new BehaviorSubject<any>('');
  message = this.source.asObservable();

  constructor() {
  }

  setMessage(obj: any) {
    this.source.next(obj);
  }

  clearMessage() {
    this.source.next(null);
  }
}
