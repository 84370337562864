import {createReducer, on} from '@ngrx/store';
import {
    clearAlertMessage,
    setAlertMessage, setAppConfig, setApplication,
    setAuthState,
    setCurrentPage,
    setLoadingSpinner,
    setLoanSelected,
    setMobileMode
} from './shared.actions';
import {initialState} from './shared.state';


const _sharedReducer = createReducer(
    initialState,
    on(setLoadingSpinner, (state, action) => {
        return {
            ...state,
            showLoading: action.status
        };
    }),
    on(setMobileMode, (state, action) => {
        return {
            ...state,
            mobileMode: action.status
        };
    }),
    on(setAuthState, (state, action) => {
        return {
            ...state,
            authenticated: action.status,
            credentialsExist: action.credentialsExist
        };
    }),
    on(setAlertMessage, (state, action) => {
        return {
            ...state,
            alert: {
                errorCode: action.errorCode,
                detail: action.detail,
                severity: action.severity,
                summary: action.summary,
                data: action.data
            }
        };
    }),
    on(clearAlertMessage, (state, action) => {
        return {
            ...state,
            alert: {}
        };
    }),
    on(setCurrentPage, (state, action) => {
        return {
            ...state,
            currentPage: action.currentPage
        };
    }),
    on(setAppConfig, (state, action) => {
        return {
            ...state,
            appConfig: <any>action
        };
    }),
    on(setApplication, (state, action) => {
        return {
            ...state,
            ...action
        };
    }),
    on(setLoanSelected, (state, action) =>
        {
            return { 
                ...state,
                loanSelected: action.status,
            }
        }
    )
);

export function SharedReducer(state, action) {
    return _sharedReducer(state, action);
}
