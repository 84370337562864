import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppState} from '@app/app.state';
import {DOCUMENT_UPLOAD_STATUS} from '@app/core/models/core.model';
import {clearPageContent} from '@lib/cms/store/cms/cms.actions';
import {getGlobalContent} from '@lib/cms/store/cms/cms.selector';
import {BasePageComponent} from '@lib/components/base-page/base-page.component';
import {ALERT_TYPES, IAlert} from '@lib/models/lib.model';
import {AppApiService} from '@lib/services/app-api.service';
import {Store} from '@ngrx/store';
import {IPageResponse} from '@lib/models/page.model';
import {getRouteParams} from '@lib/router/router.selector';
import {clearAlertMessage, setAlertMessage} from '@lib/store/shared.actions';
import {EntryFields} from 'contentful';
import {Observable} from 'rxjs';
import Object = EntryFields.Object;

@Component({
    selector: 'app-upload-documents',
    templateUrl: './upload-documents.component.html',
    styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent extends BasePageComponent implements OnInit, OnDestroy {
    STATUS = DOCUMENT_UPLOAD_STATUS;
    globalContent$: Observable<any>;
    routeParams$: Observable<any>;
    routeParams: {id: string, page: string};
    isReviewMode = null;
    loading: boolean;
    groups: string[];
    uploadFiles = {};

    constructor(private store$: Store<AppState>, private appApiService: AppApiService) {
        super();
    }

    ngOnInit(): void {
        this.globalContent$ = this.store$.select(getGlobalContent);
        this.routeParams$ = this.store$.select(getRouteParams);

        this.routeParams$.subscribe(route => {
            this.routeParams = route;
            this.getDocumentStatus();
        });
    }

    checkDocumentsStatus() {
        this.groups = Object.keys(this.data);
        let docs = [];
        this.groups.forEach((key) => {
            docs = [...docs, ...this.data[key]['documents']];
        });

        this.isReviewMode = !docs.filter(doc => doc.status === this.STATUS.INCOMPLETE).length;
    }

    /**
     * contains list of File
     * @param e
     */
    onSelect(files) {
        this.uploadFiles = {...this.uploadFiles, ...files};
        this.store$.dispatch(clearAlertMessage());
    }

    /**
     * Custom page submit
     */
    onSubmit() {
        if (this.loading) {
            return false;
        }

        const docTypes = Object.keys(this.uploadFiles);
        if (!docTypes.length) {
            this.store$.dispatch(setAlertMessage({
                errorCode: 0,
                detail: this.cmsPage.extendedParams.missingUploadFile,
                severity: ALERT_TYPES.ERROR,
                summary: ALERT_TYPES.ERROR
            } as IAlert));

            return false;
        }

        this.store$.dispatch(clearAlertMessage());
        this.loading = true;
        const formData: FormData = new FormData();
        const data = [];
        docTypes.forEach(docType => {
            const files = this.uploadFiles[docType];
            files.forEach(file => {
                const item = {
                    fileName: file['name'],
                    documentType: file['documentName']
                };
                data.push(item);
                formData.append('file', file);
            });
        });

        formData.append('data', JSON.stringify(data));

        this.appApiService.submitPage(this.routeParams.id, this.routeParams.page, formData, 'put')
            .subscribe((response: IPageResponse) => {
                this.uploadFiles = {};
                this.data = response.data;
                this.checkDocumentsStatus();
                this.loading = false;
            });

        return true;
    }

    getDocumentStatus() {
        if (this.routeParams.id && this.routeParams.page) {
            this.appApiService.getPageData(this.routeParams.id, this.routeParams.page)
                .subscribe((response: IPageResponse) => {
                    this.data = response.data;
                    this.checkDocumentsStatus();
                    this.loading = false;
                }, error => {
                    this.loading = false;
                });
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.isReviewMode = null;
        this.store$.dispatch(clearPageContent());
    }

}
