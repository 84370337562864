import {DatePipe} from '@angular/common';
import {Injectable, Renderer2} from '@angular/core';
import {UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {IFormField} from '../cms/models/cms.model';
import {environment} from '@environment/environment';

@Injectable({
    providedIn: 'root'
})
export abstract class UtilService {
    public static hasNumber(value: any) {
        return /\d/.test(value);
    }

    /**
     * Filter array by key
     * @param list
     * @param key
     * @param value
     */
    public static getObjFromList(list: any, value: any, key = 'name') {
        return list && list.filter(item => item[key] == value)[0];
    }

    public static compareArray(arr1, arr2) {
        return arr1.filter(value => !arr2.includes(value));
    }

    public static lastKey(obj: any) {
        const keys = obj && Object.keys(obj);
        return keys && keys[keys.length - 1];
    }

    public static nextKeyIndex(obj: any, url: string) {
        const keys = obj && Object.keys(obj);
        return keys.indexOf(url) + 1;
    }

    public static nextPage(obj: any): any {
        let nextPageObj = {};
        Object.keys(obj).forEach(key => {
            if (obj[key].nextPage) {
                nextPageObj = {page: key, data: obj[key]};
            }
        });

        return nextPageObj;
    }

    public static isNonEmpty(obj: any) {
        return !!(obj && Object.keys(obj).length);
    }

    /**
     * Remove duplicate keys and access only first object
     * when multiple is true, combine all types
     * @param list
     * @param key
     */
    public static arrayToObjectByKey(list: any[], key = 'name', isMultiple = false) {
        const obj = {};
        list?.forEach((field: IFormField) => {
            if (isMultiple) {
                obj[field[key]] = obj[field[key]] || [];
                obj[field[key]].push(field);
            } else {
                obj[field[key]] = obj[field[key]] || field;
            }
        });
        return obj;
    }

    public static deepMerge(target, source) {
        // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
        for (const key of Object.keys(source)) {
            if (source[key] instanceof Object) {
                Object.assign(source[key], UtilService.deepMerge(target[key], source[key]));
            }
        }

        // Join `target` and modified `source`
        Object.assign(target || {}, source);
        return target;
    }

    public static sort(list, key = 'order', isAsc = true) {
        return list.sort((a, b) => isAsc ? a[key] - b[key] : b[key] - a[key]);
    }

    public static expressionCheck(exp: string, left, right) {
        switch (exp) {
            case '$equal':
                return left === right;
            case '$notEqual':
                return left !== right;
            case '$some':
                if (typeof right.some === 'function')
                    return right.some(r => r === left);
                else 
                    return left === right;
            default:
                return false;
        }
    }

    public static log(type = 'log', mesage) {
        if (!environment.production) {
            console[type](mesage);
        }
    }

    public static jsonToQueryString(obj) {
        return Object.entries(obj).map(e => e.join('=')).join('&');
    }

    public static deleteUndefinedFromList(list: Array<any>) {
        return list.filter(item => item);
    }

    public static removeItemFromList(list: Array<any>, key: string, value: string) {
        return list.filter((item) => item[key] !== value);
    }

    public static convertObjectToList(obj: any) {
        const list = [];
        Object.keys(obj).forEach(item => {
            list.push(item);
        });
        return list;
    }

    public static countOccurances(list, value) {
        return list.reduce((a, v) => (v === value ? a + 1 : a), 0);
    }

    /**
     * Format sent to backend YYYY-MM-DD
     * @param dt
     */
    public static formatDate(dt) {
        return new DatePipe('en-Us').transform(dt, 'yyyy-MM-dd');
    };

    public static displayDate(dt: any, format, offset?: string, timezone?: string) {
        if (offset) {
            const tz = (timezone && UtilService.getFirstLetters(timezone)) || '';
            return new DatePipe('en-US').transform(<Date>dt, format, offset) + (tz ? ' ' + tz : '');
        } else {
            return new DatePipe('en-US').transform(<Date>dt, format);
        }
    }

    public static dateLessThan(from: string, to: string): ValidationErrors | null {
        return (group: UntypedFormGroup): { [key: string]: any } => {
            if (!group?.controls) {
                return null;
            }
            const f = group.controls[from];
            const t = group.controls[to];

            if (new Date(f.value).getTime() > new Date(t.value).getTime()) {
                f.setErrors({inValidFromDate: true});
                return {
                    inValidFromDate: true
                };
            } else {
                if (f.errors && f.errors['inValidFromDate']) {
                    f.setErrors(null);
                }
                return null;
            }
        };
    }

    public static daysInThisMonth(date: string) {
        const d = new Date(date);
        return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
    }

    public static addDays(date: Date, days: number) {
        return date.setDate(date.getDate() + days);
    }

    public static getDate(dateString: string) {
        if (!dateString) {
            return new Date();
        }
        const dt = dateString.split('-');
        return new Date(`${dt[1]}/${dt[2]}/${dt[0]}`);
    }

    public static maskText(value, digitCount?: string, character = '****') {
        if (!UtilService.hasNumber(value)) {
            return value;
        }
        const val = "" + value;
        const dg = parseInt(digitCount) || -4;
        return `${character}${val.slice(dg)}`;
    }

    public static transformFirstLetterUpperCase(str) {
        return str.charAt(0).toUpperCase() + str.substring(1, str.length);
    }

    public static clearFormField(fg, name) {
        fg.controls[name].setValue(null);
        fg.controls[name].setErrors(null);
        fg.controls[name].clearValidators();
        fg.controls[name].markAsUntouched();
        fg.controls[name].updateValueAndValidity();
    }

    /**
     * Works for both "Central Stadndard TIme" and "CST"
     * @param str
     */
    public static getFirstLetters(str) {
        if (str.split(' ').length > 1) {
            const firstLetters = str
                .split(' ')
                .map(word => word.charAt(0))
                .join('');

            return firstLetters;
        } else {
            return str;
        }
    }

    public static setClass(renderer: Renderer2, styleName: string, isRemove = false) {
        if (isRemove) {
            renderer.removeClass(document.body, styleName);
        } else {
            renderer.addClass(document.body, styleName);
        }

    }
}