import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AppState} from '@app/app.state';
import {UnsubscribeOnDestroyAdapter} from '../../adapters/unsub-on-destroy.adapter';
import {getGlobalContent} from '../../cms/store/cms/cms.selector';
import {IAlert} from '../../models/lib.model';
import {Store} from '@ngrx/store';
import {EntryFields} from 'contentful';
import {takeUntil} from 'rxjs/operators';
import Object = EntryFields.Object;

@Component({
  selector: 'lib-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges, OnDestroy {
  @Input() msgObj: IAlert;
  @Input() msgText: string;
  msgs: IAlert[];
  navContent: any;

  constructor(private store: Store<AppState>) {
    super();
  }

  /**
   * Get error message object from global shared content
   */
  ngOnInit(): void {
    this.store.select(getGlobalContent).pipe(takeUntil(this.unsubscribe)).subscribe((content) => {
      this.navContent = content;
    });
  }

  ngOnChanges() {
    if (this.msgObj?.detail) {
      const msg = Object.assign({}, this.msgObj);

      if (Array.isArray(msg.detail)) {
        this.msgs = [];
        msg.detail.map(error => {
          const m = {detail: error, severity: msg.severity, summary: msg.summary};
          this.msgs.push(m);
        });
      } else if (msg.detail) {
        const m = {detail: msg.detail, severity: msg.severity, summary: msg.summary};
        this.msgs = [m];
      } else if (this.navContent) {
        msg.detail = this.navContent?.errors[msg.errorCode] || this.navContent?.errors[0];
        this.msgs = [msg];
      } else {
        this.msgs = [msg];
      }
    } else if (this.msgObj?.data) {
	    this.msgs = [];
	    this.msgObj?.data.map(error => {
	        const m = {detail: error, severity: this.msgObj.severity, summary: this.msgObj.summary};
	        this.msgs.push(m);
	    });
    } else {
      this.msgs = [];
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.navContent = null;
  }
}
