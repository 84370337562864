<div *ngIf="(page$ | async) as page" class="aux-page">
    <h1>{{page.title}}</h1>
    <div class="description">{{page.kicker}}</div>
    <markdown [data]="page.description" *ngIf="page.description"></markdown>
    <markdown [data]="page.disclaimer" *ngIf="page.disclaimer"></markdown>
    <div class="cta-container">
        <button (click)="onSubmit(page.primaryCta)" (keyup.enter)="onSubmit(page.primaryCta)" class="p-button"
                type="submit">{{page.primaryCtaText}}</button>
    </div>
</div>
