<div *ngIf="(page$ | async) as page" class="aux-page login">
    <div *ngIf="!postLoginResponse && skipEmailPrompt" class="resume">
        <markdown [data]="page.subDescription" *ngIf="page.subDescription"></markdown>
        <lib-spinner></lib-spinner>
    </div>
    <ng-container *ngIf="isActionLogin && page.formFields">
        <h1>{{page.title}}</h1>
        <div class="description">{{page.kicker}}</div>
        <form *ngIf="!skipEmailPrompt && !postLoginResponse" (ngSubmit)="onSubmit()" [formGroup]="fg" autocomplete="off" class="form" id="page-form">
            <lib-input [group]="fg" [item]="page.formFields['email']"></lib-input>
            <div class="cta-container"
                *ngIf="page.primaryCtaText && !(page.extendedParams && page.extendedParams['hidePagePrimary'])">
                <lib-button [label]="page.primaryCtaText" [loading]="loading"></lib-button>
            </div>
        </form>
        <form *ngIf="postLoginResponse && postLoginResponse.method == 2" (ngSubmit)="onOtpSubmit()" [formGroup]="otpGroup" autocomplete="off" class="form" id="otp-form">
            <lib-input [group]="otpGroup" [item]="page.formFields['otpCode']"></lib-input>
            <lib-button klass="p-button-sm p-button-link" label="Resend code" [loading]="loading" (click)="resendEmailOtp($event)" type="button" [disabled]="resendDisabled"></lib-button>
            <div class="cta-container"
                *ngIf="page.primaryCtaText && !(page.extendedParams && page.extendedParams['hidePagePrimary'])">
                <lib-button [label]="page.primaryCtaText" [loading]="loading"></lib-button>
            </div>
        </form>
    </ng-container>
</div>
