import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ConfigService } from "./config.service";

export interface ErrorModal {
  status?: number;
}

@Injectable({
  providedIn: "root"
})
export class ApiService {
  constructor(private config: ConfigService) {
  }

  public buildUrl(endpoint: string, params?: object) {
    let urlParams = null;
    if (params) {
      urlParams = Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
    }
    return (
        `${this.config.get("apiUrl")}/${endpoint}` +
        (urlParams ? `?${urlParams}` : "")
    );
  }

  public buildLookupUrl(endpoint: string, params?: object) {
    let urlParams = null;
    if (params) {
      urlParams = Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
    }
    return (
        `${this.config.get("apiUrl")}${endpoint}` +
        (urlParams ? `?${urlParams}` : "")
    );
  }

  public buildApplicationUrl(id: number | string, method: any) {
    const baseUrl = this.buildUrl("application");

    return `${baseUrl}/${id}/${method}`;
  }

  /**
   * mapFormToModel
   *
   * This function takes in a formGroup, model and override to help map out the variables
   * that should be posted using the classical fillable parameter array.
   *
   * @param formGroup - FormGroup
   * @param model - Generic Model
   * @param override - key is the item to be pulled from and then what it should override
   */
  public mapFormToModel(
      formGroup: UntypedFormGroup,
      model,
      override?: { [key: string]: any }
  ) {
    const controls = formGroup.controls;
    Object.keys(controls).forEach((field) => {
      if (field) {
        model[field] = controls[field].value;
      }
    });

    if (override) {
      Object.keys(override).forEach((ovField) => {
        model[override[ovField]] = controls[ovField].value;

        // Delete the override we don't really need it
        delete model[ovField];
      });
    }

    return model;
  }

  resetGlobalError(error: ErrorModal) {
    if (error.status && error.status >= 500) {
      // this.alerts.resetAlerts();
    }
  }
}
