import {Injectable} from '@angular/core';
import {ConfigService} from '../config.service';
import {ILookupConfig} from '../../components/form/lookup/lookup.component';
import {EnumType} from 'json-to-graphql-query';

interface ISmartyAddress {
    street_line: string;
    secondary: string;
    city: string;
    state: string;
    zipcode: string;
}

export interface IAddress {
    line1: string;
    line2: string;
    city: string;
    stateCode: string;
    zipCode: string;
    type?: string | EnumType;
    addressId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private cs: ConfigService) {
  }

  getConfig(): ILookupConfig {
    return {
      allowCustom: true,
      uri: `${this.cs.get('smartyStreetsUrl')}?key=${this.cs.get('smartyStreetsKey')}`,
      minSearchLength: parseInt(this.cs.get('smartyStreetsMinSearchLength') || "10"),
      filterBy: 'name',
      return: 'value',
      debounceTimeMs: 500,
      isFilterable: true,
      headers: null,
      options: [],
      values: [],
      message: '',
      searchKey: '&search'
    } as ILookupConfig;
  }


  getHost(url : string) : string {
    const first = url.indexOf("://") + 3;
    const second = url.indexOf("/", first);
    return url.substring(first, second);
  }

    getDisplayOption(option: any): string {
        if (option?.line2) {
            return `${option.line1} ${option.line2}, ${option.city}, ${option.stateCode} ${option.zipCode}`;
        } else if (option?.line1) {
            return `${option.line1}, ${option.city}, ${option.stateCode} ${option.zipCode}`;
        }
        else 
        return option;
    }

  formatDisplayOptions(options: { suggestions: any[] }): any[] {
    const tOptions = [];
    options.suggestions.map((item, i) => {
      const option = {
        id: i,
        name: `${item.street_line}${item.secondary}, ${item.city}, ${item.state} ${item.zipcode}`,
        value: item
      };
      tOptions.push(option);
    });

    return tOptions;
  }

  setOptionToForm(e: any, key: string): any {
    const smartyAddress = <ISmartyAddress>e[key].value;
    const tAddress = {} as IAddress;
    tAddress.line1 = smartyAddress.street_line;
    tAddress.line2 = smartyAddress.secondary;
    tAddress.city = smartyAddress.city;
    tAddress.stateCode = smartyAddress.state;
    tAddress.zipCode = smartyAddress.zipcode;
    return tAddress;
  }

  setCustomToForm(value: string) : any {
    return this.getInputAddress(value);
  }



  /**
   * Order of params change when line2 exists
   * @param value
   */
  getInputAddress(value?: any) {
    if (!value) {
      return false;
    }
    const regex = /([^,]+)[,\s]*([^,]+),\s*([A-Za-z]{2})[\s,]*([0-9]{5})/g;
    const val = value.match(regex);
    const params = regex.exec(value).filter((x, i) => i > 0);
    if (params.length === 5 || params.length === 4) {
      const line2Number = (params.length === 5) ? 1 : 0;
      const stateCheck = params[2 + line2Number].trim().replace(/[^A-Za-z]/g,'').length === 2;
      const zipCheck = params[3 + line2Number].trim().replace(/[^0-9]/g,'').length === 5;
      return stateCheck && zipCheck  ? {
        line1: params[0].trim(),
        line2: line2Number ? params[1].trim() : '',
        city: params[1 + line2Number].trim(),
        stateCode: params[2 + line2Number].trim().toUpperCase(),
        zipCode: params[3 + line2Number].trim()
      } as IAddress : false;
    } else {
      return false;
    }
  }
}
