import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '@lib/components/form/base-component/base-form.component';

@Component({
    selector: 'lib-input-switch',
    templateUrl: './input-switch.component.html',
    styleUrls: ['./input-switch.component.scss']
})
export class InputSwitchComponent extends BaseFormComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
    }
}
