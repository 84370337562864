import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CMS_PAGE_ROUTES} from '@lib/cms/models/cms.model';
import {getGlobalContent} from '@lib/cms/store/cms/cms.selector';
import {setAlertMessage} from '@lib/store/shared.actions';
import {Store} from '@ngrx/store';
import {catchError, Observable, throwError} from 'rxjs';
import {ALERT_TYPES, IAlert, IUrlContext} from '../models/lib.model';
import {AuthProviderService} from '../services/authenticate/auth-provider.service';
import {StorageService} from '../services/storage.service';
import { ConfigService } from '@lib/services/config.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerInterceptorService {
    hideMessageHttpCodes = [];
    errorMessages = {
        500: "Unexpected error occurred, please try again or contact support"
    };

    constructor(
        private router: Router,
        private authProviderService: AuthProviderService,
        private store$: Store,
        private config: ConfigService,
        private storage: StorageService
    ) {
        this.store$.select(getGlobalContent).subscribe(gc => {
            if (gc && gc.extendedParams) {
                this.errorMessages = gc.extendedParams['errors'] || this.errorMessages;
                this.hideMessageHttpCodes = gc.extendedParams['showMessageOn'] || [409, 401, 404];
            }
        });
    }

    redirectOnError(error: HttpErrorResponse) {
        // this.modalService.close();
        switch (error.status) {
            case 307:
                console.log(error);
                break;
            case 401:
                const apiUrl = this.config.get("apiUrl");
                const lmsUrl = this.config.get("lmsGraphQlUrl");
                //set something here
                const lp = <IUrlContext>JSON.parse(sessionStorage.getItem("launchParams"));
                if (error.url.startsWith(apiUrl) || error.url.startsWith(lmsUrl)) {
                    if (lp?.id && lp.id !== 'get-started') {
                        this.router.navigateByUrl(CMS_PAGE_ROUTES.LOGIN+"?applicationId="+lp.id);
                    }
                    else 
                    {
                        this.router.navigate([CMS_PAGE_ROUTES.LOGIN]);
                    }
                }
                break;
            case 404:
                this.router.navigate([CMS_PAGE_ROUTES.NOT_FOUND]);
                break;
            case 403:
                this.router.navigate([CMS_PAGE_ROUTES.FORBIDDEN]);
                break;
            case 409:
                if (error.error?.action === 'login') {

                    // TODO warn user to login
                    if (this.storage.token) {
                        this.authProviderService.login();
                    }
                    else {
                        this.router.navigate(['login']);
                    }
                }
                else if (error.error?.action === 'resume' && this.storage.launchParams.id === 'get-started') {
                    this.router.navigate(['login']);
                }
                else if (error.error?.redirectUrl) {
                    const redirectFragments = error.error?.redirectUrl.split('/');
                    this.router.navigate([this.storage.launchParams.id, redirectFragments[redirectFragments.length - 1]]);
                } else if (error.error?.action) {
                    const actionFragments = error.error?.action.split('/');
                    if (this.storage.launchParams.id === 'get-started') {
                        this.router.navigate(actionFragments);
                    }
                    else {
                        this.router.navigate([this.storage.launchParams.id, actionFragments[actionFragments.length - 1]]);
                    }
                }
                break;
        }
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let messages: any;
                    this.redirectOnError(error);
                    if (error.error) {
                        const err = error.error as any;
                        if (err.message) {
                            messages = err.message;
                        } else if (Array.isArray(err.errors)) {
                            messages = err.errors.map(e => e.message);
                        } else if (this.errorMessages[error.status]) {
                            messages = this.errorMessages[error.status];
                        }
                    } else if (this.errorMessages[error.status]) {
                        messages = this.errorMessages[error.status];
                    }

                    if (!this.hideMessageHttpCodes.includes(error.status)) {
                        this.store$.dispatch(setAlertMessage({
                            errorCode: error.status,
                            detail: messages,
                            severity: ALERT_TYPES.ERROR,
                            summary: ALERT_TYPES.ERROR
                        } as IAlert));
                        return throwError(messages);
                    } else {
                        return throwError(null);
                    }

                })
            );
    }
}