import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PageBaseActionComponent} from '@lib/flow/page-base-action/page-base-action.component';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-complete-ibv',
    templateUrl: './complete-ibv.component.html',
    styleUrls: ['./complete-ibv.component.scss']
})
export class CompleteIbvComponent extends PageBaseActionComponent implements OnInit {

    constructor(private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.route.queryParams
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(params => {
                    if (params['token'])
                        this.onSubmit.emit({action: this.actionType, params: {...params}});
                }
            );
    }

}
