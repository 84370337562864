import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BaseFormComponent } from '../form/base-component/base-form.component';

@Component({
  selector: 'lib-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent extends BaseFormComponent implements OnInit, AfterViewInit, OnDestroy {
  replaceValue: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if(this.item.subType === 'currency') {
      this.replaceValue = this.data[this.item.name];
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
