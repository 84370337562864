import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ConfigService} from '../config.service';
import {StorageService} from '../storage.service';
import {UtilService} from '../util.service';
import {IAuthTokens} from './auth-provider.service';

/**
 * https://idm.untend.com/realms/demo/.well-known/openid-configuration - returns all APIs by keycloak
 * https://www.keycloak.org/docs-api/13.0/javadocs/org/keycloak/protocol/oidc/endpoints/LogoutEndpoint.html
 * Call Auth API to get authorization code
 * Call token API to exchange code to get token
 * Call logout API to clear session
 */

@Injectable({
    providedIn: 'root'
})
export class KeycloakService {
    constructor(
        private cs: ConfigService,
        private http: HttpClient,
        private storage: StorageService
    ) {
    }

    /**
     * Redirect to keycloak login if user is not logged-in
     */
    initLogin() {
        const config = {
            response_type: 'code',
            scope: 'openid profile email',
            client_id: this.cs.get('keycloakClientId'),
            redirect_uri: this.cs.get('keycloakRedirectUrl')
        };

        const prefillEmail = JSON.parse(sessionStorage.getItem('email') || null);
        if (prefillEmail) {
            config['login_hint'] = prefillEmail;
            sessionStorage.removeItem('email');
        }
        document.location.href = `${this.cs.get('keycloakUrl')}auth?${UtilService.jsonToQueryString(config)}`;
    }

    /**
     * POST code to get access token
     * if email exists in session, prefill email in keycloak
     */
    processCode(obj: any): Observable<IAuthTokens> {
        const payload = new HttpParams()
            .append('grant_type', 'authorization_code')
            .append('code', obj.code)
            .append('session_state', obj.session_state)
            .append('client_id', this.cs.get('keycloakClientId'))
            .append('redirect_uri', this.cs.get('keycloakRedirectUrl'));

        const options = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        };

        const url = `${this.cs.get('keycloakUrl')}token`;
        return this.http.post(url, payload, options)
            .pipe(map((response: any) => {
                return response && {
                    access_token: response.access_token,
                    id_token: response.id_token,
                    refresh_token: response.refresh_token
                };
            }))
            .pipe(catchError(() => {
                return of({} as IAuthTokens);
            }));
    }

    /**
     * POST code to get access token
     */
    refreshToken(): Observable<IAuthTokens> {
        const payload = new HttpParams()
            .append('grant_type', 'refresh_token')
            .append('client_id', this.cs.get('keycloakClientId'))
            .append('refresh_token', this.storage.refreshToken)
            .append('redirect_uri', this.cs.get('keycloakRedirectUrl'));

        const options = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        };

        const url = `${this.cs.get('keycloakUrl')}token`;

        return this.http.post(url, payload, options)
            .pipe(map((response: any) => {
                return response && {
                    access_token: response.access_token,
                    id_token: response.id_token,
                    refresh_token: response.refresh_token
                };
            }))
            .pipe(catchError(() => {
                return of({} as IAuthTokens);
            }));
    }

    /**
     * If id_hint_token is missed due to idle session, keycloak forces logout screen
     */
    logOut() {
        const params = {
            client_id: this.cs.get('keycloakClientId'),
            post_logout_redirect_uri: encodeURIComponent(this.cs.get('keycloakLogoutUrl'))
        } as any;

        if (this.storage.idToken) {
            params['id_token_hint'] = this.storage.idToken;
        }

        this.storage.clearTokens();
        document.location.href = `${this.cs.get('keycloakUrl')}logout?${UtilService.jsonToQueryString(params)}`;
    }
}
