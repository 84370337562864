import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {POST_LOGIN_ACTION} from '@app/core/components/login/login.component';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {APP_STORAGE, AppStorageService} from '@app/core/services/app-storage.service';
import {AppService} from '@app/core/services/app.service';
import {ICmsPage} from '@lib/cms/models/cms.model';
import {clearPageContent, loadCmsPageContent} from '@lib/cms/store/cms/cms.actions';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import {loadUserProfileContent} from '@lib/cms/store/common/common.actions';
import {BaseComponent} from '@lib/components/base/base.component';
import {IApplicationResponse} from '@lib/models/page.model';
import {AppApiService} from '@lib/services/app-api.service';
import {AuthProviderService, IAuthTokens} from '@lib/services/authenticate/auth-provider.service';
import {FlowService} from '@lib/services/flow/flow.service';
import {IApplicationQuery, IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {StorageService} from '@lib/services/storage.service';
import {UserService} from '@lib/services/user.service';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-authenticate',
    templateUrl: './authenticate.component.html',
    styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent extends BaseComponent implements OnInit, OnDestroy {
    page$: Observable<ICmsPage>;
    cms: ICmsPage;
    defaultFilter = '';
    processCodeComplete = false;

    constructor(
        private route: ActivatedRoute,
        private authProviderService: AuthProviderService,
        private user: UserService,
        private router: Router,
        private api: AppApiService,
        private store$: Store,
        private storage: StorageService,
        private appService: AppService,
        private fs: FlowService,
        private qs: QueryApiService,
        private appStorage: AppStorageService
    ) {
        super();
    }

    ngOnInit(): void {
        this.store$.dispatch(loadCmsPageContent({pathName: APP_PAGE_ROUTES.AUTHENTICATE}));
        this.page$ = this.store$.select(getCmsPageContent);
        this.route.queryParams
            .subscribe(params => {
                    this.processRouteParams(params);
                }
            );

        this.page$.subscribe((page) => {
            this.cms = page;
        });
    }

    processRouteParams(params) {
        if (params.code) {
            this.processCode(params);
        } else if (this.storage.token) {
            this.getActiveApplication();
        }
    }

    processCode(qs) {
        const params = {session_state: qs.session_state, code: qs.code};
        this.authProviderService.processCode(params)
            .subscribe((tokens: IAuthTokens) => {
                if (tokens.access_token) {
                    this.user.logIn(tokens);
                    this.onPostLogin();
                } else {
                    this.authProviderService.logOut();
                }
            });
    }

    onPostLogin() {
        this.store$.dispatch(loadUserProfileContent());
        const postLoginResponse = this.appStorage.getItem(APP_STORAGE.POST_LOGIN_RESPONSE);
        this.appStorage.removeItem(APP_STORAGE.POST_LOGIN_RESPONSE);
        if (this.storage.idToken && postLoginResponse?.postLogin === POST_LOGIN_ACTION.ACCOUNT) {
            this.router.navigate([APP_PAGE_ROUTES.ACCOUNT_OVERVIEW]);
        } else if (postLoginResponse?.postLogin === POST_LOGIN_ACTION.APPLICATION) {
            this.api.getApplication(postLoginResponse.applicationId)
                .subscribe((response: IApplicationResponse) => {
                    this.fs.persistApplicationData(response);
                });
        } else {
            if (Object.keys(this.cms).length) {
                this.processCodeComplete = true;
                this.getActiveApplication();
            } else {
                this.router.navigate([APP_PAGE_ROUTES.ACCOUNT_OVERVIEW]);
            }
        }
    }

    getActiveApplication() {
        this.qs.getQuery({
            endPoint: IEndPoint.AMS_ENDPOINT,
            query: this.getQueryBody()
        }).subscribe((response) => {
            if (response.data.applications.items[0]?.id) {
                this.api.getApplication(response.data.applications.items[0]?.id)
                    .subscribe((response: IApplicationResponse) => {
                        this.fs.persistApplicationData(response);
                    });
                this.fs.persistApplicationData(response, null);
            } else {
                this.router.navigate([APP_PAGE_ROUTES.ACCOUNT_OVERVIEW]);
            }
        });
    }

    getQueryBody(): {[key: string]: IApplicationQuery} {
        const query = {};
        const items = {};
        Object.keys(this.cms.formFields).map((key) => {
            items[key] = true;
        });
        const queryName = Object.keys(this.cms.extendedParams.query)[0];
        const q = this.cms.extendedParams.query[queryName];
        const tArgs = Object.assign({}, q.__args);
        this.defaultFilter = tArgs.filter;
        query[queryName] = {
            items,
            count: !!q.count,
            __args: q.__args
        };

        return query;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.store$.dispatch(clearPageContent());
    }

}
