import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

export interface ErrorModal {
  status?: number;
}

@Injectable({
  providedIn: "root"
})
export class PlaidApiService {
  constructor(
    private http: HttpClient,
    private api: ApiService) {

  }

  notifySuccess(appId : string | number, publicToken : string) :Observable<Object> {
    const url = this.api.buildApplicationUrl(appId, "connect-bank");
    return this.http.put(url, { publicToken });
  }

}
