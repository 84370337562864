import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, isFormArray} from '@angular/forms';
import {Router} from '@angular/router';
import {AppState} from '@app/app.state';
import {APP_PAGE_ROUTES, IPaymentMethod, MODAL_WINDOWS, PAYMENT_METHOD_TYPE} from '@app/core/models/core.model';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {ModalService} from '@lib/services/modal.service';
import {IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {StorageService} from '@lib/services/storage.service';
import {UtilService} from '@lib/services/util.service';
import {Store} from '@ngrx/store';
import {EnumType} from 'json-to-graphql-query';
import {loadCmsPageContent} from '@lib/cms/store/cms/cms.actions';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import { UnsubscribeOnDestroyAdapter } from '@lib/adapters/unsub-on-destroy.adapter';
import {getAppConfig, getAuthState} from '@lib/store/shared.selector';
import { forkJoin, take, takeUntil } from 'rxjs';
import { getUserProfile } from '@lib/cms/store/common/common.selector';
import { ICmsPage } from '@lib/cms/models/cms.model';
import { IAppConfig } from '@lib/store/shared.state';
import { LocationStrategy } from '@angular/common';
import { InfoCircleIcon } from 'primeng/icons/infocircle';
import { setLoanSelected } from '@lib/store/shared.actions';

type SummaryInfo = {
    principalBalance: number;
    nextPaymentAmount: number;
    nextPaymentDate: Date;
    type: string;
    creditLimit: number;
    loanAmount: number;
    loanId: number;
}



@Component({
    selector: 'app-account-overview',
    templateUrl: './account-overview.component.html',
    styleUrls: ['./account-overview.component.scss']
})
export class AccountOverviewComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
    principalBalance: string;
    noOfPayments: number;
    nextPayment: number;
    nextPaymentDate: number;
    paymentMethods: IPaymentMethod[];

    page: ICmsPage;
    data: SummaryInfo[];
    email: string;
    loading: boolean;
    loanId: string;
    loans: any[];
    noLoansFound: boolean;
    appConfig: IAppConfig;
    fullName: string;

    fg: UntypedFormGroup = new UntypedFormGroup({
        autopay: new UntypedFormControl(false)
    });
    autopayLoading = false;
    autopay: any;

    constructor(
        protected store$: Store<AppState>,
        protected storage: StorageService,
        protected authProvider: AuthProviderService,
        protected qs: QueryApiService,
        protected router: Router,
        private ms: ModalService
    ) {
        super();
    }

    ngOnInit(): void {
        this.store$.dispatch(setLoanSelected({ status: false }));
        this.getPageData(APP_PAGE_ROUTES.ACCOUNT_OVERVIEW, this.getAccountSummary.bind(this));
    }

    /**
     * Outer query is to get LoanId by email
     * Inner query is to get Loan summary by LoanId
     */

    getPageData(pathName, callback) {
        this.store$.dispatch(loadCmsPageContent({pathName}));
        forkJoin(
            [this.store$.select(getAuthState).pipe(take(1))] // Is Authenticated
        ).subscribe(results => {
            if (!results[0] || !(this.storage.token && this.storage.idToken)) {
                this.authProvider.login();
                return false;
            }

            this.store$.select(getUserProfile)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(profile => {
                    if (profile.email) {
                        this.email = profile.email;
                        this.getAccountSummary();
                    }
                });

            this.store$.select(getCmsPageContent)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(page => {
                    this.page = page;
                    this.getAccountSummary();
                });

            this.store$.select(getAppConfig)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(config => {
                    if (config) {
                        this.appConfig = config;
                        this.getAccountSummary();
                    }
                });

            return true;
        });
    }

    getAccountSummary() {
        if (!this.page || !this.page.extendedParams || !this.appConfig || !this.email) {
            return false;
        }
        const qc = this.page.extendedParams.queryConfig;

        const ls = qc['getLoanSummary'] && qc['getLoanSummary']['borrowers'];
        if (ls) {
            const query = {
                ...ls,
                '__args': ls['__args']
            };
            this.qs.getQuery({
                endPoint: IEndPoint.LMS_ENDPOINT,
                query: this.qs.getQueryBody('borrowers', query, {where : { email: { eq: this.email}}})
            }).subscribe(res => {
                this.loans = res.data.borrowers.items[0].loans;
                this.populateDataPoints();
            });
        }
        return true;
    }

    populateDataPoints() {
        const us = UtilService;
        const format = this.page.formFields['dueDate'].format;
        const currentDate = new Date();
        this.data = [];
        this.loans.map(d => {
            const info = {} as SummaryInfo;
            const cd = d.loanTerms?.startDate ?? new Date(); 
            const cdOffset = new Date(us.formatDate(us.displayDate(cd, format, this.appConfig?.companyOffset)));
            info.loanId = d.id;
            info.loanAmount = d.loanTerms?.product?.loanAmount;
            info.creditLimit = d.loanTerms?.product?.creditLimit;
            info.type = d.loanTerms?.product?.type;
            info.nextPaymentDate = d.state?.details?.nextPaymentDate;
            info.nextPaymentAmount = d.state?.details?.nextPaymentAmount;
            if (cdOffset < currentDate) {
                info.principalBalance = d.state?.details?.balance;
            } else {
                info.principalBalance = d.loanTerms?.product?.loanAmount;
            }
            this.data.push(info);
        })
    }

    viewLoanDetails(loan: SummaryInfo) {
        sessionStorage.setItem('loanId', loan.loanId.toString());
        this.store$.dispatch(setLoanSelected({status: true}));
        this.router.navigate([APP_PAGE_ROUTES.ACCOUNT_DETAILS]);
    }

    getLoanType(type: string) {
        if (type === 'InstallmentLoan') return 'Installment';
        return "";
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        this.page = null;
        this.loading = false;
        this.loanId = null;
        this.data = null;
    }
}
