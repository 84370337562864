import {ICmsPage, IGlobal} from '../../models/cms.model';

export interface ICmsState {
    global: IGlobal;
    page: ICmsPage;
}

export const initialState: ICmsState = {
    global: {
        title: ''
    },
    page: {} as ICmsPage
};
