import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxMaskService } from "ngx-mask";
import { renderString} from 'nunjucks'

@Pipe({
    name: 'template'
})
export class ContentTemplateFormatPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer, private maskPipeService: NgxMaskService) {
    }

    /**
     * Custom pipes
     * Custom format will resolve to its own format method
     * @param value
     * @param params
     */
    transform(value: any, params : any) {
            return renderString(value, params);
        }    
}
