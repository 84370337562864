import {ISharedState} from '@lib/store/shared.state';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const SHARED_STATE_NAME = 'shared';

const getSharedState = createFeatureSelector<ISharedState>(SHARED_STATE_NAME);

export const getLoading = createSelector(getSharedState, (state) => {
    return state.showLoading;
});

export const getMobileMode = createSelector(getSharedState, (state) => {
    return state.mobileMode;
});

export const getAuthState = createSelector(getSharedState, (state) => {
    return state.authenticated;
});

export const getLoanSelected = createSelector(getSharedState, (state) => {
    return state.loanSelected;
});


export const getCredentialsState = createSelector(getSharedState, (state) => {
    return state.credentialsExist;
});

export const getAlerts = createSelector(getSharedState, (state) => {
    return state.alert;
});

export const getCurrentPageData = createSelector(getSharedState, (state) => {
    return state.currentPage?.data;
});

export const getPages = createSelector(getSharedState, (state) => {
    return state.currentPage?.pages;
});

export const getCurrentProgressStep = createSelector(getSharedState, (state) => {
    return state.currentPage?.progressStep;
});

export const getApplicationId = createSelector(getSharedState, (state) => {
    return state.applicationId;
});

export const getProgressSteps = createSelector(getSharedState, (state) => {
    return state.progressSteps;
});

export const getAppConfig = createSelector(getSharedState, (state) => {
    return state.appConfig;
});
