<div [formGroup]="group" *ngIf="readonly" class="review {{item.klass}}">
    <h5>{{item.label}} <a [routerLink]="'../' + item.cta" *ngIf="item.cta"><i class="edit pi pi-pencil"></i></a></h5>
    <div class="section" *ngIf="data[item.name] as d">
        <div class="section-col">
            <!-- Iterate from CMS formField's extendedParams -> items -->
            <ng-container *ngFor="let subItem of subItems">
                <div class="data {{subItem['klass']}}"
                     *ngIf="!templateList.includes(subItem['type'])">
                    {{(d[subItem['name']] || subItem['format']) | dataFormat: {
                    model: subItem,
                    data: d
                } }}
                </div>
                <div class="data" *ngIf="templateList.includes(subItem['type'])">
                    <ng-container
                            *ngTemplateOutlet="dynamicSection; context:{type: subItem['type'], itemData: d, item, data }"></ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="section" *ngIf="!data[item.name]">
        <div class="section-col">
            {{item.actionLabel}}
        </div>
    </div>
</div>
<ng-template #dynamicSection let-type="type" let-itemData="itemData" let-item="item" let-data="data">
    <div *ngIf="type ==='address'">
<!--        {{itemData | json}} {{data | json}}-->
        <div class="section" *ngIf="item.name === 'mailing' && !data.isMailingAddressDifferent">
            <div class="data">{{item.actionLabel}}</div>
        </div>
        <div class="section"
             *ngIf="item.name === 'residence' || (item.name === 'mailing' && data.isMailingAddressDifferent)">
            <div class="w-100">
                <div class="data capitalize">
                    {{itemData.line1}} <span *ngIf="itemData.line2">{{itemData.line2}}</span>
                </div>
                <div class="data capitalize">{{itemData.city}}, {{itemData.stateCode}}, {{itemData.zipCode}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="type==='income'">
        <div class="section">
            <div class="w-100">
                <div class="data">
                    {{itemData.takeHomePay | currency}} {{payFrequencyOptions && payFrequencyOptions[itemData.payFrequency].value}}
                </div>
                <div class="data">
                    {{item.extendedParams['nextPaymentLabel']}} {{itemData.nextPayDate | date: item.extendedParams['dateFormat']}}
                </div>
            </div>
        </div>
    </div>
</ng-template>
