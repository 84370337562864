import {IAppDataState} from '@app/core/store/app-data.state';
import {IAppFlowDataState} from '@lib/models/flow.model';
import {IPageResponse} from '@lib/models/page.model';
import {IAppConfig} from '@lib/store/shared.state';
import {createAction, props} from '@ngrx/store';
import {IAlert} from '@lib/models/lib.model';

export enum ACTIONS {
    SET_APPLICATION = '[App data state] set application response',
    SET_LOADING_ACTION = '[shared state] set loading spinner',
    SET_MOBILE_MODE = '[shared state] set mobile mode',
    SET_AUTH_STATE = '[shared state] set auth state',
    SET_ALERT_MESSAGE = '[shared state] set error message',
    CLEAR_ALERT_MESSAGE = '[shared state] clear error message',
    SET_CURRENT_PAGE = '[App page data state] set current page',
    SET_APP_CONFIG = '[App data state] set app config',
    SET_LOAN_SELECTED = '[shared state] set loan selected'
};

export const setMobileMode = createAction(
    ACTIONS.SET_MOBILE_MODE,
    props<{status: boolean}>()
);

export const setAuthState = createAction(
    ACTIONS.SET_AUTH_STATE,
    props<{status: boolean, credentialsExist: boolean}>()
);

export const setLoanSelected = createAction(
    ACTIONS.SET_LOAN_SELECTED,
    props<{status: boolean}>()
);

export const setLoadingSpinner = createAction(
    ACTIONS.SET_LOADING_ACTION,
    props<{status: boolean}>()
);

export const setAlertMessage = createAction(
    ACTIONS.SET_ALERT_MESSAGE,
    props<IAlert>()
);

export const clearAlertMessage = createAction(
    ACTIONS.CLEAR_ALERT_MESSAGE
);

export const setCurrentPage = createAction(
    ACTIONS.SET_CURRENT_PAGE,
    props<{currentPage: IPageResponse}>()
);

export const setAppConfig = createAction(
    ACTIONS.SET_APP_CONFIG,
    props<{appConfig: IAppConfig}>()
);

export const setApplication = createAction(
    ACTIONS.SET_APPLICATION,
    props<IAppFlowDataState>()
);
