import {Component, OnInit} from '@angular/core';
import {DynamicBaseComponent} from '@lib/components/dynamic-base-component/dynamic-base.component';
import {AppApiService} from '@lib/services/app-api.service';

@Component({
    selector: 'app-documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent extends DynamicBaseComponent implements OnInit {
    showMore = true;

    constructor(private api: AppApiService) {
        super();
    }

    ngOnInit(): void {
    }

    viewDocument(doc) {
        if (this.model.cms.config.downloadDocuemnt) {
            document.location.href = doc.url;
        } else {
            this.api.getDocumentByUrl(doc.url, true).subscribe(response => {
                const file = URL.createObjectURL(response.body);
                window.open(file);
            });
        }
    }

    moreOrLess() {
        this.showMore = !this.showMore;
    }
}
