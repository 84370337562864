import {ICommonState} from './common.state';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const COMMON_STATE_NAME = 'common';

const getCommonState = createFeatureSelector<ICommonState>(COMMON_STATE_NAME);

export const getUserProfile = createSelector(getCommonState, (state) => {
  return state.userProfile;
});
