import {Injectable} from '@angular/core';
import {AppState} from '@app/app.state';
import {loadUserProfileContent, loadUserProfileContentSuccess} from './common.actions';
import {getUserProfile} from './common.selector';
import {UserService} from '../../../services/user.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {exhaustMap, map, withLatestFrom} from 'rxjs';

@Injectable()
export class CommonEffects {
    getUserProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadUserProfileContent),
            withLatestFrom(this.store.select(getUserProfile)),
            // map(() => loadGlobalContentSuccess({}))
            exhaustMap(() => {
                return this.userService.getProfile().pipe(
                    map((content) => {
                        return loadUserProfileContentSuccess({content});
                    })
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private userService: UserService
    ) {
    }
}
