import {formatCurrency} from '@angular/common';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {loadOffersContent} from '@app/core/store/app-data.actions';
import {getOffersContent} from '@app/core/store/app-data.selector';
import {ICmsPage} from '@lib/cms/models/cms.model';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import {BaseFormComponent} from '@lib/components/form/base-component/base-form.component';
import {ALERT_TYPES} from '@lib/models/lib.model';
import {UtilService} from '@lib/services/util.service';
import {Store} from '@ngrx/store';
import {EntryFields} from 'contentful';
import {Observable} from 'rxjs';
import Object = EntryFields.Object;

export enum PAYMENT_TYPES {
    DD,
    NON_DD,
    MAILED_CHECKS
}

export interface IOfferPage {
    paymentTypes: [{
        order: number;
        paymentTypeId: number;
        description: string;
        title: string;
    }],
    amountNeededHeading: string;
    paymentTypeHeading: string;
    amountReceived: string;
    paymentsText: string;
    aprText: string;
    title: string;
    kicker: string;
}

export interface IOffer {
    offerId: number;
    loanAmount: number;
    termInMonths: number;
    periods: number;
    averagePaymentAmount: number;
    contractRate: number;
    averageApr: number;
    paymentType: number;
    payFrequency: number;
    legend: string;
    value: number;
}

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})
export class OffersComponent extends BaseFormComponent implements OnInit, AfterViewInit {
    ALERT_TYPES = ALERT_TYPES;
    page$: Observable<ICmsPage>;
    lOffers: IOffer[] = [];
    paymentTypes: number[] = [];
    offerPage$: Observable<IOfferPage>;
    selectedOffer: IOffer;
    defaultOffer: IOffer;
    selectedAmount: number;
    maxOfferAmount: number;
    noOffer: boolean;

    constructor(private store$: Store) {
        super();
    }

    ngOnInit(): void {
        this.store$.dispatch(loadOffersContent());
        this.offerPage$ = this.store$.select(getOffersContent);
        this.page$ = this.store$.select(getCmsPageContent);

        // When only one offer
        if (!this.data.offers) {
            const dt = Object.assign({}, this.data);
            this.data = { offers: [dt]};
        }

        this.data.offers.map((offer: IOffer) => {
            const lOffer = Object.assign({}, offer);
            lOffer.legend = formatCurrency(offer.loanAmount, 'en', '$', 'USD', '1.0-0');
            lOffer.value = offer.loanAmount;
            this.lOffers.push(lOffer);
            this.paymentTypes.push(offer.paymentType);

            this.maxOfferAmount = this.maxOfferAmount > offer.loanAmount ? this.maxOfferAmount : offer.loanAmount;
        });

        this.paymentTypes = [...new Set(this.paymentTypes.sort())];

        if (this.group.controls[this.item.name].value) {
            this.chooseOffer(this.group.controls[this.item.name].value);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.sliderSelected(this.maxOfferAmount);
        });
    }

    chooseOffer(obj) {
        this.noOffer = false;
        if (!obj.offerId) {
            this.noOffer = !obj.offerId;
        } else if (obj.isDefault) {
            this.formControl.setValue(obj.offerId);
            this.defaultOffer = UtilService.getObjFromList(this.lOffers, obj.offerId, 'offerId');
        } else {
            this.selectedOffer = UtilService.getObjFromList(this.lOffers, obj.offerId, 'offerId');
        }
    }

    sliderSelected(e) {
        this.selectedAmount = e;
    }

}
