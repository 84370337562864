import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseFormComponent} from '../../form/base-component/base-form.component';
import {IUrlContext, MESSAGES} from '../../../models/lib.model';
import {AssetLoadService} from '../../../services/asset-load.service';
import {MessageService} from '../../../services/message.service';
import { ConfigService } from '@lib/services/config.service';
import { AppApiService } from '@lib/services/app-api.service';
import { PlaidApiService } from '@lib/services/plaid/plaid-api.service';

interface IConfigInput {
    script: string;
    token: string;
    sandbox: boolean;
    flowId: string;
};

export interface IPlaidResponse {
    accountId: string;
    userId: string;
}

interface IPlaidConfig {
    token: string;
    onSuccess: (public_token, metadata) => {};
    onLoad: () => {};
    onExit: () => {};
    onEvent: (eventName, metadata) => {};
}
@Component({
    selector: 'lib-plaid',
    templateUrl: './plaid.component.html',
    styleUrls: ['./plaid.component.scss']
})
export class PlaidComponent extends BaseFormComponent implements OnInit, OnDestroy {
    @Output() onConnect: EventEmitter<any> = new EventEmitter();
    @Output() onArgyleEvent: EventEmitter<any> = new EventEmitter();

    plaid: any;
    launchParams: IUrlContext;

    constructor(
        public assetLoadService: AssetLoadService, 
        private plaidApi : PlaidApiService,
        private ms: MessageService, 
        private config : ConfigService) {
        super();
    }

    ngOnInit(): void {
        this.launchParams = <IUrlContext>JSON.parse(sessionStorage.getItem("launchParams"));
        this.loadScript(this.initPlaid.bind(this));
    }

    initPlaid() {
        const config = {

            token: this.data[this.item.name],
            onLoad: () => {},
            onSuccess: this.onSuccess.bind(this),
            onEvent: this.onEvent.bind(this),
            onExit: this.onExit.bind(this)
        } as IPlaidConfig;

        this.plaid = (window as any).Plaid.create(config),

        this.plaid.open();
    }


    onSuccess(public_token: string, metadata: any) {
        console.log("Success received");
        this.ms.setMessage({ type:MESSAGES.PAGE_SUBMIT, data:{ publicToken: public_token }});
    };

    onExit() {
        console.log("Exit received");
    }

    onEvent(eventName: string, metadata: any) {
        console.log("Event recived: " + eventName + "\n data: " + JSON.stringify(metadata));
    }

    /**
     * Post an event on argyle close
     * @param event
     * @param eventObj
     * @param callbackOptions
     */
    postEvent(event: string, eventObj: any, callbackOptions?: any) {
        this.logEvent(event, eventObj);
        console.log(event);
    }

    logEvent(event?: string, payload?: any) {
        console.log(event, payload);
    }

    loadScript(callback) {
        this.assetLoadService.load({name: 'plaid', src: this.config.get("plaidScriptPath")}).then(script => {
            if ((script[0] as any).loaded) {
                callback();
            }
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
