import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BaseComponent} from '../base/base.component';

@Component({
    selector: 'lib-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss']
})
export class IframeComponent extends BaseComponent implements OnInit, AfterViewInit {
    @Input() url: any;
    @ViewChild('iframe') iframe: ElementRef;
    @Output() loaded: EventEmitter<any> = new EventEmitter();

    constructor(private sanitizer: DomSanitizer) {
        super();
    }

    ngOnInit(): void {
        // Img
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

    ngAfterViewInit() {
        // const doc = this.iframe.nativeElement.contentDocument;
    }

    onLoad() {
        this.loaded.emit(!!this.iframe?.nativeElement);
    }
}
