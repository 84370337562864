import {HostListener, Injectable, Sanitizer, SecurityContext} from '@angular/core';
import {ConfigService} from "@lib/services/config.service";
import {CommonService} from "@lib/services/common.service";
import { DomSanitizer } from '@angular/platform-browser';
import { SECURITY_CONTEXT } from 'ngx-markdown';

export const TRACK_EVENTS = {
    CLICK: 'track-click-event',
    PAGE_SUBMIT: 'page-submit'
}

@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    gtmCode: string;

    constructor(private cs: ConfigService, private common: CommonService, private domSanitizer: DomSanitizer) {
    }

    init() {
        this.gtmCode = this.cs.get('gtmCode');
        if (this.gtmCode) {
            this.initGtm(this.gtmCode);
        }
    }

    push(obj: any) {
        if (obj && this.common.nativeWindow?.dataLayer) this.common.nativeWindow.dataLayer.push(obj);
    }

    logPageView(url: string) {
        if (this.gtmCode && url) {
            this.push({
                event: 'page-view',
                pageName: url
            })
        }
    }

    logEvent(event: { event, category, action, label }) {
        const hit = {
            event: event.event,
            category: event.category,
            action: event.action,
            label: event.label
        }
        this.push(hit);
    }

    initGtm(code: string) {
        
        if (document.readyState === "loading") {

            // Loading hasn't finished yet        
            window.addEventListener('DOMContentLoaded', (event) =>
            {
                this.loadGtm(code);
            });
        }
        else
            this.loadGtm(code);
    }

    loadGtm(code: String) {
        this.inner(window, document, 'script', 'dataLayer', code);
    }

    inner(w,d,s,l,i)
    {
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0], j=d.createElement(s),
         dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    }

    clear() {
        this.gtmCode = '';
    }
}
