import {ICmsState} from './cms.state';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const CMS_STATE_NAME = 'cms';

const getCmsState = createFeatureSelector<ICmsState>(CMS_STATE_NAME);

export const getGlobalContent = createSelector(getCmsState, (state) => {
  return state.global;
});

export const getCmsPageContent = createSelector(getCmsState, (state) => {
  return state.page;
});
