import {CommonModule, DatePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BaseModalComponent} from '@lib/components/base-modal/base-modal.component';
import {DynamicActionRenderDirective} from '@lib/directives/dynamic-action-render.directive';
import {DynamicComponentDirective} from '@lib/directives/dynamic-component.directive';
import {DynamicFormRenderDirective} from '@lib/directives/dynamic-form-render.directive';
import {DynamicPageRenderDirective} from '@lib/directives/dynamic-page-render.directive';
import {DynamicTileRenderDirective} from '@lib/directives/dynamic-tile-render.directive';
import {PageHeaderComponent} from '@lib/flow/page-header/page-header.component';
import {EffectsModule} from '@ngrx/effects';
import {DialogService} from 'primeng/dynamicdialog';
import {CmsEffects} from './cms/store/cms/cms.effects';
import {CommonEffects} from './cms/store/common/common.effects';
import {CompleteIbvComponent} from './components/actions/complete-ibv/complete-ibv.component';
import {AlertsComponent} from './components/alerts/alerts.component';
import {DataTableCellComponent} from './components/data-table/data-table-cell/data-table-cell.component';
import {DataTableComponent} from './components/data-table/data-table.component';
import {IconTemplateComponent} from './components/data-table/icon-template/icon-template.component';
import {DynamicBaseComponent} from './components/dynamic-base-component/dynamic-base.component';
import {EsignComponent} from './components/esign/esign.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {FooterComponent} from './components/footer/footer.component';
import {AccordionComponent} from './components/form/accordion/accordion.component';
import {ButtonComponent} from './components/form/button/button.component';
import {CheckboxComponent} from './components/form/checkbox/checkbox.component';
import {DatepickerComponent} from './components/form/datepicker/datepicker.component';
import {InputSwitchComponent} from './components/form/input-switch/input-switch.component';
import {InputComponent} from './components/form/input/input.component';
import {LookupComponent} from './components/form/lookup/lookup.component';
import {PasswordConfirmComponent} from './components/form/password-confirm/password-confirm.component';
import {RadioComponent} from './components/form/radio/radio.component';
import {TextareaComponent} from './components/form/textarea/textarea.component';
import {ToggleButtonComponent} from './components/form/toggle-button/toggle-button.component';
import {HeaderComponent} from './components/header/header.component';
import {HtmlComponent} from './components/html/html.component';
import {IframeComponent} from './components/iframe/iframe.component';
import {ImageViewerComponent} from './components/image-viewer/image-viewer.component';
import {JsonViewerComponent} from './components/json-viewer/json-viewer.component';
import {LeftBarMenuComponent} from './components/left-bar-menu/left-bar-menu.component';
import {PdfViewerComponent} from './components/pdf-viewer/pdf-viewer.component';
import {SliderComponent} from './components/slider/slider.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {StepsComponent} from './components/steps/steps.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {TextComponent} from './components/text/text.component';
import {LayoutComponent} from './components/tiles/layout/layout.component';
import {TileComponent} from './components/tiles/tile/tile.component';
import {UnmaskComponent} from './components/unmask/unmask.component';
import {ArgyleComponent} from './components/vendors/argyle/argyle.component';
import {DataFormatPipe} from './directives/data-format.pipe';
import {AppNgxModule} from './ngx.module';
import {StringReplacePipe} from './pipes/string-replace.pipe';
import {PrimengModule} from './primeng.module';
import {BaseFormComponent} from "@lib/components/form/base-component/base-form.component";
import {PayrollModalComponent} from './modals/payroll-modal/payroll-modal.component';
import { ContentTemplateFormatPipe } from './directives/content-template.pipe';
import { FieldConfirmComponent } from './components/form/field-confirm/field-confirm.component';
import { PlaidComponent } from './components/vendors/plaid/plaid.component';
import { OtpComponent } from './components/form/otp/otp.component';

const components = [
    ButtonComponent,
    InputComponent,
    AlertsComponent,
    DatepickerComponent,
    CheckboxComponent,
    LookupComponent,
    RadioComponent,
    SliderComponent,
    HeaderComponent,
    FooterComponent,
    StepsComponent,
    TextComponent,
    StringReplacePipe,
    EsignComponent,
    ArgyleComponent,
    PlaidComponent,
    OtpComponent,
    SpinnerComponent,
    CompleteIbvComponent,
    DataTableComponent,
    LayoutComponent,
    TileComponent,
    TabsComponent,
    FileUploadComponent,
    TextareaComponent,
    JsonViewerComponent,
    PdfViewerComponent,
    IframeComponent,
    ImageViewerComponent,
    PasswordConfirmComponent,
    FieldConfirmComponent,
    IconTemplateComponent,
    LeftBarMenuComponent,
    DynamicBaseComponent,
    DataTableCellComponent,
    DataFormatPipe,
    ContentTemplateFormatPipe,
    DynamicComponentDirective,
    DynamicActionRenderDirective,
    DynamicTileRenderDirective,
    DynamicPageRenderDirective,
    DynamicFormRenderDirective,
    PageHeaderComponent,
    ToggleButtonComponent,
    BaseModalComponent,
    HtmlComponent,
    InputSwitchComponent,
    AccordionComponent,
    UnmaskComponent,
    BaseFormComponent,
    PayrollModalComponent
];

@NgModule({
    declarations: [
        ...components
    ],
    exports: [
        ...components,
        RouterModule,
        PrimengModule,
        AppNgxModule
    ],
    imports: [
        EffectsModule.forRoot([CmsEffects, CommonEffects]),
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        PrimengModule,
        AppNgxModule
    ],
    providers: [DialogService, DatePipe],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class LibModule {
}
