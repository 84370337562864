<div class="modal-content" *ngIf="config.data as d">
    <div class="modal-header">
    <h4>
        <i class="{{d.cms.titleIcon}}" *ngIf="d.cms.titleIcon"></i>
        {{d.cms.title}}
    </h4>
    <i class="pi pi-times modal-close-icon" (click)="close(d.data?.actions)" (keyup.enter)="close(d.data?.actions)"
        tabindex="0"></i>
    </div>    
    <div class="modal-data-list" *ngIf="d.cms.extendedParams">
        {{d.cms.extendedParams.listHeading}}
        <ul>
            <li *ngFor="let item of d.data.items; trackBy: trackByFn">{{item}}</li>
        </ul>
    </div>
    <markdown [data]="d.cms.body" *ngIf="d.cms.body" class="modal-description"></markdown>
    <div class="modal-actions">
        <lib-button *ngFor="let fieldKey of formFieldKeys; trackBy: trackByFn"
                    [label]="d.cms.formFields[fieldKey].label"
                    class="action"
                    [klass]="d.cms.formFields[fieldKey].klass"
                    (click)="cta(d.cms.formFields[fieldKey].cta, d.data?.actions)"
        ></lib-button>
    </div>
</div>
