import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BaseFormComponent} from '../base-component/base-form.component';
import {AppApiService} from '../../../services/app-api.service';
import {LookupService} from '../../../services/lookup/lookup.service';
import {UtilService} from '../../../services/util.service';
import { Subject, debounceTime } from 'rxjs';

export interface ILookupConfig {
    message: string;
    allowCustom: boolean,
    uri: string;
    debounceTimeMs? : number;
    minSearchLength: number;
    filterBy: string;
    return: string;
    headers: { [header: string] : string};
    isFilterable: boolean;
    //options: any[];
    values: any[];
    searchKey: string;
}

@Component({
    selector: 'lib-lookup',
    templateUrl: './lookup.component.html',
    styleUrls: ['./lookup.component.scss']
})
export class LookupComponent extends BaseFormComponent implements OnInit, OnChanges {
    @Input() isGroup: boolean;
    @Input() pTemplate = '';
    @Input() panelStyleClass = '';
    @Output() onSelect: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    tempGroup: UntypedFormGroup = new UntypedFormGroup({
        tempControl: new UntypedFormControl()
    });

    constructor(private appApiService: AppApiService, private lookupService: LookupService) {
        super();
    }
    private searchSubject = new Subject<any>();
    allowCustom: boolean = false;
    private textChanged : boolean = false;

    ngOnInit(): void {
        // if (this.item.name === 'residence') {
        //   this.formControl.setValue({
        //     line1: '201 Presidents Cir',
        //     line2: '',
        //     city: 'Salt Lake City',
        //     stateCode: 'UT',
        //     zipCode: '84112'
        //   });
        // }
        this.getConfig();
        this.lookupRules();
        this.formControl.valueChanges.subscribe(a =>
            {
                // if (!a && this.item.option?.isFilterable) 
                // {
                //     this.tempGroup.controls["tempControl"].setValue("");
                //     this.tempGroup.controls["tempControl"].markAsTouched();
                // }
            });
    }
    ngOnDestroy(): void {
        this.searchSubject.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.lookupRules();
    }

    customOption: any | null = null;
    lastSearchBy : string = "";

    get isCustom() {
        return !!this.item.subType;
    }

    /**
     * Get option config from BE or custom component
     */
    getConfig() {
        this.item.option = this.item.option || this.lookupService.getConfig(this.item.subType, this.item.option);

        const optionConfig :ILookupConfig = this.item.option;
        if (optionConfig.allowCustom && this.item.extendedParams?.customOption) {
            this.customOption = this.item.extendedParams.customOption;
        }
        this.searchSubject.pipe(debounceTime(this.item.option.debounceTimeMs || 0)).subscribe(this.filterData.bind(this));

        if (this.item.extendedParams?.toggleCustomMode) {
            this.allowCustom = false;
        }
        else 
            this.allowCustom = this.item.option.allowCustom;
    }

    /**
     * Get option config from an item
     * searchKey exists only to address field - vendor integration
     * Dropdown dynamic - isFilterable: true and uri exists
     * Dropdown static - isFilterable: true and uri does not exits
     * Autocomplete - isFilterable: false, uri must exists
     * Autocomplete - allowCustom: true, show "don't find option" always, onclick of it, turn autocomplete to text field
     */

    onSearch(e :any) {
        this.searchSubject.next(e);
        return true;
    }

    lookupRules() {
        const optionConfig: ILookupConfig = this.item.option;

        if (optionConfig.isFilterable && this.formControl.value) {
            // autocomplete - populate on load
            const searchKey = optionConfig.searchKey || '?filter';
            const tUrl = `${optionConfig.uri}${searchKey}=${this.getDisplayOption()}`;
            this.appApiService.getData(tUrl, !!optionConfig.searchKey).subscribe((options) => {
                this.item.option.values = [this.formatDisplayOptions(options)[0]];
                this.tempGroup.controls['tempControl'].setValue(this.getDisplayOption());
                this.tempGroup.controls['tempControl'].updateValueAndValidity();
            });
        } else if (!optionConfig.isFilterable && optionConfig.uri) {
            // dropdown
            this.appApiService.getData(optionConfig.uri).subscribe((options) => {
                this.item.option.values = options;
                this.tempGroup.controls['tempControl'].setValue(this.formControl.value);
            });
        } else {
            //this.item.option.values = optionConfig.values;
            this.tempGroup.controls['tempControl'].setValue(this.formControl.value);
        }
    }

    filterData(e: any) {
        const optionConfig: ILookupConfig = this.item.option;
        const query: string = e?.query || this.formControl.value || "";
        if (query?.length >= optionConfig.minSearchLength) {
            const searchKey = optionConfig.searchKey || '?filter';
            const tUrl = `${optionConfig.uri}${searchKey}=${query}`;
            this.appApiService.getData(tUrl, !!optionConfig.searchKey).subscribe((options) => {
                this.item.option.values = this.formatDisplayOptions(options) || [];
            }, () => {
            });
        } else {
            optionConfig.values = [];
        }
    }

    dropdownBlur(e : any) {
        return false;
    }

    /**
     * Set custom value to form control
     * event can be a dropdown value, direct lookup value or lookup object (Address, Bank etc)
     *
     * @param e
     * @param isDropdown
     */
    onSelectEvent(e: any, isDropdown) : boolean {
        this.textChanged = false;
        if (isDropdown) {
            this.formControl.setValue(e.value);
        } 
        else if (this.customOption && e.value['id'] === this.customOption.id)
        {
            this.allowCustom = true;
             this.tempGroup.controls['tempControl'].setValue(this.getDisplayOption());
             this.tempGroup.controls['tempControl'].updateValueAndValidity();
             this.tempGroup.controls['tempControl'].markAllAsTouched();
            const value = this.lookupService.setCustomToForm(this.item.subType, this.getDisplayOption());
            this.formControl.setValue(value);
            this.formControl.markAsTouched();
        }
        else if (!this.isCustom) {
            if (this.customOption && e.value['id'] === this.customOption.id){
                this.formControl.setValue(this.lastSearchBy);
                this.formControl.markAsTouched();
                console.log("value: " + this.formControl.value);
                return false;
            }
            else {
                this.formControl.setValue(e.value[this.item.option.return]);
            }
        } else {
            const value = this.lookupService.setOptionToForm(this.item.subType, e, this.item.option.return);
            this.formControl.setValue(value);
        }

        return true;
    }

    onKeyUp(e : any) {
        this.textChanged = true;
        this.lastSearchBy = e.target.value;
    }

    onBlurEvent(e: any) {
        if (this.allowCustom) {
            this.formControl.setValue(this.lookupService.setCustomToForm(this.item.subType, this.getDisplayOption()));
        }
        this.formControl.markAllAsTouched();

    }

    getDisplayOption(): string {
        const value =  this.item.option.allowCustom && this.lastSearchBy && this.textChanged ? 
            this.lastSearchBy : this.formControl.value;
        return this.lookupService.getDisplayOption(this.item.subType, value);
    }

    formatDisplayOptions(options): any[] {
        options = options || [];
        let allOptions = this.customOption ? [...options, this.customOption] : options;
        if (!this.item.subType) {
            return allOptions;
        }

        return this.lookupService.formatDisplayOptions(this.item.subType, allOptions);
    }

    onClear(e: any) {
        if (e?.value === undefined) {
            this.formControl.reset();
        }
    }

}
