import {DocumentsListComponent} from '@app/core/components/documents-list/documents-list.component';
import {DocumentsViewerComponent} from '@app/core/components/documents-viewer/documents-viewer.component';
import {FundComponent} from '@app/core/components/fund/fund.component';
import {ConfirmModalComponent} from '@app/core/components/modals/confirm-modal/confirm-modal.component';
import {EditAutopayModalComponent} from '@app/core/components/modals/edit-autopay-modal/edit-autopay-modal.component';
import {IframeModalComponent} from '@app/core/components/modals/iframe-modal/iframe-modal.component';
import {OffersComponent} from '@app/core/components/offers/offers.component';
import {PayrollComponent} from '@app/core/components/payroll/payroll.component';
import {ReviewComponent} from '@app/core/components/review/review.component';
import {SignComponent} from '@app/core/components/sign/sign.component';
import {UploadDocumentsComponent} from '@app/core/components/upload-documents/upload-documents.component';
import {CMS_PAGE_ROUTES} from '@lib/cms/models/cms.model';
import {DataTableCellComponent} from '@lib/components/data-table/data-table-cell/data-table-cell.component';
import {IconTemplateComponent} from '@lib/components/data-table/icon-template/icon-template.component';
import { ContentComponent } from '../components/content/content.component';
import { PlaidComponent } from '@lib/components/vendors/plaid/plaid.component';

export const APP_PAGE_ROUTES = {
    ...CMS_PAGE_ROUTES,
    HOME: '/',
    OFFER_PAGE: 'offers',
    LANDING: 'landing',
    ACCOUNT_OVERVIEW: 'account-overview',
    ACCOUNT_DETAILS: 'account-details',
    PAYMENT_HISTORY: 'payment-activity',
    PAYMENT_METHODS: 'payment-methods',
    ONE_TIME_PAYMENTS: 'scheduled-payments',
    ACCOUNT_DOCUMENTS: 'documents',
    PAYMENT: 'payment',
    CHALLENGE: 'challenge'
};

export const PAGE_COMPONENT_TYPES = {
    'verif-docs': UploadDocumentsComponent
};

export const APP_COMPONENT_TYPES = {
    esign: SignComponent,
    review: ReviewComponent,
    payroll: PayrollComponent,
    plaid: PlaidComponent,
    fileupload: UploadDocumentsComponent,
    documents: DocumentsViewerComponent,
    offers: OffersComponent,
    content: ContentComponent,
    fund: FundComponent,
    'data-table-cell': DataTableCellComponent,
    'icon-template': IconTemplateComponent,
    'accordion-tab-document': DocumentsListComponent
};

export const APP_TILES = {};
export const MODAL_WINDOWS = {
    CONFIRM_AUTOPAY_DISABLE: {
        name: 'confirm-autopay-disable',
        component: ConfirmModalComponent
    },
    DEAUTHORIZE_PAYMENT_METHOD: {
        name: 'deauthorize-payment-method',
        component: ConfirmModalComponent
    },
    "product-details": {
        name: 'product-details',
        component: ConfirmModalComponent
    },

    CHECKOUT_FORM: {
        name: 'checkout-form',
        component: IframeModalComponent
    },
    DISABLE_AUTOPAY: {
        name: 'disable-autopay',
        component: EditAutopayModalComponent
    },
    ENABLE_AUTOPAY: {
        name: 'enable-autopay',
        component: EditAutopayModalComponent
    }
};

export enum PAYMENT_METHOD_TYPE {
    ACH = 4,
    DEBIT_CARD = 2,
    DEBIT_CARD_NAME = 'DEBIT',
    ACH_NAME = 'ACH',
    NONE = 'NONE'
}

export enum DOCUMENT_UPLOAD_STATUS {
    INCOMPLETE,
    COMPLETE,
    DISABLED
}

export enum TRANSACTION_STATUS {
    SCHEDULED = 'SCHEDULED',
    LOGGED = 'LOGGED',
    PROCESSED = 'PROCESSED',
    RETURNED = 'RETURNED',
    CANCELLED = 'CANCELLED'
}

export interface IPaymentMethod {
    id?: number;
    name?: string;
    paymentDetails?: any;
    authorized?: boolean;
    accountNumber?: string;
    paymentMethodCollectionId?: string;
    paymentMethodTypeId?: number;
    paymentMethodType?: {
        processType?: string;
        display?: string;
    }
    key?: string;
    value?: string;
    type?: string;
}

export interface ILoanPaymentMethod {
    type?: string;
    name?: string;
    paymentMethods?: IPaymentMethod[]
}
