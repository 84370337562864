<lib-spinner *ngIf="!((page && data) || noLoansFound)"></lib-spinner>
<div class="account-overview no-loans" *ngIf="noLoansFound">
    <h4>{{page.formFields['accountSummary'].label}}</h4>
    <markdown [data]="page.kicker" *ngIf="page.kicker"></markdown>
</div>
<div *ngIf="page && data">
    <div class="account-overview" *ngIf="page && data as d">
        <div class="summary-section section">
            <h4>{{page.formFields['accountSummary'].label}}</h4>
            <div class="label-value">
                <div class="data-label">{{page.formFields['loanId'].label}}</div>
                <div class="data-value">{{d.loanId}}</div>
            </div>
            <div class="label-value" *ngIf="d.loanTerms?.product?.loanAmount">
                <div class="data-label">{{page.formFields['originalNoteAmount'].label}}</div>
                <div class="data-value">{{d.loanTerms?.product?.loanAmount | currency}}</div>
            </div>
            <div class="label-value">
                <div class="data-label">{{page.formFields['interestRate'].label}}</div>
                <div class="data-value">{{d.loanTerms?.pricing?.interestRate * 100 }}%</div>
            </div>
            <div class="label-value" *ngIf="productHasField('creditLimit')">
                <div class="data-label">{{page.formFields['creditLimit'].label}}</div>
                <div class="data-value">{{creditLimit | currency}}</div>
            </div>
            <div class="label-value" *ngIf="page.formFields['currentBalance']">
                <div class="data-label">{{page.formFields['currentBalance'].label}}</div>
                <div class="data-value">{{currentBalance | currency}}</div>
            </div>
            <div class="label-value">
                <div class="data-label">{{page.formFields['principalBalance'].label}}</div>
                <div class="data-value">{{principalBalance | currency}}</div>
            </div>
            <div class="label-value" *ngIf="page.formFields['availableCredit'] && productHasField('availableCredit')">
                <div class="data-label">{{page.formFields['availableCredit'].label}}</div>
                <div class="data-value">{{availableCredit | currency}}</div>
            </div>
            <div class="label-value" *ngIf="page.formFields['availableToWithdraw'] && productHasField('availableToWithdraw')">
                <div class="data-label">{{page.formFields['availableToWithdraw'].label}}</div>
                <div class="data-value">{{availableToWithdraw | currency}}</div>
            </div>
            <div class="label-value" *ngIf="collateralBalance">
                <div class="data-label">{{page.formFields['collateralBalance'].label}}</div>
                <div class="data-value">{{collateralBalance | currency}}</div>
            </div>
            <div class="label-value" *ngIf="productHasField('recurringFeeAmount')">
                <div class="data-label">{{page.formFields['recurringFeeAmount'].label}}</div>
                <div class="data-value">{{recurringFeeAmount | currency}}</div>
            </div>
            <div class="label-value" *ngIf="d.loanTerms?.calculatedSchedule?.scheduledPayments">
                <div class="data-label">{{page.formFields['regularPeriodicPayment'].label}}</div>
                <div class="data-value">{{(d.loanTerms?.calculatedSchedule.scheduledPayments.items[0].paymentAmount ||
                    0) | currency}}</div>
            </div>
            <div class="label-value" *ngIf="d.loanTerms?.calculatedSchedule?.paymentAmount">
                <div class="data-label">{{page.formFields['regularPeriodicPayment'].label}}</div>
                <div class="data-value">{{(d.loanTerms?.calculatedSchedule?.paymentAmount || 0) | currency}}</div>
            </div>
            <div class="label-value" *ngIf="d.state.details?.maturityDate">
                <div class="data-label">{{page.formFields['maturityDate'].label}}</div>
                <div class="data-value">{{d.details?.maturityDate | date: page.formFields['maturityDate'].format}}</div>
            </div>
            <div class="label-value" *ngIf="d.state.details?.paymentsRemaining">
                <div class="data-label">{{page.formFields['paymentsRemaining'].label}}</div>
                <div class="data-value">{{noOfPayments}}</div>
            </div>
        </div>
        <div class="summary-section section">
            <h4>{{page.formFields['paymentSummary'].label}}</h4>
            <div class="label-value">
                <div class="data-label">{{page.formFields['nextPayment'].label}}</div>
                <div class="data-value">{{(nextPayment || 0) | currency}}</div>
            </div>
            <div class="label-value">
                <div class="data-label">{{page.formFields['dueDate'].label}}</div>
                <div class="data-value">{{nextPaymentDate | date: page.formFields['dueDate'].format}}</div>
            </div>
            <ng-container *ngIf="paymentMethods?.length">
                <div class="autopay space-between">
                    <div class="data-label">{{page.formFields['autopay'].label}}</div>
                    <div class="data-value">
                        <form [formGroup]="fg" *ngIf="!autopayLoading">
                            <lib-input-switch [group]="fg" [item]="page.formFields['autopay']"
                                (click)="launchAutopayModal($event)"
                                [readonly]="page.formFields['autopay'].extendedParams['readonly']"
                                (keyup.enter)="launchAutopayModal($event)"
                                *ngIf="!page.formFields['autopay'].extendedParams['readonly']">
                            </lib-input-switch>
                            <p *ngIf="page.formFields['autopay'].extendedParams['readonly']" class="autopay-value"
                                [ngClass]="{'enable': fg.controls['autopay'].value}">
                                {{fg.controls['autopay'].value ?
                                page.formFields['autopay']['extendedParams'].enabledText :
                                page.formFields['autopay']['extendedParams'].disabledText}}
                            </p>
                        </form>
                        <lib-spinner *ngIf="autopayLoading" size="25px"></lib-spinner>
                    </div>
                </div>
                <markdown class="autopay-content"
                    [ngClass]="{'enable': fg.controls['autopay'].value && !page.formFields['autopay'].extendedParams['readonly'], 'readonly': page.formFields['autopay'].extendedParams['readonly']}"
                    *ngIf="fg.controls['autopay'].value || page.formFields['autopay'].extendedParams['readonly']">
                    {{page.formFields['autopay'].sectionInfo}}
                </markdown>
            </ng-container>
        </div>
        <div class="make-payment">
            <lib-button [label]="page.formFields['makePayment'].label" [klass]="page.formFields['makePayment'].klass"
                (click)="makePayment()" (keyup.enter)="makePayment()"
                *ngIf="page.formFields['makePayment']"></lib-button>
        </div>

        <div class="summary-section section recent-payments" *ngIf="d.appliedTransactions?.items.length">
            <div class="summary-section__header">
                <h4 class="with-link">{{page.formFields['recentPayments'].label}}</h4>
                <a routerLink="/payment-activity">{{page.formFields['viewAll'].label}}</a>
            </div>
            <lib-data-table [cms]="page"
            [endPoint]="iEndPoint.LMS_ENDPOINT"
            *ngIf="page.appId">
            </lib-data-table>
        </div>
    </div>
</div>