import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppState} from '@app/app.state';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {AccountBasePageComponent} from '@app/core/pages/account-base-page/account-base-page.component';
import {ICmsPage} from '@lib/cms/models/cms.model';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {StorageService} from '@lib/services/storage.service';
import {Store} from '@ngrx/store';
import {EnumType} from 'json-to-graphql-query';

@Component({
    selector: 'app-payment-history',
    templateUrl: './payment-history.component.html',
    styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent extends AccountBasePageComponent implements OnInit, OnDestroy {
    id: any;
    page: ICmsPage;
    data: any;
    email: string;
    iEndPoint = IEndPoint;
    noLoansFound: boolean;

    constructor(
        protected store$: Store<AppState>,
        protected storage: StorageService,
        protected authProvider: AuthProviderService,
        protected qs: QueryApiService,
        protected router: Router
    ) {
        super(store$, storage, authProvider, qs, router);
    }

    ngOnInit(): void {
        super.getLoanData(APP_PAGE_ROUTES.PAYMENT_HISTORY, this.getPaymentHistory.bind(this));
    }

    /**
     * Outer query is to get LoanId by email
     * Inner query is to get Loan summary by LoanId
     */
    getPaymentHistory() {
        if (!this.page) {
            return false;
        }

        const qt = this.page.extendedParams.queryConfig['queryTransactionsConfig'];
        this.page = Object.assign({}, {
            ...this.page,
            appId: this.loanId,
            queryConfig: {
                ...qt,
                query: {
                    loan: {
                        '__args': {
                            id: true
                        },
                        appliedTransactions: {
                            totalCount: true,
                            items: "{items}",
                            '__args': {
                                skip: 0,
                                take: qt.config.rowsPerPageOptions[0],
                                where: {type: {eq: 'Payment'}},
                                order: {effectiveDate: new EnumType('DESC')}
                            }
                        }
                    }
                }
            }
        });

        return true;
    }

    makePayment() {
        this.router.navigate([APP_PAGE_ROUTES.PAYMENT]);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.page = null;
        this.loading = false;
        this.loanId = null;
        this.data = null;
    }
}
