<lib-spinner *ngIf="isReviewMode === null"></lib-spinner>
<div *ngIf="cmsPage && isReviewMode !== null" class="upload-documents">
    <div class="upload-documents__header">
        <div *ngIf="!isReviewMode">
            <h1>{{cmsPage.extendedParams.title}}</h1>
            <markdown [data]="cmsPage.extendedParams.kicker"></markdown>
        </div>
        <div *ngIf="isReviewMode">
            <h1>{{cmsPage.extendedParams.reviewTitle}}</h1>
            <markdown [data]="cmsPage.extendedParams.reviewKicker"></markdown>
        </div>
    </div>
    <div class="upload-documents__body">
        <div class="upload-documents__body-group file-upload" *ngFor="let group of groups; trackBy: trackByFn;">
            <ng-container *ngIf="cmsPage.formFields[group] as cmsGroup">
                <h4>
                    {{cmsGroup.label}} <i class="pi pi-check-circle" *ngIf="isReviewMode"></i>
                </h4>
                <ul *ngIf="!isReviewMode">
                    <li *ngFor="let document of data[cmsGroup.name].documents; trackBy: trackByFn;">
                        <lib-file-upload [label]="cmsGroup.extendedParams[document.name]"
                                         (select)="onSelect($event)" [documentType]="document.name"
                                         [groupName]="cmsGroup.name"
                                         [files]="uploadFiles[document.name] || []"
                                         [disabled]="document.status === STATUS.DISABLED"
                                         klass="file-upload {{document.status === STATUS.COMPLETE? 'file-upload-complete' : ''}}"
                                         [accept]="(globalContent$ | async).extendedParams.acceptedFileTypes">
                        </lib-file-upload>
                    </li>
                </ul>
            </ng-container>
        </div>
    </div>
    <div class="cta-container" *ngIf="!isReviewMode">
        <ng-container *ngIf="(routeParams$ | async) as route">
            <lib-button [label]="cmsPage.primaryCtaText" [loading]="loading" (click)="onSubmit()"
                        (keyup.enter)="onSubmit()"></lib-button>
        </ng-container>
    </div>
</div>
