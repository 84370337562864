import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ICmsPage, IFormField} from '@lib/cms/models/cms.model';
import {BaseComponent} from '../../base/base.component';

/**
 * Item: holds cms content
 */

@Component({template: ''})
export class BaseFormComponent extends BaseComponent {
    @Input() item: IFormField;
    @Input() group: UntypedFormGroup;
    @Input() readonly: boolean;
    @Input() data: any;
    @Input() pageGetSchema: any;
    @Input() formSubmitSchema: any;
    @Input() componentTypes: any;
    @Input() page: ICmsPage;
    @Output() onSubmit: EventEmitter<any> = new EventEmitter();

    constructor() {
        super();
    }

    get formControl() {
        const fc = this.group?.get(this.item.name) as UntypedFormControl;
        return fc || new UntypedFormControl(this.item.name);
    }

    get errorKeys() {
        return Object.keys(this.formControl.errors);
    }

    get value() {
        const field = this.group.controls[this.item.name] as UntypedFormGroup;
        return field?.value;
    }
}
