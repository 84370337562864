import {
    ComponentRef,
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import {APP_COMPONENT_TYPES} from '@app/core/models/core.model';
import {ICmsPage, IFormField} from '@lib/cms/models/cms.model';
import {COMPONENT_TYPES} from '@lib/services/form.service';
import {BaseFormComponent} from '@lib/components/form/base-component/base-form.component';

@Directive({
    selector: '[appDynamicFormRender]'
})
export class DynamicFormRenderDirective implements OnInit, OnChanges, OnDestroy {
    @Input() componentType;
    @Input() item: IFormField;
    @Input() group: any;
    @Input() data: any;
    @Input() pageGetSchema: any;
    @Input() formSubmitSchema: any;
    @Input() readonly: boolean;
    @Input() page: ICmsPage;
    @Output() onSubmit: EventEmitter<any> = new EventEmitter();

    componentRef: ComponentRef<BaseFormComponent>;

    constructor(public viewContainerRef: ViewContainerRef) {
    }

    ngOnInit(): void {
        const types = {...COMPONENT_TYPES, ...APP_COMPONENT_TYPES};
        this.viewContainerRef.clear();
        const type = types[this.item.customType || this.item.type] || types.string;
        this.componentRef = this.viewContainerRef.createComponent(type)
        this.componentRef.instance.group = this.group;
        this.componentRef.instance.item = this.item;
        this.componentRef.instance.readonly = this.readonly;
        this.componentRef.instance.page = this.page;
        this.componentRef.instance.data = this.data;
        this.componentRef.instance.pageGetSchema = this.pageGetSchema;
        this.componentRef.instance.formSubmitSchema = this.formSubmitSchema;
        this.componentRef.instance.onSubmit = this.onSubmit;
    }

    ngOnChanges(changes: SimpleChanges): boolean {
        if (!this.componentRef || !this.group) {
            return false;
        }

        // this.componentRef.instance.group = this.group;
        return true;
    }

    ngOnDestroy(): void {
    }
}
