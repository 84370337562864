import {Injectable} from '@angular/core';
import {GlobalConstants} from '@app/core/constants/global.constants';
import {clearAlertMessage} from '@lib/store/shared.actions';
import {ISharedState} from '@lib/store/shared.state';
import {Store} from '@ngrx/store';
import {DialogService} from 'primeng/dynamicdialog';
import {CmsService} from '../cms/services/cms.service';
import {IDialog} from '../models/lib.model';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    static instance: ModalService;

    constructor(public dialogService: DialogService, private cms: CmsService, private store$: Store<ISharedState>) {
        return ModalService.instance = ModalService.instance || this;
    }

    show(modalWindow: any, data?: any) {
        this.store$.dispatch(clearAlertMessage());
        this.cms.getModalDetails(modalWindow.name, []).subscribe((dialog: IDialog) => {
            this.close();
            GlobalConstants.MODAL_REF = this.dialogService.open(modalWindow.component, {
                ...dialog.config,
                styleClass: data?.styleClass || dialog.klass || '',
                data: {
                    name: modalWindow.name,
                    cms: <IDialog>dialog,
                    data
                }
            });
        });
    }

    close() {
        if (GlobalConstants.MODAL_REF) {
            GlobalConstants.MODAL_REF.close();
            GlobalConstants.MODAL_REF.destroy();
        }
    }
}
