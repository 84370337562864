import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MESSAGES} from '@lib/models/lib.model';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {ICmsPage} from '@lib/cms/models/cms.model';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import {FormService} from '@lib/services/form.service';
import {MessageService} from '@lib/services/message.service';
import {BaseFormComponent} from '../base-component/base-form.component';

@Component({
    selector: 'field-confirm',
    templateUrl: './field-confirm.component.html',
    styleUrls: ['./field-confirm.component.scss']
})
export class FieldConfirmComponent extends BaseFormComponent implements OnInit, OnDestroy {
    confirmItem: any;
    form: UntypedFormGroup = new UntypedFormGroup({});
    page$: Observable<ICmsPage>;
    page: ICmsPage;
    pageSub$: Subscription;
    formConfirmSub$: Subscription;
    messageSub$: Subscription;
    passwordNotMatchValidator = {};

    constructor(
        private store$: Store,
        private formBuilder: UntypedFormBuilder,
        private fs: FormService,
        private ms: MessageService
    ) {
        super();
    }

    ngOnInit(): boolean {
        this.messageSub$ = this.ms.message.subscribe(obj => {
            if (obj.type === MESSAGES.SUBFORM_TOUCHED) {
                if (this.group.status === 'VALID' || this.form.status === 'VALID') {
                    this.group.controls[this.item.name].clearValidators();
                    this.group.controls[this.item.name].setErrors(null);
                    this.group.setErrors(null);
                    this.group.markAllAsTouched();
                    this.group.updateValueAndValidity();
                    this.ms.setMessage({type: MESSAGES.PAGE_SUBMIT, data: null});
                } else {
                    this.form.markAllAsTouched();
                    this.form.updateValueAndValidity();
                    this.group.markAllAsTouched();
                    this.group.updateValueAndValidity();
                }
            }
        });

        if (this.data.passwordExists) {
            this.group.controls[this.item.name].setErrors(null);
            this.group.setErrors(null);
            return false;
        }

        this.page$ = this.store$.select(getCmsPageContent);
        this.confirmItem = Object.assign({}, this.item);
        this.confirmItem.name = this.item.name + 'Confirmation';
        this.confirmItem.label = this.item.extendedParams?.confirmLabel ?? 'Confirm ' + this.item.label;
        this.confirmItem.placeholder = this.item.extendedParams?.confirmPlaceholder ?? 'Confirm ' + (this.item.placeholder ?? this.item.label);

        this.pageSub$ = this.page$.subscribe(page => {
            this.page = page;
            this.confirmItem = Object.assign({}, this.page?.formFields[this.confirmItem.name]);
            this.confirmItem.validations = {...this.confirmItem.validations, ...this.item.validations};
            this.buildForm();
        });

        // Local group temporary to complete password
        this.form.statusChanges.subscribe(status => {
            this.onConfirmPasswordChange(status === 'VALID');
        });

        return true;
    }

    buildForm() {
        const formJson = {};
        this.passwordNotMatchValidator = this.confirmItem.validations['passwordNotMatch'];
        delete this.confirmItem.validations['passwordNotMatch'];
        formJson[this.item.name] = new UntypedFormControl('', this.fs.getValidatorOptions(this.item.validations));
        formJson[this.confirmItem.name] = new UntypedFormControl('', this.fs.getValidatorOptions(this.confirmItem.validations));
        this.form = this.formBuilder.group(formJson, {
            validators: this.passwordMatch.bind(this)
        });
    }

    passwordMatch(formGroup: UntypedFormGroup) {
        if (!this.confirmItem?.name) {
            return null;
        }
        const {value: password} = formGroup.get(this.item.name);
        const {value: confirmPassword} = formGroup.get(this.confirmItem.name);
        return password === confirmPassword ? null : {passwordNotMatch: true};
    }

    onConfirmPasswordChange(isMatch: boolean) {
        if (isMatch) {
            this.formControl?.setValue(this.confirmFormControl.value);
            this.formControl.setErrors(null);
            this.formControl.markAsTouched();
            this.formControl.updateValueAndValidity();
            return true;
        } else {
            this.formControl?.setValue('');
            this.formControl.setErrors({required: true});
            this.formControl.markAsUntouched();
            this.formControl.updateValueAndValidity();
            return false;
        }
    }

    get confirmFormControl() {
        return this.form.controls[this.confirmItem.name];
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.form = null;
        this.confirmItem = null;
        this.page$ = null;
        this.pageSub$?.unsubscribe();
        this.messageSub$?.unsubscribe();
        this.formConfirmSub$?.unsubscribe();
    }

}
