import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {getCmsPageContent, getGlobalContent} from '../../cms/store/cms/cms.selector';
import {BaseComponent} from '../base/base.component';
import {IMenuItem} from '../../models/lib.model';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '@app/app.state';
import { ICmsPage } from '@lib/cms/models/cms.model';

@Component({
    selector: 'lib-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {
    page$: Observable<ICmsPage>;
    global$: Observable<any>;

    constructor(private store: Store<AppState>, private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.global$ = this.store.select(getGlobalContent);
        this.page$ = this.store.select(getCmsPageContent);
    }

    onClick(menu: IMenuItem, event:Event) {
        if (menu.onClick == "newTab" || this.newTabKeyPressed(event)) {
            const url = this.isAbsoluteUrl(menu.url) ? menu.url : this.router.createUrlTree([menu.url]).toString();
            window.open(url, '_blank');
            event.preventDefault();
        }
        else {
            if (this.isAbsoluteUrl(menu.url)) {
                window.location.href = menu.url;
            }
            else {
                this.router.navigate([menu.url]);
            }
        }
    }

    isAbsoluteUrl(url : string) {
        return (url.startsWith('http') || url.startsWith('tel:') || url.startsWith('mailto:'))
    }

    newTabKeyPressed(event: Event) {
        const mouse = <MouseEvent>event;
        const keyboard = <KeyboardEvent>event;
        if (mouse && (mouse.shiftKey || mouse.ctrlKey || mouse.metaKey)) return true;
        if (keyboard && (keyboard.shiftKey || keyboard.ctrlKey || keyboard.metaKey)) return true;
        return false;
    }

}
