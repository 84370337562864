<ng-container [formGroup]="group">
    <div class="field-checkbox" *ngIf="$any(group).controls[item.name]">
        <p-checkbox [formControl]="$any(group).controls[item.name]" [id]="item.name" [binary]="true"
                    [label]="item.label" [disabled]="readonly" (onChange)="onChange($event)">
        </p-checkbox>
        <!--        <label [for]="item.name">{{item.label}}</label>-->
    </div>
    <ng-container *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
        <small class="p-error block"
               *ngFor="let errKey of errorKeys; trackBy: trackByFn;">{{item.validations[errKey]?.message}}</small>
    </ng-container>
</ng-container>
