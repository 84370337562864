import {Injectable} from '@angular/core';
import {AppState} from '@app/app.state';
import {ALERT_TYPES, IAlert} from '../models/lib.model';
import {Store} from '@ngrx/store';
import {setAlertMessage} from '@lib/store/shared.actions';
import {Apollo, gql} from 'apollo-angular';
import {jsonToGraphQLQuery} from 'json-to-graphql-query';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export enum IEndPoint {
    LMS_ENDPOINT = 'lms',
    AMS_ENDPOINT = 'ams',
    API_ENDPOINT = 'api'
}

interface IQuery {
    __args?: {};

    [key: string]: any;
}

interface IDataTableQuery {
    name?: string;
    items?: any;
    count?: number;
    loading?: boolean;
    __args?: {
        filter: string;
        limit?: number;
        skip?: number;
    }
}

export interface IApplicationQuery extends IDataTableQuery {
    dataNodeName?: string;
    dataParentNodeName?: string;

    [key: string]: any;
}

@Injectable({
    providedIn: 'root'
})
export class QueryApiService {
    constructor(private apollo: Apollo, private store$: Store<AppState>) {
    }

    getQuery(request: {endPoint: string, query: any}): Observable<any> {
        const query = {
            query: request.query
        };
        const graphql_query = gql`${jsonToGraphQLQuery(query, {pretty: true})}`;
        return this.apollo.use(request.endPoint || IEndPoint.AMS_ENDPOINT).query<any>({
            query: graphql_query,
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
            context: {loading: true}
        })
            .pipe(map((data, errors) => {
                if (data?.errors?.length) {
                    this.store$.dispatch(setAlertMessage({
                        errorCode: ALERT_TYPES.ERROR,
                        data: data.errors.map(
                            (e: any) => e.message + ' at ' + e.path?.join(' -> ')
                        ),
                        severity: ALERT_TYPES.ERROR,
                        summary: ALERT_TYPES.ERROR
                    } as IAlert));
                }
                return data;
            }));
    }

    /**
     * Build query with root and root args
     * @param queryName
     * @param dataPoints
     * @param args
     */
    getQueryBody(queryName: string, dataPoints: {[key: string]: any}, args?: {[key: string]: any}) {
        const body = {};
        body[queryName] = {
            ...dataPoints,
            __args: args
        };

        return body;
    }

    doMutation(request: {endPoint: string, payload: any}): Observable<any> {
        const query = {
            mutation: request.payload
        };
        const graphql_query = gql`${jsonToGraphQLQuery(query, {pretty: true})}`;
        return this.apollo.use(request.endPoint).mutate<any>({
            mutation: graphql_query,
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
            context: {loading: true}
        })
            .pipe(map((data, errors) => {
                if (errors !== 0) {
                    console.log(errors);
                }
                return data;
            }));
    }

    /**
     * Build query with root and root args
     * @param queryName
     * @param dataPoints
     * @param args
     */
    getMutationBody(queryName: string, dataPoints: {[key: string]: string}, args?: {[key: string]: any}) {
        const body = {};
        body[queryName] = {
            ...dataPoints,
            __args: args
        };

        return body;
    }
}
