import {Component, Input, OnInit} from '@angular/core';
import {BaseFormComponent} from '../base-component/base-form.component';

@Component({
  selector: 'lib-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends BaseFormComponent implements OnInit {
  @Input() rows = 5;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
