import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppState} from '@app/app.state';
import {MODAL_WINDOWS} from '@app/core/models/core.model';
import {AppService} from '@app/core/services/app.service';
import {BaseModalComponent} from '@lib/components/base-modal/base-modal.component';
import {IAlert, IDialog} from '@lib/models/lib.model';
import {IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {UtilService} from '@lib/services/util.service';
import {getAlerts, getAppConfig} from '@lib/store/shared.selector';
import {IAppConfig} from '@lib/store/shared.state';
import {Store} from '@ngrx/store';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

enum PAYMENT_METHOD_TYPE {
    DEBIT_CARD = 2,
    DEBIT_CARD_NAME = 'DEBIT',
    NONE = 'NONE'
}

@Component({
    selector: 'app-edit-autopay-modal',
    templateUrl: './edit-autopay-modal.component.html',
    styleUrls: ['./edit-autopay-modal.component.scss']
})
export class EditAutopayModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    formFieldKeys: string[];
    alert$: Observable<IAlert>;
    cms: IDialog;
    appConfig: IAppConfig;
    paymentMethodOption: any;
    fg: UntypedFormGroup = new UntypedFormGroup({
        paymentMethodId: new UntypedFormControl('')
    });

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private store$: Store<AppState>,
        private qs: QueryApiService,
        private router: Router,
        private appService: AppService
    ) {
        super(ref, config);
    }

    ngOnInit(): void {
        this.formFieldKeys = Object.keys(this.data?.cms.formFields);
        this.alert$ = this.store$.select(getAlerts);
        this.store$.select(getAppConfig).pipe(takeUntil(this.unsubscribe))
            .subscribe(config => {
                if (config) {
                    this.appConfig = config;
                    this.postInit();
                }
            });
    }

    postInit() {
        if (this.data?.cms.name === MODAL_WINDOWS.DEAUTHORIZE_PAYMENT_METHOD.name && !this.data.data.showDeAuthModal) {
            this.cta('close');
            this.onSubmit('', this.data.data.actions);
        }

        if (this.data?.cms.name === MODAL_WINDOWS.ENABLE_AUTOPAY.name) {
            this.doPaymentMethodOptions();
            this.fg.controls['paymentMethodId'].addValidators(Validators.required);
        } else {
            this.fg.controls['paymentMethodId'].removeValidators(Validators.required);
        }
    }

    getLookupOptions(obj: {name: string, values: any, target: any, filterBy?: string, return?: string}) {
        this[obj.target] = {
            ...this.config.data?.cms.formFields[obj.name],
            option: {
                allowCustom: false,
                minSearchLength: 0,
                filterBy: obj.filterBy || "value",
                return: obj.return || "key",
                isFilterable: false,
                values: obj.values
            }
        };
    }

    /**
     * Only supported payment types are shown when exists
     * paymentTypeId: 1 (Regular)
     */
    doPaymentMethodOptions() {
        this.getLookupOptions({
            name: 'paymentMethodId',
            values: this.data.data.data.paymentMethods.map(o => {
                return {
                    ...o,
                    id: o.id,
                    accountNumber: `****${o.accountNumber.slice(-4)} ( ${o.name} )`
                };
            }).filter(Boolean),
            target: 'paymentMethodOption',
            filterBy: 'accountNumber',
            return: 'id'
        });

        this.fg.controls['paymentMethodId'].setValue(this.data.data.data.paymentMethods[0]);
    }

    getPaymentMethodType(type, name) {
        if (type === 'loan') {
            // return ` ( ${UtilService.transformFirstLetterUpperCase(type)}: ${name} )`;
            return '';
        } else if (type) {
            return ` ( ${UtilService.transformFirstLetterUpperCase(type)}: ${name} )`;
        } else {
            const n = `${name}`;
            return n ? ` ( ${UtilService.transformFirstLetterUpperCase(name)} )` : '';
        }
    }

    onSubmit(action?: string, actions?: any) {
        this.loading = true;
        if (this.fg.invalid) {
            this.loading = false;
            this.fg.markAllAsTouched();
            this.fg.updateValueAndValidity();
            return false;
        }

        const input = {
            loanId: this.data.data.loanId
        };

        if (this.fg.value['paymentMethodId']) {
            input['paymentMethodId'] = this.fg.value['paymentMethodId'];
        }
        const mutationName = Object.keys(this.data?.cms.extendedParams.mutation)[0];

        if (mutationName === 'updatePaymentMethod') {
            delete input['loanId'];
            input['name'] = this.data.data.data.name;
            input['paymentMethodId'] = this.data.data.data.id;
            input['authorized'] = !this.data.data.data.authorized;
            input['paymentMethodCollectionId'] = this.data.data.data.paymentMethodCollectionId;
        }

        this.qs.doMutation({
            endPoint: IEndPoint.LMS_ENDPOINT,
            payload: this.qs.getMutationBody(mutationName, this.data?.cms.extendedParams.mutation[mutationName], {input})
        }).subscribe(res => {
            if (res.data[mutationName][mutationName + 'Result']) {
                this.cta(action, actions, {...res.data[mutationName][mutationName + 'Result'], mutation: mutationName});
            } else {
                this.cta(action, actions, {...res.data[mutationName], mutation: mutationName});
            }
            this.loading = false;
        }, error => {
            this.loading = false;
        });

        return true;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
