import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {APP_COMPONENT_TYPES} from '@app/core/models/core.model';
import {CMS_PAGE_TYPES, ICmsPage, IFormField} from '../../../cms/models/cms.model';
import {BaseComponent} from '../../base/base.component';
import {FORM_FIELD, FormService} from '../../../services/form.service';
import {UtilService} from '../../../services/util.service';
import {EntryFields} from 'contentful';
import Object = EntryFields.Object;

@Component({
    selector: 'lib-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() cmsPage: ICmsPage;
    @Input() group: UntypedFormGroup;
    @Input() data: {
        [key: string]: any;
    };
    @Input() pageGetSchema: {
        [key: string]: any;
    };
    @Input() schema: any;
    @Output() formInitiated: EventEmitter<any> = new EventEmitter();
    pageFields: IFormField[];
    componentTypes = APP_COMPONENT_TYPES;

    constructor(private formService: FormService) {
        super();
    }

    get cmsFieldNames() {
        return Object.keys(this.cmsPage?.formFields);
    }

    get formFieldNames() {
        return Object.keys(this.group?.controls);
    }

    schemaKeys(parentKey?: string) {
        const obj = parentKey ? this.schema[parentKey] : this.schema;
        return Object.keys(obj);
    }

    get subFormList() {
        return [FORM_FIELD.SUB_FORM];
    }

    get readonly() {
        return this.cmsPage.type === CMS_PAGE_TYPES.READ_ONLY;
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.group && changes['schema'] && changes['schema'].previousValue !== changes['schema'].currentValue) {
            this.onModalChange();
        }
    }

    onModalChange() {
        this.formBuilder();
    }

    /**
     * 1. Prepare form schema model to feed to form builder
     * 2. Build FormGroup from form model
     * 3. Set cms fields
     * 4. Patch form with data
     * 5.
     * @param visitedPage
     */
    formBuilder() {
        const fields = {...this.data, ...this.schema};
        const formModel = this.buildFieldsModel(fields);
        this.orderFields(formModel);
        this.buildFormGroup(formModel);
        this.patchForm();
        if (!this.readonly || !this.schema['$when']) {
            this.applyFormRules(formModel, this.schema['$when']);
        }
    }

    /**
     * Set type, disable & validations before rendering form
     * Visited page GET is 200, but 204 if not visited
     * Combine pageSchema, cmsFormFields & pageDataPoints
     * Recursive functions to consider sub-forms and combo fields
     */
    buildFieldsModel(buildFieldsData: string[], parentKey?: string): any {
        const newFormFields: IFormField[] = [];
        Object.keys(buildFieldsData).forEach((formFieldKey) => {
            const cmsField = this.cmsPage.formFields[formFieldKey];
            if (cmsField) {
                const formField: IFormField = cmsField ? {...cmsField} : {name: formFieldKey};
                const formFieldSchema = parentKey ? this.schema[parentKey][formFieldKey] : this.schema[formFieldKey];
                const isSubForm = this.subFormList.includes(cmsField.type);

                formField.disabled = !this.schemaKeys(parentKey).includes(formFieldKey);
                if (isSubForm) {
                    const subFields = this.buildFieldsModel(buildFieldsData[formFieldKey], formFieldKey);
                    formField.subFormFields = subFields;
                } else {
                    formField.validations = {...formField.validations};
                }

                if (formFieldSchema && !isSubForm) {
                    formField.type = cmsField.type;
                    formField.validations = formFieldSchema.validations;
                    formField.option = formFieldSchema.option;

                    if (formField.type === 'string' && formFieldSchema.validations) {
                        formField.minLength = formFieldSchema.validations['minlength']?.value;
                        formField.maxLength = formFieldSchema.validations['maxlength']?.value;
                    }
                } else {
                    formField.type = cmsField && cmsField?.type;
                }

                newFormFields.push(formField);
            }
        });

        return newFormFields;
    }

    orderFields(fields: any) {
        this.pageFields = UtilService.sort(fields, 'order');
    }

    buildFormGroup(fields: any[]) {
        this.formService.modelToForm(this.group, fields);
    }

    applyFormRules(fields: any[], $when: any) {
        this.formService.applyFormRules(this.group, fields, $when);
    }

    patchForm() {
        if (UtilService.isNonEmpty(this.data)) {
            this.group.patchValue(this.data);
            this.group.updateValueAndValidity();
        }
    }

    /**
     * Throw warning or errors in console
     * TODO add validation errors to AppInsights
     */
    checkFormValidity() {
        const cmsMissingFields = UtilService.compareArray(this.cmsFieldNames, this.formFieldNames);
        const formSchemaMissingFields = UtilService.compareArray(this.formFieldNames, this.cmsFieldNames);
        if (cmsMissingFields.length > formSchemaMissingFields.length) {
            console.error('DEV ERROR: Please remove form fields from CMS');
            console.error(cmsMissingFields);
        }
        if (cmsMissingFields.length < formSchemaMissingFields.length) {
            console.error('DEV ERROR: Please add below form fields in CMS');
            console.error(formSchemaMissingFields);
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.group = null;
        this.pageFields = [];
        this.cmsPage.formFields = [];
        this.formService.clearSubscriptions();
    }
}
