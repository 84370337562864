<ng-container *ngIf="model as m" class="document">
    <div class="document-header" *ngIf="m.type === 'header' && m.data.items as items">
        <div>{{m.cms[items[0].documentType].label}}</div>
        <div class="document-header__count">
            {{items.length}} {{m.cms.config.documentsTotalCountText}}{{items.length > 1 ? 's' : ''}}
        </div>
    </div>
    <div class="document-content" *ngIf="m.type === 'content' && m.data.items as items">
        <ng-container *ngFor="let doc of items;let i = index;">
            <a tabindex="0" class="break-line" (click)="viewDocument(doc)" (keyup.enter)="viewDocument(doc)"
               [ngClass]="{'hide-more': showMore && m.cms.config?.defaultDocumentsCount < (i+1)}">
                {{doc.fileName}}
            </a>
        </ng-container>
        <div *ngIf="m.cms.config?.defaultDocumentsCount + 1 <= items.length">
            <a tabindex="0" class="more-or-less" *ngIf="!showMore" (click)="moreOrLess()"
               (keyup.enter)="moreOrLess()">
                {{m.cms.config?.lessLabel}} <i class="pi pi-angle-double-up"></i>
            </a>
            <a tabindex="0" class="more-or-less" *ngIf="showMore" (click)="moreOrLess()"
               (keyup.enter)="moreOrLess()">
                {{m.cms.config?.moreLabel}} <i class="pi pi-angle-double-down"></i>
            </a>
        </div>
    </div>
</ng-container>
