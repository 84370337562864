import { HttpClient } from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {APP_STORAGE, AppStorageService} from '@app/core/services/app-storage.service';
import { AppService } from '@app/core/services/app.service';
import {UnsubscribeOnDestroyAdapter} from '@lib/adapters/unsub-on-destroy.adapter';
import {CMS_PAGE_ROUTES, ICmsPage} from '@lib/cms/models/cms.model';
import {clearPageContent, loadCmsPageContent, loadCmsPageContentSuccess} from '@lib/cms/store/cms/cms.actions';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import { getUserProfile } from '@lib/cms/store/common/common.selector';
import {IAlert, IUrlContext} from '@lib/models/lib.model';
import {getRouteQueryParams} from '@lib/router/router.selector';
import { ApiService } from '@lib/services/api.service';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {FormService} from '@lib/services/form.service';
import { UserService } from '@lib/services/user.service';
import {clearAlertMessage} from '@lib/store/shared.actions';
import {getAlerts} from '@lib/store/shared.selector';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

enum ACTIONS {
    LOGIN,
    RESUME
};

export enum POST_LOGIN_METHOD {
    PASSWORD,
    CHALLENGE,
    OTP
};

export enum POST_LOGIN_ACTION {
    ACCOUNT,
    APPLICATION
};

type PostLoginResponse = {
    method: POST_LOGIN_METHOD;
    postLogin: POST_LOGIN_ACTION,
    email? : string,
    phone? : string,
    applicationId : string
}

type AuthenticatedResponse = {
    accessToken: string
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
    page$: Observable<ICmsPage>;
    page: ICmsPage;
    alert$: Observable<IAlert>;
    otpGroup = new UntypedFormGroup({
        otpCode: new UntypedFormControl('')
    })
    fg = new UntypedFormGroup({
        email: new UntypedFormControl('')
    });
    loading: boolean;
    routeQueryParams$: Observable<any>;
    routeQueryParams: any;
    urlContext? : IUrlContext;
    ACTIONS = ACTIONS;
    resendDisabled: boolean;

    constructor(
        private store$: Store,
        private router: Router,
        private fs: FormService,
        private http: HttpClient,
        private apiService: ApiService,
        private authProvider: AuthProviderService,
        private appStorage: AppStorageService,
        private userService : UserService,
        private appService : AppService
    ) {
        super();
    }

    postLoginResponse? : PostLoginResponse;
    skipEmailPrompt : boolean = false;

    ngOnInit(): void {
        this.store$.dispatch(loadCmsPageContent({pathName: APP_PAGE_ROUTES.LOGIN}));
        this.page$ = this.store$.select(getCmsPageContent);
        this.alert$ = this.store$.select(getAlerts);
        this.routeQueryParams$ = this.store$.select(getRouteQueryParams);
        this.urlContext = JSON.parse(sessionStorage.getItem('launchParams'));
        const route = this.urlParam.split('?')[0];
        if (route !== CMS_PAGE_ROUTES.LOGIN) {
            this.router.navigate([APP_PAGE_ROUTES.PAGE_404]);
        }

        this.store$.select(getUserProfile).pipe(takeUntil(this.unsubscribe)).subscribe(profile => {
            if (profile?.email) {
                this.onPageLoad();
            }
        });

        this.routeQueryParams$.subscribe(routeQueryParams => {
            this.routeQueryParams = routeQueryParams;
            if (this.routeQueryParams?.email) {
                this.onPageLoad();
            }
        });

        this.page$.pipe(takeUntil(this.unsubscribe)).subscribe((page: ICmsPage) => {
            if (page && page.formFields && page.name === APP_PAGE_ROUTES.LOGIN) {
                this.page = page;
                this.onPageLoad();
            }
        });
        
        if (this.routeQueryParams['applicationId']) {
            this.skipEmailPrompt = true;
            this.http.post(this.apiService.buildUrl("authentication"), {
                email: null,
                action: ACTIONS.RESUME,
                applicationId : this.routeQueryParams['applicationId']
            }).subscribe((r) => 
            {
                const resp = <PostLoginResponse>r;
                this.onPostLogin(resp);
            });
        }

    }

    /**
     * Show form if login is requested else post authentication
     * @param page
     */
    onPageLoad() {
        if (!(this.page && this.routeQueryParams)) {
            return false;
        }

        if (this.isActionLogin) {
            this.fg.controls['email'].addValidators(this.fs.getValidatorOptions(this.page.formFields['email'].validations));
        } else {
            this.onSubmit();
        }

        return true;
    }

    get urlParam() {
        return this.router.url.substring(1);
    }

    /**
     * /login?email=test@test.com - resume
     * /login - Sign page with form
     */
    get action() {
        return this.isActionLogin ? ACTIONS.LOGIN : ACTIONS.RESUME;
    }

    get isActionLogin() {
        return !this.routeQueryParams.email;
    }

    onSubmit(): boolean {
        this.loading = true;
        if (this.fg.invalid) {
            this.loading = false;
            this.fg.markAllAsTouched();
            this.fg.updateValueAndValidity();
            return false;
        }
        this.loading = true;
        const payload = {
            email: this.fg.controls['email'].value || this.routeQueryParams.email,
            action: this.action
        };

        if (payload.email) {
            this.appStorage.setItem(APP_STORAGE.EMAIL, payload.email);
            this.http.post(this.apiService.buildUrl('authentication'), payload)
            .subscribe({ next : response => {
                this.onPostLogin(<PostLoginResponse>response);
            }});
        }

        return true;
    }

    resendEmailOtp($event: any) : boolean {
        this.loading = true;
        this.resendDisabled = true;
        this.http.post(this.apiService.buildUrl("otp/email"), {
            applicationId: this.postLoginResponse.applicationId,
        }).subscribe({next: r => {
            this.loading = false;
            setTimeout(this.reenableResend.bind(this), 30000);
        }});
        return false;

    }

    reenableResend() {
        this.resendDisabled = false;
    }

    onOtpSubmit() : boolean {
        if (this.otpGroup.invalid) {
            this.otpGroup.markAllAsTouched();
            this.otpGroup.updateValueAndValidity();
        }
        this.loading = true;
        this.http.post(this.apiService.buildUrl("authentication/otp"), {
            otpCode: this.otpGroup.controls['otpCode'].value,
            applicationId: this.postLoginResponse.applicationId,
            source: 'email'
        }).subscribe({next: this.onAuthenticated.bind(this), error: e => {
                this.loading = false;
            }
        });

        return true;
    }

    /**
     * “method”: “password | challenge | otp” ( Enums: 0 | 1 | 2 )
     * “postLogin”: “account | application”  ( Enums: 0 | 1 )
     * @param response
     */
    onPostLogin(response : PostLoginResponse) {
        this.postLoginResponse = response;

        if (response.method === POST_LOGIN_METHOD.PASSWORD) {
                this.appStorage.setItem(APP_STORAGE.POST_LOGIN_RESPONSE, response);
                this.authProvider.login();
                this.loading = false;
        }
        else if (response.method === POST_LOGIN_METHOD.OTP) {
            this.resendEmailOtp({});
        }
        else
        {
            this.loading = false;
        }

        //ignore the other options since this component will handle them
        //     case POST_LOGIN_METHOD.CHALLENGE:
        //         this.appStorage.removeItem(APP_STORAGE.EMAIL);
        //         if (response.applicationId) {
        //             this.router.navigate([response.applicationId, 'challenge']);
        //         } else {
        //             console.error('ERROR: ApplicationId is not existing.');
        //         }
        //         this.loading = false;
        //         break;
        //     case POST_LOGIN_METHOD.OTP:
        //         this.appStorage.removeItem(APP_STORAGE.EMAIL);
        //         if (response.applicationId) {
        //             this.router.navigate([response.applicationId, 'resume']);
        //         } else {
        //             console.error('ERROR: ApplicationId is not existing.');
        //         }
        //         this.loading = false;
        //         break;
        // }
    }

    onAuthenticated(response : AuthenticatedResponse) {
        this.userService.logIn({
            access_token : response.accessToken,
            refresh_token : null,
            id_token : null
        });

        if (this.postLoginResponse.postLogin == POST_LOGIN_ACTION.APPLICATION) {
            //go back to application route. 
            if (!this.urlContext?.name || this.urlContext.name === 'get-started') {
                this.appService.getCurrentApplication(<string>this.postLoginResponse.applicationId, null, null);
            }
            else
            {
                this.router.navigate([this.postLoginResponse.applicationId, this.urlContext.name]);
            }
        }
        else if (this.postLoginResponse.postLogin == POST_LOGIN_ACTION.ACCOUNT) {
            this.router.navigate([APP_PAGE_ROUTES.ACCOUNT_OVERVIEW])
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.store$.dispatch(clearAlertMessage());
        this.store$.dispatch(loadCmsPageContentSuccess({}));
        this.store$.dispatch(clearPageContent());
    }

}
