<p-accordion *ngIf="model && cms" [multiple]="cms.config.allowMultiple || false">
    <ng-container *ngFor="let tab of tabs;let i=index;">
        <p-accordionTab [disabled]="model.data[tab].disabled"
                        [selected]="(i==0 && cms.config.expandFirstByDefault)? true : false">
            <ng-template pTemplate="header">
                <ng-container
                        [model]="{data: model.data[tab], type: 'header', cms, appId: model.appId}"
                        [template]="template"
                        appDynamicComponentRender
                        class="header"
                ></ng-container>
            </ng-template>
            <ng-template pTemplate="content">
                <ng-container
                        [model]="{data: model.data[tab], type: 'content', cms, appId: model.appId}"
                        [template]="template"
                        appDynamicComponentRender
                        class="content"
                ></ng-container>
            </ng-template>
        </p-accordionTab>
    </ng-container>
</p-accordion>
