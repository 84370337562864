<div class="data-table-cell"
     [ngClass]="{'highlight-text': model.col.field === model.rowData.hightlightField && model.rowData.hightlight}">
    <!-- Direct field, not a nested object -->
    <span *ngIf="model.col.field.split('.').length === 1 && model.col.type !== 'customFormat'"
          class="{{model.col.klass}}">
                       {{model.rowData[model.col.field] | dataFormat: {
        model: model.col,
        data: model.rowData,
        timezone: model.timezone
    } }}
    </span>
    <!-- Direct field, not a nested object - customFormat, dynamic component-->
    <span *ngIf="model.col.field.split('.').length === 1 && model.col.type === 'customFormat'"
          class="{{model.col.klass}}">
        <ng-container appDynamicComponentRender [model]="{checked: model.rowData?.authorized}"
                      [template]="model.col.format"></ng-container>
    </span>
    <!-- Handle nested object - JSON Array | JSON Object - TODO: customFormat template, Audit uses custom template -->
    <span *ngIf="model.col.field.split('.').length !== 1" class="{{model.col.klass}}">
        <!-- Handle nested object - JSON Object -->
        <ng-container *ngIf="!model.rowData[model.col.field.split('.')[0]]?.length">
            {{model.col.format | dataFormat: {
            model: model.col,
            data: model.rowData,
            timezone: model.timezone
        } }}
        </ng-container>
        <!-- Handle nested object - JSON Array -->
        <ng-container *ngIf="model.rowData[model.col.field.split('.')[0]] as items">
            <ng-container *ngIf="items.length">
                <ng-container *ngFor="let rd of items;let i = index;">
                    <span class="break-line {{model.col.klass}}"
                          [ngClass]="{'hide-more': model.col.extendedParams?.moreOrLess && showMore && model.col.extendedParams?.moreOrLess < (i+2),'last': i===items.length-1}">
                        {{rd[model.col.field] || model.col.format | dataFormat: {
                        model: model.col,
                        data: rd,
                        rootName: model.col.field.split('.')[0],
                        timezone: model.timezone
                    } }}</span>
                </ng-container>
                <ng-container *ngIf="model.col.extendedParams?.moreOrLess && model.col.extendedParams?.moreOrLess <= items.length">
                    <a tabindex="0" class="more-or-less" *ngIf="!showMore" (click)="moreOrLess()"
                       (keyup.enter)="moreOrLess()">
                        {{model.cms.formFields['less'].label}} <i class="pi pi-angle-double-up"></i>
                    </a>
                    <a tabindex="0" class="more-or-less" *ngIf="showMore" (click)="moreOrLess()"
                       (keyup.enter)="moreOrLess()">
                        {{model.cms.formFields['more'].label}} <i class="pi pi-angle-double-down"></i>
                    </a>
                </ng-container>
            </ng-container>
        </ng-container>
    </span>
</div>
