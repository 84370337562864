import {IAppDataState} from '@app/core/store/app-data.state';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const APP_DATA_STATE_NAME = 'app-data';
const getApplicationState = createFeatureSelector<IAppDataState>(APP_DATA_STATE_NAME);

export const getOffersContent = createSelector(getApplicationState, (state) => {
    return state?.offers;
});

export const getDocumentsContent = createSelector(getApplicationState, (state) => {
    return state?.documents;
});
