import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {getCurrentProgressStep, getPages, getProgressSteps} from '@lib/store/shared.selector';
import {getGlobalContent} from '../../cms/store/cms/cms.selector';
import {BaseComponent} from '../base/base.component';
import {UtilService} from '../../services/util.service';
import {Store} from '@ngrx/store';
import {getRouteParams} from '@lib/router/router.selector';
import {MenuItem} from 'primeng/api';
import {combineLatest, debounceTime, takeUntil} from 'rxjs';

@Component({
    selector: 'lib-page-breadcrumbs',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss']
})
export class StepsComponent extends BaseComponent implements OnInit, OnDestroy {
    steps: MenuItem[] = [];
    urlContext: any;
    currentPageIndex: number;
    lastAllowedPage: string;
    lastAllowedPageIndex: number;
    allowedPages: string[];
    currentProgressStep: string;

    constructor(private store$: Store, private router: Router) {
        super();
    }

    ngOnInit(): void {
        combineLatest(
            this.store$.select(getRouteParams),
            this.store$.select(getProgressSteps),
            this.store$.select(getPages),
            this.store$.select(getGlobalContent),
            this.store$.select(getCurrentProgressStep)
        )
            .pipe(
                debounceTime(500),
                takeUntil(this.unsubscribe)
            )
            .subscribe((results) => {
                this.buildBreadcrumbs(results);
            });

    }

    /**
     * results[0] = urlContext
     * results[1] = steps
     * results[2] = currentPage - Pages
     * results[3] = cmsPage
     * results[4] = progressStep
     * @param results
     */
    buildBreadcrumbs(results: any) {
        if (results[1]?.length) {
            this.steps = [];
            this.urlContext = results[0];
            this.mapSteps(results[1], results[3]);
            this.allowedPages = Object.keys(results[2] || {});
            this.currentProgressStep = results[4] || this.urlContext.page;
            this.lastAllowedPage = this.allowedPages[this.allowedPages.length - 1];
            this.setBreadcrumbsStates();
        }
    }

    mapSteps(list: string[], cmsSteps: any) {
        const steps = UtilService.arrayToObjectByKey(cmsSteps.progressSteps, 'name');
        list.map(step => {
            const mi = {
                label: steps[step]?.label,
                id: step
            };
            this.steps.push(mi);
        });
    }

    setBreadcrumbsStates() {
        this.currentPageIndex = this.findStepIndex(this.currentProgressStep);
        this.lastAllowedPageIndex = this.findStepIndex(this.lastAllowedPage) + 1;
    }

    findStepIndex(page: string) {
        let pgIndex = this.steps.findIndex(p => p.id?.toLowerCase() === page);
        if (page && pgIndex === -1) {
            const prevPage = this.allowedPages[this.allowedPages.indexOf(page) - 1];
            pgIndex = this.findStepIndex(prevPage);
        }
        return pgIndex;
    }

    goto(selectedIndex: any) {
        const step = this.steps[selectedIndex];
        if (step && step['cta'] && selectedIndex < this.lastAllowedPageIndex) {
            this.router.navigate([this.urlContext.id, step['id'].toLowerCase()]);
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
