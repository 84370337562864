import {Component, OnInit} from '@angular/core';
import {DynamicBaseComponent} from '../../dynamic-base-component/dynamic-base.component';

@Component({
    selector: 'app-icon-template',
    templateUrl: './icon-template.component.html',
    styleUrls: ['./icon-template.component.scss']
})
export class IconTemplateComponent extends DynamicBaseComponent implements OnInit {

    constructor() {
      super();
    }

    ngOnInit(): void {
    }

}
