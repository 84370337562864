import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'lib-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
    @Input() checked: boolean;
    @Input() klass = 'p-button-outlined';
    @Input() label = '';
    @Input() onLabel = '';
    @Input() offLabel = '';
    @Input() onIcon = 'pi pi-check';
    @Input() offIcon = '';
    @Output() callback: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    /**
     * When label is existing, on & off labels will be same
     */
    ngOnInit(): void {
        if (this.label) {
            this.onLabel = this.label;
            this.offLabel = this.label;
        }
    }

    onChange(e) {
        if (this.callback) {
            this.callback.emit(e.checked);
        }
    }

}
