import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {environment} from '@environment/environment';
import {AppState} from '@app/app.state';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    public isProduction: boolean = environment.production;
    config: any;
    private readonly CONFIG_URL_PATH = 'assets/json/';
    private readonly CONFIG_FILENAME = 'config.json';


    constructor(private http: HttpClient, private store: Store<AppState>) {
    }

    getConfig(): string {
        if (!environment.production && environment.clientId) {
            return `${this.CONFIG_URL_PATH}${environment.clientId}-${this.CONFIG_FILENAME}`;
        } else {
            return `${this.CONFIG_URL_PATH}${this.CONFIG_FILENAME}`;
        }
    }

    public async loadConfigurations(configDeps: (() => void)[]): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.http
                .get(this.getConfig())
                .toPromise()
                .then(config => {
                    this.config = config;
                    const cmsHandle = this.config['cmsHandle'];
                    if (this.config[cmsHandle + '.url']) {
                        this.config['cmsAssetUrl'] = this.config['strapi.url'].replace('/api', '');
                    }
                    return Promise.all(configDeps.map(dep => dep()));
                })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    get(name: string): any {
        return this.config[name];
    }

    onInitializeError(error: Error): Observable<Error> {
        throw error;
    }
}
