import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { NgxMaskService } from 'ngx-mask';
import {UtilService} from '../services/util.service';


@Pipe({
    name: 'dataFormat'
})
export class DataFormatPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer, private maskPipeService: NgxMaskService) {
    }

    /**
     * Replace CMS dynamic values as {dataPoint} with BE data
     * eg1: data = { test: 'John'}, stringValue = "Welcome {test}!"
     * Result: "Welcome John"
     *
     * eg2: data = { test: 1234}, stringValue = "Amount {test | currency}!"
     * Result: "Amount $123.00"
     *
     * eg2: data = { test: '2023-03-17T18:22:26.540Z'}, stringValue = "Date {test | date: 'MM/dd/yyyy'}!"
     * Result: "Date 03/17/2023"
     *
     * if {param1} {param2} {param3}(Count: 3) - RowData datapoint object has 10 records(Array) then 1 row will do 30 passes (3 x 10)
     *
     */
    replaceString(value, params: { model?: any, data?: any, rootName?: string }) {
        const fragments = typeof value?.match === 'function' && value?.match(/{([^}]+)}/g);
        let emptyString = '';
        if (fragments) {
            fragments.map((dataPointWithBraces, i) => {
                const dataPoint = dataPointWithBraces.replace(/{|}/g, '');
                if (dataPoint) {
                    const pipe = dataPoint.split('|');
                    emptyString = value.replace(dataPointWithBraces, '');
                    value = this.formatDataPoint(value, pipe, params, dataPointWithBraces, dataPoint);
                }
            });
        }

        return (emptyString !== value || emptyString === value) ? value : '';
    }

    /**
     * Angular supported pipes - default pipes
     * dateOffset - only timezone change
     * dateTimezone - append "timezone" string too. eg: CST, CDT etc
     * @param value
     * @param pipe
     * @param params
     * @param dataPointWithBraces
     * @param dataPoint
     */
    formatDataPoint(value, pipe, params, dataPointWithBraces, dataPoint) {
        let tempValue = value;
        // Data point format pipe
        // eg: ['amount', 'currency'] or ['date', 'date:MM/dd/yyyy']
        if (pipe[1]) {
            let model, data;
            // pipe[1] = currency or date:MM/dd/yyyy or date
            const format = pipe[1].split(':');
            switch (format[0]) {
                case 'currency':
                    model = {
                        type: 'string',
                        subType: pipe[1]
                    };
                    data = {};
                    break;
                case 'dateOffset':
                    if (params.timezone?.companyOffset) {
                        model = {
                            type: 'dateTimezone',
                            format: format[1] || 'MM/dd/yyyy hh:mm a'
                        };
                    }

                    data = {};
                    break;
                case 'dateTimezone':
                    if (params.timezone?.companyOffset) {
                        model = {
                            type: 'dateTimezone',
                            format: (format[1] || 'MM/dd/yyyy hh:mm a') + '|timezone'
                        };
                    }

                    data = {};
                    break;
                case 'date':
                    model = {
                        type: 'datetime',
                        format: format[1] || 'MM/dd/yyyy'
                    };
                    data = {};
                    break;
                case 'lookup':
                    model = {
                        name: pipe[0], 
                        type: 'lookup'
                    }
                    model[pipe[0].trim()] = params?.model[pipe[0].trim()];
                    data = {};
                    break;
                default:
                    break;
            }
            const formattedData = this.transform(this.getDataFromDataPoint(params, pipe[0]), {
                model,
                data,
                timezone: params.timezone
            });
            tempValue = formattedData && value.replace(dataPointWithBraces, formattedData);
        } else {
            tempValue = value.replace(dataPointWithBraces, this.getDataFromDataPoint(params, dataPoint) || '');
        }

        return tempValue;
    }

    /**
     * Supports upto 5 nested data points
     * eg: data = { dp1: { dp2: {dp3: 'Welcome'}}}, dataPoint='dp1.dp2.dp3';
     * Result = "Welcome"
     * @param data
     * @param dataPoint
     */
    getDataFromDataPoint(params: { model?: any, data?: any, rootName?: string }, dataPoint) {
        let data = {};

        if (params.rootName && !params.data[params.rootName]) {
            data[params.rootName] = params.data;
        } else {
            data = params.data;
        }
        return this.getValue(data, dataPoint);
        const dataPointNames = dataPoint.split('.');
        let returnValue;
        switch (dataPointNames.length) {
            case 1:
                returnValue = data[dataPointNames[0]];
                break;
            case 2:
                returnValue = data[dataPointNames[0]] && data[dataPointNames[0]][dataPointNames[1]];
                break;
            case 3:
                returnValue = data[dataPointNames[0]][dataPointNames[1]][dataPointNames[2]];
                break;
            case 4:
                returnValue = data[dataPointNames[0]][dataPointNames[1]][dataPointNames[2]];
                break;
            case 5:
                returnValue = data[dataPointNames[0]][dataPointNames[1]][dataPointNames[2]][dataPointNames[3]];
                break;
            default:
                break;
        }

        return returnValue;
    }

    /**
     * Custom pipes
     * Custom format will resolve to its own format method
     * @param value
     * @param params
     */
    transform(
        value: any,
        params: {
            model?: any,
            data?: any,
            rootName?: string,
            timezone?: {
                companyOffset?: string,
                companyTimezone?: string
            }
        }
    ): unknown {
        if (!value && value !== 0) {
            return '';
        }
        switch (params.model?.type) {
            case 'dateTimezone':
                // eslint-disable-next-line no-case-declarations
                const f = params.model.format.split('|');
                if (f[1]) {
                    value = UtilService.displayDate(<Date>value, f[0], params.timezone?.companyOffset, params.timezone?.companyTimezone);
                } else {
                    value = UtilService.displayDate(<Date>value, f[0], params.timezone?.companyOffset);
                }
                break;
            case 'datetime':
                // eslint-disable-next-line no-case-declarations
                const format = params.model.format.split('|');
                if (format[1] && params.timezone?.companyOffset) {
                    value = UtilService.displayDate(<Date>value, format[0], params.timezone?.companyOffset, format[1] ? params.timezone?.companyTimezone : '');
                } else {
                    value = UtilService.displayDate(<Date>value, format[0]);
                }
                break;
            case 'string':
                if (params.model.subType === 'currency') {
                    value = new CurrencyPipe('en-US').transform(<string>value, 'USD', 'symbol', '1.2-2');
                } else if (params.model.format) {
                    value = this.maskPipeService.applyMask(value, params.model.format);
                }
                break;
            case 'template':
                //{ city }, {state} {zipCode}
                if (params?.model?.template) {
                    if (params?.model?.useParent && params?.data) {
                        value = this.replaceString(params?.model.template, { model: params.model, data: params?.data});
                    }
                    else {
                        value = this.replaceString(params?.model.template, { model: params.model, data: value});
                    }
                }
                break;
            case 'lookup':
                if (params?.model[params?.model?.name]) {
                    value = params?.model[params?.model?.name][value];
                }

                break;
            default:
                if (params.data) {
                    value = this.replaceString(value, params);
                }
                if (params.model?.format && this[params.model?.format.split(':')[0]]) {
                    value = this[params.model?.format.split(':')[0]](value, params);
                }
                break;
        }
        return value;
    }

    getValue(obj, path : string) {
        return !path ? obj : path.split('.').reduce((a, v) => a == undefined ? a : a[v], obj);
    }

    html(value: unknown, params: { model?: any, data?: any, rootName?: string }) {
        console.log("html");
        return value;
    }

    /**
     * format: mask:numbers:characters
     * eg: mask:-4:*
     * @param value
     * @param params
     */
    mask(value: unknown, params: { model?: any, data?: any, rootName?: string }) {
        if (!UtilService.hasNumber(value)) {
            return value;
        }
        const digits = params.model?.format.split(':')[1] || 4;
        return UtilService.maskText(value, digits);
    }
}
