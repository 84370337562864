import {ComponentRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef} from '@angular/core';
import {PageBaseActionComponent} from '@lib//flow/page-base-action/page-base-action.component';
import {CompleteIbvComponent} from '@lib/components/actions/complete-ibv/complete-ibv.component';

const ACTIONS = {
  'complete-ibv': CompleteIbvComponent
};

@Directive({
  selector: '[appDynamicActionRender]'
})
export class DynamicActionRenderDirective implements OnInit, OnDestroy {
  @Input() actionType;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  componentRef: ComponentRef<PageBaseActionComponent>;

  constructor(public viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.viewContainerRef.clear();
    if (ACTIONS[this.actionType]) {
      this.componentRef = this.viewContainerRef.createComponent(ACTIONS[this.actionType]);
      this.componentRef.instance.actionType = this.actionType;
      this.componentRef.instance.onSubmit = this.onSubmit;
    }
  }

  ngOnDestroy(): void {
  }
}
