<div *ngIf="offer && !noOffer" class="offer">
    <div class="offer__item">
        <span class="offer-label">{{cmsPage.amountReceived}}</span>
        <span class="offer-value">{{offer.loanAmount | currency : 'USD' : 'symbol' : '1.0-2'}}</span>
    </div>
    <div class="offer__item">
        <span class="offer-label">
            {{offer.periods}}
            {{pageGetSchema['offers'] && pageGetSchema['offers']['item']['payFrequency']['option']['values'][offer.payFrequency || 0].value}}
            {{cmsPage.paymentsText}}
        </span>
        <span class="offer-value">{{offer.averagePaymentAmount | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
    </div>
    <div class="offer__item">
        <span class="offer-label">{{cmsPage.aprText}}</span>
        <span class="offer-value">{{offer.averageApr}}%</span>
    </div>
</div>
<div *ngIf="noOffer" class="offer no-offer">
    {{item.actionLabel}}
</div>
