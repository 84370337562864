import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {IFormField, IFormFieldValidation} from '../cms/models/cms.model';
import {CheckboxComponent} from '../components/form/checkbox/checkbox.component';
import {DatepickerComponent} from '../components/form/datepicker/datepicker.component';
import {InputComponent} from '../components/form/input/input.component';
import {LookupComponent} from '../components/form/lookup/lookup.component';
import {PasswordConfirmComponent} from '../components/form/password-confirm/password-confirm.component';
import {RadioComponent} from '../components/form/radio/radio.component';
import {SectionHeadingComponent} from '../components/form/section-heading/section-heading.component';
import {TextareaComponent} from '../components/form/textarea/textarea.component';
import {TextComponent} from '../components/text/text.component';
import {UtilService} from './util.service';
import {EntryFields} from 'contentful';
import {Subscription} from 'rxjs';
import Object = EntryFields.Object;
import { FieldConfirmComponent } from '@lib/components/form/field-confirm/field-confirm.component';
import { OtpComponent } from '@lib/components/form/otp/otp.component';

export const FORM_VALIDATORS = {
    required: Validators.required,
    min: (value) => Validators.min(value),
    max: (value) => Validators.max(value),
    pattern: (value) => Validators.pattern(value),
    maxlength: (value) => Validators.maxLength(value),
    minlength: (value) => Validators.minLength(value)
};

export const FORM_FIELD = {
    TEXT: 'text',
    OPTION: 'option',
    DATE_TIME: 'datetime',
    SELECT: 'select',
    RADIO: 'radio',
    CHECK: 'check',
    SUB_FORM: 'subform'
};

/**
 * Backend types
 */
export const FORM_FIELD_DEFAULTS = {
    'check': false,
    'datetime': null,
    'decimal': 0,
    'date': null,
    'select': ''
};

export const COMPONENT_TYPES = {
    string: InputComponent,
    datetime: DatepickerComponent,
    lookup: LookupComponent,
    radio: RadioComponent,
    check: CheckboxComponent,
    sectionHeading: SectionHeadingComponent,
    subform: SectionHeadingComponent,
    ssn: InputComponent,
    text: TextComponent,
    textarea: TextareaComponent,
    otp: OtpComponent,
    passwordConfirmation: PasswordConfirmComponent,
    confirmation : FieldConfirmComponent
};

@Injectable({
    providedIn: 'root'
})
export class FormService {
    subs: Subscription[] = [];

    modelToForm(form: UntypedFormGroup, model) {
        const group: any = {};
        Object.keys(model).map((key) => {
            const item: IFormField = model[key];
            if (Object.keys(item.subFormFields || '')?.length) {
                form.addControl(item.name, new UntypedFormGroup({}));
                this.modelToForm(<UntypedFormGroup>form.controls[item.name], item.subFormFields);
            }

            group[item.name] = new UntypedFormControl(
                {
                    value: item.nullable ? null : FORM_FIELD_DEFAULTS[item.type],
                    disabled: item.disabled
                },
                this.getValidatorOptions(item.validations)
            );

            form.addControl(item.name, group[item.name]);
        });
    }

    getValidatorOptions(validations: IFormFieldValidation[]): ValidatorFn[] {
        if (!validations) {
            return [];
        }

        const x = Object.entries(validations).map(([key, {value}]) => {
            if (!FORM_VALIDATORS[key]) {
                console.error('DEV ERROR: Missing Angular validation:: ' + key);
                console.log(key);
                return '';
            }
            return !value ? FORM_VALIDATORS[key] : FORM_VALIDATORS[key](value);
        });

        return x;
    }

    /**
     * Apply conditional rules - cross field validations
     * Check if field name is in the $when section, if exists, check expression and disable or enable form to hide/show
     * whenFieldKey - Subscription on key to trigger events
     * applyFieldKey - Rules applied on key
     * Apply validations
     *
     * Listen on whenFieldKey to applyRules
     * Load initial field
     * Expression check Eg: whenFieldValue $equal true
     * @param form
     * @param model
     * @param $when - Eg: { whenFieldKey: { $equal: true, applyFieldKey: { validations: []}} }
     */
    applyFormRules(form, model, $when): boolean {
        if (!$when) {
            return false;
        }

        let expression: string;
        Object.keys($when).map((whenFieldKey) => {
            Object.keys($when[whenFieldKey]).map((key) => {
                if (key.startsWith('$')) {
                    expression = key;
                    return false;
                }

                const applyFieldKey = key;
                // on page load
                const rightExp = $when[whenFieldKey][expression];

                // on whenFieldKey event
                this.subs[whenFieldKey] = new Subscription();
                this.subs[whenFieldKey] = form.controls[whenFieldKey]?.valueChanges.subscribe(selectedValue => {
                    if (UtilService.expressionCheck(expression, selectedValue, rightExp)) {
                        this.hideShowFields(false, form, model, applyFieldKey, $when[whenFieldKey][applyFieldKey]);
                    } else {
                        this.hideShowFields(true, form, model, applyFieldKey);
                    }
                });

                form.controls[whenFieldKey]?.updateValueAndValidity();
                form.controls[applyFieldKey]?.updateValueAndValidity();
                return true;
            });
        });

        return true;
    }

    /**
     * Hide (dsable) if formFroup or formControl - disabling form hides and validations are not performed
     * @param hide - toggle flag
     * @param form - formGroup
     * @param model - complete form model -  cms, validations, subfields etc
     * @param applyFieldKey - target field key
     * @param applyField - target field value
     */
    hideShowFields(hide: boolean, form, model, applyFieldKey: string, applyField?: any) {
        const field = UtilService.getObjFromList(model, applyFieldKey);
        field.hide = hide;
        form.controls[applyFieldKey].markAsUntouched();
        if (hide) {
            form.controls[applyFieldKey].disable();
        } else {
            form.controls[applyFieldKey].enable();
            this.mapModelFieldValidations(field, applyField);
            this.addSubKeyValidations(form, applyFieldKey, applyField);
        }
    }

    addSubKeyValidations(form, applyFieldKey, applyField) {
        Object.keys(applyField).map((subKey) => {
            let control: AbstractControl;
            if (subKey === 'validations') {
                control = form.controls[applyFieldKey];
                control.addValidators(this.getValidatorOptions(applyField.validations));
                control.markAsUntouched();
                control.updateValueAndValidity();
            } else if (form.controls[applyFieldKey].controls) {
                control = form.controls[applyFieldKey].controls[subKey];
                control.addValidators(this.getValidatorOptions(applyField[subKey].validations));
                control.markAsUntouched();
                control.updateValueAndValidity();
            }
        });
    }

    /**
     * Map validations respectively
     * group is a collection of sub form fields (Array)
     * group validations are a json object
     * @param group
     * @param groupValidations
     */
    mapModelFieldValidations(group, groupValidations) {
        group.validations = group.validations || groupValidations.validations;
        group.subFormFields?.map(subFormField => {
            const field = groupValidations[subFormField['name']];
            if (field) {
                subFormField.validations = field.validations;
            }
        });
    }

    clearSubscriptions() {
        this.subs = [];
    }

}
