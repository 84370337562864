import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppState} from '@app/app.state';
import { PAYMENT_TYPES } from '@app/core/components/offers/offers.component';
import {APP_PAGE_ROUTES, IPaymentMethod, MODAL_WINDOWS, PAYMENT_METHOD_TYPE} from '@app/core/models/core.model';
import {AccountBasePageComponent} from '@app/core/pages/account-base-page/account-base-page.component';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {ModalService} from '@lib/services/modal.service';
import {IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {StorageService} from '@lib/services/storage.service';
import {UtilService} from '@lib/services/util.service';
import {Store} from '@ngrx/store';
import {EnumType} from 'json-to-graphql-query';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent extends AccountBasePageComponent implements OnInit, OnDestroy {
    paymentMethodOption: any;
    paymentMethods: any;
    paymentSuccess = false;
    processDate: any;
    date = new Date();
    fullName: string;
    dateObj = {
        current: new Date(),
        minDate: new Date(),
        maxDate: null
    };
    effectiveDate: any;
    fg: UntypedFormGroup = new UntypedFormGroup({
        amount: new UntypedFormControl('', [Validators.required, Validators.min(0.01)]),
        paymentAmountType: new UntypedFormControl('minAmountDue', Validators.required),
        paymentMethodId: new UntypedFormControl('', Validators.required),
        date: new UntypedFormControl('', Validators.required)
    });
    paymentAmountTypeField: any;
    autopayEnabled = false;
    isAch: any;
    allPaymentMethods = [];
    selectedPaymentMethod: any;

    constructor(
        protected store$: Store<AppState>,
        protected storage: StorageService,
        protected authProvider: AuthProviderService,
        protected qs: QueryApiService,
        protected router: Router,
        private ms: ModalService
    ) {
        super(store$, storage, authProvider, qs, router);
    }

    ngOnInit(): void {
        super.getLoanData(APP_PAGE_ROUTES.PAYMENT, this.getPaymentMethods.bind(this));
    }

    getPaymentMethods() {
        if (!this.page) {
            return false;
        }

        this.qs.getQuery({
            endPoint: IEndPoint.LMS_ENDPOINT,
            query: this.qs.getQueryBody('loanPaymentMethods', this.page.extendedParams.queryConfig['getPaymentMethods']['loanPaymentMethods'], {loanId: this.loanId, action: new EnumType('DEBIT')})
        }).subscribe(res => {
            this.paymentMethods = [];
            res.data.loanPaymentMethods.map(pmc =>
                {
                    pmc.paymentMethods.filter(pm => pm.authorized).map(pm => {
                        this.paymentMethods.push(pm);
                    });
                });
                this.populateForm();
        });

        this.qs.getQuery({
            endPoint: IEndPoint.LMS_ENDPOINT,
            query: this.qs.getQueryBody('loan', this.page.extendedParams.queryConfig['loan'], {id: this.loanId})
        }).subscribe(res => {
            this.data = res.data.loan || [];
            this.populateForm();
        });


        this.fg.controls['paymentAmountType'].setValue(this.page.formFields['paymentAmountType'].options[0].value);
        this.fg.controls['paymentAmountType'].valueChanges.subscribe(() => this.onPaymentAmountTypeChange());
        this.fg.controls['paymentMethodId'].valueChanges.subscribe(() => this.onPaymentMethodIdChange());
        this.fg.controls['date'].valueChanges.subscribe(() => this.getEffectiveDate());
        return true;
    }

    populateForm() {
        const options = Object.assign([], this.page.formFields['paymentAmountType'].options);
        this.paymentAmountTypeField = Object.assign({}, this.page.formFields['paymentAmountType']);
        this.autopayEnabled = this.data?.autopay?.paymentSchedule.isActive;
        const borrower = this.data?.borrowers.filter(b => b.email === this.email)[0];
        if (borrower) {
            this.fullName = borrower.firstName + ' ' + borrower.lastName;
        }
        this.fg.controls['amount'].addValidators(Validators.min(this.appConfig?.paymentAmountRange?.min || 0.01));
        this.fg.controls['amount'].addValidators(Validators.max(this.appConfig?.paymentAmountRange?.max ?? 1000));
        if (!this.data?.state?.details || !this.data?.state.details?.nextPaymentAmount) {
            options[0] = {
                ...options[0],
                disabled: 'disabled'
            };
            this.fg.controls['paymentAmountType'].setValue('otherAmount');
            this.fg.controls['paymentAmountType'].updateValueAndValidity();
        }
        this.paymentAmountTypeField.options = options;
        this.onPaymentAmountTypeChange();
        if (this.data) {
        this.fg.patchValue({
            amount: this.data.state?.details?.nextPaymentAmount,
            date: this.data.state?.details?.nextPaymentAmountDate
        });
    }
        this.doPaymentMethodOptions();
        this.setDateRange();
        this.fg.controls['date'].setValue(UtilService.formatDate(new Date().toISOString()));
        this.fg.controls['date'].updateValueAndValidity();
    }

    setDateRange() {
        this.dateObj.minDate = new Date();
        this.dateObj.maxDate = UtilService.addDays(new Date(), this.appConfig.maxPaymentDaysInAdvance);
    }

    /**
     * Any new is selected, disable date field
     */
    onPaymentMethodIdChange() {
        const paymentMethod = this.fg.controls['paymentMethodId'].value;
        if (!Number.isInteger(paymentMethod)) {
            this.isAch = paymentMethod.processType === PAYMENT_METHOD_TYPE.ACH_NAME;
            this.selectedPaymentMethod = null;
            this.effectiveDate = null;
            this.setDateRange();
            this.fg.controls['date'].setValue(UtilService.formatDate(new Date().toISOString()));
            this.fg.controls['date'].disable();
        } else {
            this.selectedPaymentMethod = this.allPaymentMethods.filter(pm => pm.id === paymentMethod)[0];
            this.isAch = this.selectedPaymentMethod.paymentMethodType.processType === PAYMENT_METHOD_TYPE.ACH_NAME;
            this.fg.controls['date'].enable();
        }
        this.fg.controls['date'].updateValueAndValidity();
    }

    onPaymentAmountTypeChange() {
        if (this.fg.controls['paymentAmountType'].value !== 'otherAmount') {
            this.fg.controls['amount'].setValue(this.data.state?.details?.nextPaymentAmount);
            this.fg.controls['amount'].disable();
        } else {
            this.fg.controls['amount'].enable();
        }
    }

    /**
     * Only supported payment types are shown when exists
     * paymentTypeId: 1 (Regular)
     */
    doPaymentMethodOptions() {
        const dropdownGroups = [];
        if (this.paymentMethods == null || this.paymentMethods === undefined) return;
        this.appConfig.paymentMethodTypes.filter(type => type.processType !== PAYMENT_METHOD_TYPE.NONE).map(pmType => {
            const pmGroups = this.paymentMethods.filter(pm => pm.paymentMethodTypeId === pmType.id);
            const create = this.page.formFields['create'];
            pmGroups.push({
                id: null,
                label: pmType.display,
                key: {
                    paymentTypeId: create.extendedParams['paymentTypeId'],
                    processType: pmType.processType,
                },
                paymentMethodType: {display: pmType.display}
            });

            dropdownGroups.push({
                label: pmType.display,
                value: pmType.display.toLowerCase(),
                items: pmGroups.map(pm => {
                    pm.type = pmType.type;
                    this.allPaymentMethods.push(pm);
                    // const val = pmGroups[0].paymentMethodType.display + '-' + pm.accountNumber;
                    const createLabel = (create.label + ' ' + pmType.display);
                    return {
                        ...pm,
                        key: pm.id || pm.key,
                        label: this.formatPaymentMethod(pm, createLabel),
                        value: this.formatPaymentMethod(pm, createLabel)
                    };
                })
            });
        });

        this.getLookupOptions({
            name: 'paymentMethodId',
            values: dropdownGroups,
            target: 'paymentMethodOption'
        });
        //this.paymentMethodOption.option.values = dropdownGroups;
        this.fg.controls['paymentMethodId'].setValue(dropdownGroups[0].items[0].key);
    }

    /**
     * ACH
     * [FriendlyName] ([accountType]: [accountLastFour])
     * Debit
     * [FriendlyName] ([CardBrand]: [cardLastFour])
     * @param pm
     * @param createLabel
     */
    formatPaymentMethod(pm: IPaymentMethod, createLabel: string) {
        let pmDetails = '';
        if (pm.paymentMethodType.processType === PAYMENT_METHOD_TYPE.ACH_NAME) {
            pmDetails = ` (${pm.paymentDetails.accountType}: ${UtilService.maskText(pm.paymentDetails.accountLastFour, '-4')})`;
        } else if (pm.paymentMethodType.processType === PAYMENT_METHOD_TYPE.DEBIT_CARD_NAME) {
            pmDetails = ` (${pm.paymentDetails.cardBrand}: ${UtilService.maskText(pm.paymentDetails.cardLastFour, '-4')})`;
        }
        return !pm.id ? createLabel : (pm.name || UtilService.maskText(pm.accountNumber, '-4')) + pmDetails
    }

    getLookupOptions(obj: { name: string, values: any, target: any, filterBy?: string, return?: string }) {
        this[obj.target] = {
            ...this.page.formFields[obj.name],
            option: {
                allowCustom: false,
                minSearchLength: 0,
                filterBy: obj.filterBy || "value",
                return: obj.return || "key",
                isFilterable: false,
                values: obj.values
            }
        };
    }

    /**
     * @param action
     * @param actions
     */
    onSubmit() {
        this.loading = true;
        if (this.fg.invalid) {
            this.loading = false;
            this.fg.markAllAsTouched();
            this.fg.updateValueAndValidity();
            return false;
        }

        const paymentMethod = this.fg.controls['paymentMethodId'].value;
        if (Number.isInteger(paymentMethod)) {
            this.schedulePayment();
            return false;
        }

        const input = {
            loanId: this.loanId,
            paymentTypeId: paymentMethod.paymentTypeId,
            paymentAmount: this.fg.controls['amount'].value,
            paymentProcessType: new EnumType(paymentMethod.processType)
        };

        this.requestPayment(input);

        return true;
    }

    /**
     * Debit payments should have an authorizationProvided flag as false, else true
     * checkout form / thirdparty are considered Debit
     */
    schedulePayment() {
        const input: any = {
            loanId: this.loanId,
            amount: this.fg.controls['amount'].value || 0,
            paymentTypeId: this.page.formFields['create'].extendedParams['paymentTypeId'],
            processDate: this.fg.value['date'],
            paymentMethodId: this.fg.value['paymentMethodId'],
            authorizationProvided: this.isAch ? true : false
        };

        this.qs.doMutation({
            endPoint: IEndPoint.LMS_ENDPOINT,
            payload: this.qs.getMutationBody(
                'schedulePayment',
                this.page.extendedParams.queryConfig.mutation['schedulePayment'],
                {input}
            )
        }).subscribe(res => {
            res.processDate = input['processDate'];
            this.onPaymentSuccess(true);
        }, error => {
            this.loading = false;
        });
    }

    onPaymentSuccess(isScheduled?: boolean) {
        this.loading = false;
        this.processDate = isScheduled ? null : new Date().toISOString();
        this.paymentSuccess = true;
    }

    requestPayment(input) {
        const mutationName = 'createPaymentCheckoutForm';
        this.qs.doMutation({
            endPoint: IEndPoint.LMS_ENDPOINT,
            payload: this.qs.getMutationBody(mutationName, this.page.extendedParams.queryConfig.mutation[mutationName], {input})
        }).subscribe(res => {
            const url = res.data.createPaymentCheckoutForm.url;
            const config = {
                styleClass: 'iframe-modal',
                url,
                actions: {
                    primary: this.onPaymentResponse.bind(this),
                    close: this.closePaymentForm.bind(this)
                }
            };
            this.ms.show(MODAL_WINDOWS.CHECKOUT_FORM, config);
        }, error => {
            this.loading = false;
        });

    }

    closePaymentForm() {
        this.loading = false;
        this.ms.close();
    }

    onPaymentResponse(message) {
        this.loading = false;
        switch (message) {
            case 'PAYMENT_SUCCESS':
                this.onPaymentSuccess();
                break;
        }
    }

    goToAccountOverview() {
        this.router.navigate([APP_PAGE_ROUTES.ACCOUNT_OVERVIEW]);
    }

    getEffectiveDate() {
        const paymentMethod = this.fg.controls['paymentMethodId'].value;
        if (!(this.fg.controls['date'].value && Number.isInteger(paymentMethod))) {
            return false;
        }
        const input = {
            processDate: this.fg.controls['date'].value,
            paymentMethodId: this.fg.controls['paymentMethodId'].value,
            transactionType: new EnumType('PAYMENT')
        };
        this.qs.getQuery({
            endPoint: IEndPoint.LMS_ENDPOINT,
            query: {
                configuration: {
                    effectiveDate: {
                        effectiveDate: true,
                        __args: {input}
                    }
                }
            }
        }).subscribe(res => {
            const date = res.data.configuration.effectiveDate.effectiveDate;
            this.effectiveDate = UtilService.displayDate(date, this.page.formFields['date'].extendedParams['displayFormat'], this.appConfig.companyOffset);
        });
        return true;
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        this.page = null;
        this.loading = false;
        this.loans = null;
        this.data = null;
    }
}
