import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UnsubscribeOnDestroyAdapter} from '@lib/adapters/unsub-on-destroy.adapter';
import {ICmsPage, IFormField} from '@lib/cms/models/cms.model';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
  @Input() item: IFormField;
  @Input() cmsPage: ICmsPage;
  @Input() data: any;
  @Input() pageGetSchema: any;
  @Input() formSubmitSchema: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  trackByFn(index: number, el: any): number {
    return el?.id;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
