import {DatePipe} from '@angular/common';
import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    destroyPlatform
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TableLazyLoadEvent} from 'primeng/table';
import {EnumType} from 'json-to-graphql-query';
import {ICannedFilter, ICmsPage} from '../../cms/models/cms.model';
import {IApplicationQuery, QueryApiService} from '../../services/query-api.service';
import {UtilService} from '../../services/util.service';
import { FilterMetadata } from 'primeng/api';

interface IDataTable {
    totalCount: number;
    data: any;
    cols: Array<any>;
    loading: boolean;
    filter: string;
    dataNodeName: string;
    skip?: number;
    take?: number;
    where?: any
}

interface IDataTableFilter {
    matchMode: string;
    operator: string;
    value: string;
}

interface IDataTableEvent {
    filters?: {
        [key: string]: Array<IDataTableFilter>
    };
    first?: number;
    globalFilter?: any;
    multiSortMeta?: any;
    rows?: number;
    sortField?: string
    sortOrder?: number
}

const FILTER_TYPE = {
    equals: 'eq',
    notEquals: 'neq',
    dateIs: 'eq',
    dateIsNot: 'neq',
    dateBefore: 'lt',
    dateAfter: 'gt',
    contains: 'contains',
    notContains: 'ncontains'
};

@Component({
    selector: 'lib-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges, AfterContentChecked {
    @Input() cms: ICmsPage;
    @Input() endPoint: string;
    @Input() timezone: {
        companyOffset?: string,
        companyTimezone?: string
    };
    @Input() refresh = false;
    @Input() readOnly = false;
    @Output() onClick: EventEmitter<any> = new EventEmitter();
    @Output() onSelect: EventEmitter<any> = new EventEmitter();
    @Output() onLoad: EventEmitter<any> = new EventEmitter();
    @ViewChild('datatable') dataTable: any;
    dt: IDataTable = {} as IDataTable;
    defaultFilter: any;
    rootName = '';
    prevGlobalFilter: string | string[] = '';
    selectedItems: any;
    cannedFilters: ICannedFilter[];
    cannedFilterQuery: {
        where?: any,
        order?: any
    };
    event: any;

    fg: UntypedFormGroup = new UntypedFormGroup({
        searchTerm: new UntypedFormControl('')
    });

    COL_TYPE = {
        'string': 'text',
        'number': 'numeric',
        'datetime': 'date',
        'currency': 'numeric',
        'percentage': 'numeric'
    };

    constructor(private qs: QueryApiService,
                private datepipe: DatePipe,
                private cdref: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['cms']?.previousValue !== changes['cms']?.currentValue || changes['refresh']?.currentValue) {
            this.readOnly = this.readOnly || !!(this.cms?.queryConfig?.config?.readOnly);
            this.initTable();
        }
    }

    initTable() {
        // Initiate canned filters
        this.cannedFilters = Object.assign([], this.cms?.queryConfig?.cannedFilters?.options);
        if (this.cannedFilters && this.cannedFilters.length) {
            this.onCannedFilters(false, null);
        } else {
            this.getData();
        }
        if (this.dataTable) {
            this.dataTable.reset();
        }
        
    }

    getData($event?: TableLazyLoadEvent) {
        if (this.readOnly && $event?.sortField) {
            return false;
        }
        const sortField = typeof $event?.sortField === 'string' ? [$event.sortField] : $event?.sortField ?? [];
        const globalFilter = typeof $event?.globalFilter === 'string' ? [$event.globalFilter] : $event?.globalFilter ?? [];

        if (!this.cms?.formFields || sortField.some((item) => this.cms?.formFields[item]?.extendedParams?.disableSorting)) {
            return false;
        }

        // Keep previous $event
        if (!$event && this.event) {
            $event = this.event;
        } else if (!$event) {
            this.dt = {} as IDataTable;
            return {};
        }

        this.dt.loading = true;
        const query = this.getQueryBody($event);
        if (!Object.keys(query).length) {
            return false;
        }

        // Set filters in session
        this.event = $event;
        // this.setFiltersInSession(query);
        return this.qs.getQuery({endPoint: this.endPoint, query}).subscribe((res) => {
            const dataNode = this.cms.queryConfig?.dataNodeName;
            const dataParentNode = this.cms.queryConfig.dataParentNodeName;
            let dtResponse: any;
            if (dataParentNode) {
                dtResponse = this.cms.appId ? res.data[this.rootName][dataParentNode][dataNode] : res.data[this.rootName];
            } else {
                dtResponse = this.cms.appId ? res.data[this.rootName][dataNode] : res.data[this.rootName];
            }

            if (this.cms.queryConfig.config.rowExpandDataKey === 'rowIndex') {
                dtResponse?.items.map((r, i) => r.rowIndex = i);
            }

            if (dtResponse?.items) {
                if (this.onLoad.observed) {
                    this.onLoad.emit({response: dtResponse, callback: this.buildDataTable.bind(this)});
                } else if (!this.onLoad.observed) {
                    this.buildDataTable(dtResponse);
                }
            }
            this.dt.loading = res.loading;
        });
    }

    setFiltersInSession(query) {
        const node = this.cms.queryConfig?.dataNodeName;
        sessionStorage.setItem(node, JSON.stringify(query[node]['__args']));
        
    }

    /**
     * 1. Direct datatable - appInfo (${filters}){items, totalCount}
     * appInfo - Applications table
     *
     * 2. Data table with appId - application(${id}){ viewer { notes (${filters}){items, totalCount} } }
     * viewer (parent), notes(dataNodeName)
     * @param $event
     */
    getQueryBody($event?: TableLazyLoadEvent): {[key: string]: IApplicationQuery} {
        const query = {};
        const appQuery = {};
        let dtQuery: IApplicationQuery;
        const cmsDataNode = this.cms.queryConfig?.dataNodeName;

        if (this.cms.appId && this.cms.queryConfig && this.cms.queryConfig.query) {
            const parentNode = this.cms.queryConfig?.dataParentNodeName;
            this.rootName = Object.keys(this.cms.queryConfig.query)[0];
            appQuery[this.rootName] = {
                __args: {
                    id: this.cms.appId
                }
            };

            // application (id) :: root or loan(id) or borrower(id)
            dtQuery = this.cms.queryConfig?.query[this.rootName];
            if (parentNode) {
                // application (id) / viewer :: root / parent
                appQuery[this.rootName][parentNode] = {};
                // application (id) / viewer / notes :: root / parent / notes (skip, take) {items, totalCount}
                appQuery[this.rootName][parentNode][cmsDataNode] = {};
                appQuery[this.rootName][parentNode][cmsDataNode] = this.prepareDTQuery(query, dtQuery[parentNode][cmsDataNode], $event);
            } else {
                // loan(id) / payments (skip, take) {items, totalCount}
                appQuery[this.rootName][cmsDataNode] = {};
                appQuery[this.rootName][cmsDataNode] = this.prepareDTQuery(query, dtQuery[cmsDataNode], $event);
            }
            return appQuery;
        } else {
            this.rootName = cmsDataNode;
            if (this.cms.queryConfig && this.cms.queryConfig.query) {
                dtQuery = this.cms.queryConfig.query[cmsDataNode];
                query[this.cms.queryConfig.dataNodeName] = this.prepareDTQuery(query, dtQuery, $event);
            }
            return query;
        }
    }

    /**
     * Table totalCount, filters
     * One level nested query is supported - Eg: Loans { Borrowers }
     */
    prepareDTQuery(query, dtQuery, $event) {
        if (dtQuery.items !== "{items}") {
            return dtQuery;
        }
        let items = {};
        const tArgs = Object.assign({}, dtQuery?.__args || {});

        Object.keys(this.cms.formFields).filter(k => !(this.cms.formFields[k]?.extendedParams?.dataTable?.exclude ?? false)).map((key) => {
            const select = this.cms.formFields[key]?.extendedParams?.dataTable?.select;
            if (select) {
                items = {
                    ...items, 
                    ...select
                };
            }
            else {
                const nested = key.split('.');
                if (nested.length === 2) {
                    items[nested[0]] = items[nested[0]] || {};
                    items[nested[0]][nested[1]] = !this.cms.formFields[key].hide;
                    this.getInterfaceQuery(items, key, nested);
                } else {
                    items[key] = !this.cms.formFields[key].hide;
                }
            }
        });

        return {
            items,
            totalCount: !!dtQuery?.totalCount || true,
            __args: this.getArguments($event, tArgs)
        };
    }

    getInterfaceQuery(items, key, nested) {
        if (this.cms.formFields[key].extendedParams?.query) {
            items[nested[0]] = this.cms.formFields[key].extendedParams?.query;
        } else {
            return null;
        }
    }

    /**
     * Have filter map and sort order
     * tArgs.filter is for appPortal graphQl (__args: filter)
     * @param $event
     * @param targs
     */
    getArguments($event: TableLazyLoadEvent, tArgs) {
        if ($event.first === 0 && this.dataTable) {
            this.dataTable.first = 0;
        }

        if ($event?.globalFilter || this.prevGlobalFilter) {
            return {
                skip: $event.first || 0,
                take: $event.rows || this.cms.queryConfig.config.rowsPerPageOptions[0],
                searchTerm: $event.globalFilter || ''
            };
        } else if ($event && tArgs.where) {
            this.defaultFilter = {where: tArgs.where, order: tArgs.order};
            const where = this.getWhereFiltersData($event) || {};
            return {
                skip: $event.first || 0,
                take: $event.rows || this.cms.queryConfig.config.rowsPerPageOptions[0],
                where: (Object.keys(where).length ? where : tArgs.where) || {},
                order: this.getSortData($event, false, tArgs) || {}
            };
        } else if ($event) {
            return {
                skip: $event.first || 0,
                take: $event.rows || this.cms.queryConfig.config.rowsPerPageOptions[0]
            };
        } else {
            const args = Object.assign({}, tArgs);
            if (args.order) {
                args.order = this.defaultSort(args?.order);
            }
            tArgs = args;
            return tArgs;
        }
    }

    getPath(item:any, jpath: string) {
        const regex = /(([\w]+)|(\[.*\])|(\.{1}[\w]+))/g; //chunks it up
        let current = item;
        for (const match of jpath.matchAll(regex))
        {
            if (!current) return current;
            const m = match[0];
            if (m.startsWith('.')) {
                const s = m.substring(1);
                current = current[s];
            }
            else if (m.startsWith('[')) {
                const s = m.substring(1, m.length-1);
                const split = s.split('=');
                let value = "";
                split[0] = split[0].trim();
                split[1] = split[1].trim();
                if (split[1].startsWith("'") || split[1].startsWith('"')) value = split[1].substring(1, split[1].length-1);
                else value = split[1];
                const filterField = split[0].substring(1);
                current = current.filter(i => i[filterField]==value)[0];
            }   
        }
        return current;
        //breakdown.transfers[@id='OutstandingInterest'].amount;
        
    }

    map(items: any[]) {
        Object.keys(this.cms.formFields).map(key=> {
            const field = this.cms.formFields[key];
            const jpath = field?.extendedParams?.dataTable?.map;
            if (!jpath) return;

            items.map(item =>
                {
                    const split = field.name.split('.');
                    let dest = item;
                    let x = 0;
                    while (x < split.length-1) {
                        if (dest[split[x]] === 'undefined') dest[split[x]] = {};
                        dest = dest[split[x]];
                        x++;
                        if (dest === 'undefined') break;
                    }
                    dest[split[x]] = this.getPath(item, jpath);
                }
            )

        })
    }

    /**
     * data[0] is a first record to iterate rowData model to get all keys
     *
     * @param tData
     */
    buildDataTable(tData: {items: any, totalCount: number}) {
        this.map(tData.items);
        this.dt.data = tData.items;
        this.dt.totalCount = tData.totalCount;
        const cmsFields = this.cms.formFields;
        if (tData.totalCount && this.dt.data[0]) {
            const nestedCols = [];
            this.dt.cols = Object.keys(this.dt.data[0]).map((itemName) => {
                //Not nested object check
                
                if ([null, undefined].includes(this.dt.data[0][itemName]) || (typeof this.dt.data[0][itemName] !== 'object')) {
                    return cmsFields[itemName] && {
                        field: itemName,
                        ...cmsFields[itemName],
                        header: cmsFields[itemName].label
                    };
                } else {
                    let nested = {};
                    // Get a properly formed object to load column
                    if (Array.isArray(this.dt.data[0][itemName])) {
                        nested = this.dt.data[0][itemName][0] ? this.dt.data[0][itemName][0] : this.dt.data.filter(dtd => {
                            return dtd[itemName][0];
                        }).filter(Boolean)[0][itemName][0];
                    } else {
                        nested = this.dt.data[0][itemName] ? this.dt.data[0][itemName] : this.dt.data.filter(dtd => dtd[itemName]).filter(Boolean)[0][itemName];
                    }
                    Object.keys(nested || {}).map(nestedKey => {
                        const key = itemName + '.' + nestedKey;
                        const field = cmsFields[key] && cmsFields[key].label && {
                            field: key,
                            ...cmsFields[key],
                            header: cmsFields[key].label
                        };

                        if (field) {
                            nestedCols.push(field);
                        }
                    });
                }
            });
            this.dt.cols = [...this.dt.cols, ...nestedCols];
        } else {
            this.dt.cols = Object.keys(cmsFields).map((itemName) => {
                return cmsFields[itemName] && !cmsFields[itemName].hide && {
                    field: itemName,
                    ...cmsFields[itemName],
                    header: cmsFields[itemName].label
                };
            });
        }
        this.dt.cols = UtilService.deleteUndefinedFromList(this.dt.cols);
    }

    isFilterMetadata(value : any) : value is FilterMetadata {
        return (value as FilterMetadata) !== undefined ;
    }

    /**
     * LMS graphql - Datatable using Chilli cream integration
     * Straight field (Eg: where: {loanNumber: {contains: "1001"}})
     * nested object (Eg: where: { details: { daysPastDue: { gte: 100 } } })
     * (Eg: where : { borrowers: {some: { email: {eq: "prequal_test7@test.com"}}}} )
     * Array of data NOT SUPPORTED BY BE: Sorting doesn't work on collections (View has to be created by BE)
     * @param $event
     */
    getWhereFiltersData($event: TableLazyLoadEvent) {
        const tFilters = $event.filters;
        const filterKeys = tFilters && Object.keys(tFilters);
        const filterCols = filterKeys.filter(fKey => tFilters[fKey][0]['value']);
        if (filterCols?.length) {
            const filterString = Object.assign({}, this.defaultFilter);
            filterCols.forEach(field => {
                const tFilter = tFilters[field];
                var x : FilterMetadata;
                const uFilter = (Array.isArray(tFilter) ) ? tFilter : [tFilter];
                uFilter.forEach((filter: IDataTableFilter) => {
                    const fields = field.split('.');
                    filterString['where'] = Object.assign({}, filterString['where'] || {});
                    if (!fields[1]) {
                        // where: {effectiveDate: {eq: "05/10/2023"}}
                        filterString['where'][field] = filterString['where'][field] || {};
                        if (this.cms.formFields[field].type === 'datetime') {
                            const val = this.datepipe.transform(filter.value, this.cms.formFields[field].format);
                            filterString['where'][field][FILTER_TYPE[filter.matchMode] || filter.matchMode] = val;
                        } else {
                            filterString['where'][field][FILTER_TYPE[filter.matchMode] || filter.matchMode] = filter.value;
                        }
                    } else {
                        // where: {paymentMethodType: {some: {display: {eq: "05/10/2023"}}}
                        filterString['where'][fields[0]] = filterString['where'][fields[0]] || {};
                        filterString['where'][fields[0]][fields[1]] = {};
                        if (this.cms.formFields[field].type === 'datetime') {
                            const val = this.datepipe.transform(filter.value, this.cms.formFields[field].format);
                            filterString['where'][fields[0]][fields[1]][FILTER_TYPE[filter.matchMode] || filter.matchMode] = val;
                        } else {
                            filterString['where'][fields[0]][fields[1]][FILTER_TYPE[filter.matchMode] || filter.matchMode] = filter.value;
                        }
                    }
                });
                return true;
            });

            const cfqw = this.cannedFilterQuery && this.cannedFilterQuery['where'];
            if (cfqw && cfqw['or']) {
                //Multiple filters configured from CMS - use or
                filterString['where']['or'] = this.cannedFilterQuery['where']['or'];
            } else if (cfqw && Object.keys(cfqw).length) {
                // Single filter configured from CMS
                filterString['where'] = {...filterString['where'], ...cfqw};
            }
            return filterString['where'];
        } else {
            return this.cannedFilterQuery?.where;
        }
    }

    /**
     * Prepare sort order
     * sortOrder = 1 or -1 from primeNg
     * Supports nested object sort
     * eg: order: [{ details: { daysPastDue: ASC } }]
     *
     * Replace a previous sort filter from default cannedFilter
     * @param $event
     */
    getSortData($event: TableLazyLoadEvent, isOrderBy = false, args?: any) {
        if ($event.sortField) {
            enum SORT_TYPE {
                // @ts-ignore
                ASC = new EnumType('ASC'), DESC = new EnumType('DESC')
            };
            const sortField = (Array.isArray($event.sortField)) ? $event.sortField : [$event.sortField];
            let order;
            order = [];
            sortField.forEach((item, index) =>
            {
                const nestedParam = item.split('.');
                if (nestedParam[1]) {
                    order[index] = {};
                    order[index][nestedParam[0]] = {};
                    order[index][nestedParam[0]][nestedParam[1]] = $event.sortOrder === 1 ? SORT_TYPE.ASC : SORT_TYPE.DESC;
                } else {
                    order[index] = {};
                    order[index][item] = $event.sortOrder === 1 ? SORT_TYPE.ASC : SORT_TYPE.DESC;
                }
            });

            return order;
        } else if (args?.order) {
            return this.defaultSort(args.order);
        } else {
            return this.cannedFilterQuery?.order;
        }
    }

    onItemClick(e, item, ctaColName) {
        e.preventDefault();
        if (this.onClick) {
            this.onClick.emit({item, ctaColName});
        }
    }

    defaultSort(orderParams) {
        if (!orderParams) {
            return {};
        }
        const order = Object.assign({}, orderParams);
        Object.keys(order).map(o => {
            if (typeof order[o] === 'string') {
                order[o] = new EnumType(order[o].toUpperCase());
            }
        });

        return order;
    }

    /**
     * Selection for both checkboxes and radio buttons
     * @param e
     * @param single
     */
    onSelection(e?: any, single?: boolean) {
        if (this.onSelect) {
            this.onSelect.emit(single && this.selectedItems != null ? [this.selectedItems] : this.selectedItems);
        }
    }

    /**
     * Type: Single (treats like Radio button), multiple (treats like checkbox)
     * option is null on load - Load default filters
     * @param checked
     * @param option
     */
    onCannedFilters(checked, selectedOption: ICannedFilter, clear?: boolean): boolean {
        if (selectedOption) {
            selectedOption = Object.assign({}, selectedOption);
            selectedOption.checked = checked;
        }

        let index = -1;
        const filters = Object.assign([], this.cannedFilters);
        this.cannedFilters.map((filter, i) => {
            const o = Object.assign({}, filter);
            // Single type: reset previous selection on change
            if (clear || selectedOption && this.cms.queryConfig.cannedFilters.type !== 'multiple') {
                o.checked = false;
            }
            if (filter.label === selectedOption?.label) {
                index = i;
                o.checked = selectedOption.checked;
            }

            filters[i] = o;

            // On load if any checked is true, apply filter
            // On multiple selection, remove when unchecked
            if (o.checked || (filter.label === selectedOption?.label && selectedOption.checked)) {
                this.prepareCannedFilter(filters[i]);
            } else if (!o.checked && filter.label === selectedOption?.label) {
                filters[i]['where'] = {};
                this.cannedFilterQuery = {};
            }
        });
        if (selectedOption && !clear) {
            filters[index] = selectedOption;
        }
        this.cannedFilters = Object.assign([], filters);

        if (clear || !Object.prototype.hasOwnProperty.call(this.cannedFilterQuery, 'where')) {
            this.clearCannedFilterQuery();
        }

        if (this.event) {
            this.event.first = 0;
        }
        this.getData(this.event);
        return true;
    }

    prepareCannedFilter(filter) {
        this.cannedFilterQuery = {};
        this.cannedFilterQuery['where'] = filter.where;
        const order = Object.assign([], filter.order);
        order.forEach((o, i) => {
            const t = Object.assign({}, o);
            Object.keys(t).map(key => t[key] = new EnumType(t[key]));
            order[i] = t;
        });

        this.cannedFilterQuery['order'] = order || {};
    }

    clearCannedFilterQuery() {
        this.cannedFilterQuery = {};
    }

    clearGlobalSearch() {
        this.event = null;
        this.defaultFilter = null;
        this.prevGlobalFilter = null;
        this.fg.controls['searchTerm'].setValue('');
        this.fg.controls['searchTerm'].markAsTouched();
        this.fg.controls['searchTerm'].updateValueAndValidity();
    }

    clear(table) {
        this.clearGlobalSearch();
        this.clearCannedFilterQuery();
        this.onCannedFilters(false, null, true);
        table.clear();
    }
}
