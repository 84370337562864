import {Injectable} from '@angular/core';
import {AuthProviderClientIdService} from '@lib/services/auth-provider-client-id.service';
import {IUrlContext} from '../models/lib.model';

const STORAGE = {
    token: 'ui-token',
    idToken: 'id-token',
    refreshToken: 'refresh-token',
    launchParams: 'launchParams'
};

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(private apcs: AuthProviderClientIdService) {
    }

    get clientId() {
        return this.apcs.getClientId();
    }

    get token() {
        return this.getItem(STORAGE.token);
    }

    set token(token: string) {
        this.setItem(STORAGE.token, token && (token !== 'null') ? token : '');
    }

    get idToken() {
        return this.getItem(STORAGE.idToken);
    }

    set idToken(idToken: string) {
        this.setItem(STORAGE.idToken, idToken && (idToken !== 'null') ? idToken : '');
    }

    get refreshToken() {
        return this.getItem(STORAGE.refreshToken);
    }

    set refreshToken(refreshToken: string) {
        this.setItem(STORAGE.refreshToken, refreshToken && (refreshToken !== 'null') ? refreshToken : '');
    }

    get launchParams(): IUrlContext {
        return JSON.parse(sessionStorage.getItem(STORAGE.launchParams));
    }

    clearTokens() {
        this.removeItem('launchParams');
        this.removeItem(STORAGE.token);
        this.removeItem(STORAGE.idToken);
        this.removeItem(STORAGE.refreshToken);
    }

    getItem(key) {
        return sessionStorage.getItem(this.clientId + '-' + key);
    }

    removeItem(key) {
        return sessionStorage.removeItem(this.clientId + '-' + key);
    }

    setItem(key, value) {
        sessionStorage.setItem(this.clientId + '-' + key, value);
    }
}
