import {createAction, props} from '@ngrx/store';

export enum CMS_ACTIONS {
    LOAD_GLOBAL_CONTENT = '[CMS Action] LOAD global content',
    LOAD_GLOBAL_CONTENT_SUCCESS = '[CMS Action] LOAD global content success',
    LOAD_CMS_PAGE_CONTENT = '[CMS Action] LOAD CMS Page Content',
    LOAD_CMS_PAGE_CONTENT_SUCCESS = '[CMS Action] LOAD CMS Page Content success',
    CLEAR_PAGE_CONTENT = '[shared state] clear page content'
}

export const loadGlobalContent = createAction(CMS_ACTIONS.LOAD_GLOBAL_CONTENT);

export const loadGlobalContentSuccess = createAction(
    CMS_ACTIONS.LOAD_GLOBAL_CONTENT_SUCCESS,
    props<any>()
);

export const loadCmsPageContent = createAction(
    CMS_ACTIONS.LOAD_CMS_PAGE_CONTENT,
    props<{pathName: string, nestedParams?: string[]}>()
);

export const loadCmsPageContentSuccess = createAction(
    CMS_ACTIONS.LOAD_CMS_PAGE_CONTENT_SUCCESS,
    props<any>()
);

export const clearPageContent = createAction(
    CMS_ACTIONS.CLEAR_PAGE_CONTENT
);
