import {Injectable} from '@angular/core';
import {ApolloClientOptions, from, InMemoryCache} from '@apollo/client/core';
import {onError} from '@apollo/client/link/error';
import {HttpLink} from 'apollo-angular/http';
import {ConfigService} from './config.service';

const errorLink: any = onError(({graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({message, locations, path}) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Operation: ${operation}, Forward: ${forward}`);
        });
    }
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }

    // return forward(operation);
});

@Injectable({
    providedIn: 'root'
})
export class QueryClientService {

    constructor(private config: ConfigService) {
    }

    configureNamedClient(httpLink: HttpLink): Record<string, ApolloClientOptions<any>> {
        const lmsUri = `${this.config.get('lmsGraphQlUrl')}`;
        const amsUri = `${this.config.get('amsGraphQlUrl')}`;
        const apiUri = `${this.config.get('apiUrl')}`;
        return {
            'lms': {
                link: from([errorLink, httpLink.create({uri: lmsUri})]),
                cache: new InMemoryCache({addTypename: false})
            },
            'ams': {
                link: from([errorLink, httpLink.create({uri: amsUri})]),
                cache: new InMemoryCache({addTypename: false})
            },
            'api': {
                link: from([errorLink, httpLink.create({uri: apiUri})]),
                cache: new InMemoryCache({addTypename: false})
            }
        };
    }

    /**
     * AMS query can be called by name or default
     * @param httpLink
     */
    configureDefaultClient(httpLink: HttpLink): ApolloClientOptions<any> {
        const uri = `${this.config.get('graphQlUrl')}`;
        return {
            link: from([errorLink, httpLink.create({uri})]),
            cache: new InMemoryCache({addTypename: false})
        };
    }
}
