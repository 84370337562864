import {Component, OnInit} from '@angular/core';
import {MODAL_WINDOWS} from '@app/core/models/core.model';
import {ModalService} from '@lib/services/modal.service';
import {UtilService} from '@lib/services/util.service';
import {BaseFormComponent} from '../base-component/base-form.component';

@Component({
    selector: 'lib-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends BaseFormComponent implements OnInit {
    constructor(private modal: ModalService) {
        super();
    }

    ngOnInit(): void {
    }

    onChange(e) {
        const name = UtilService.transformFirstLetterUpperCase(this.item.name);
        const ep = this.item.extendedParams;
        if (!this.group.controls[this.item.name].value && ep && ep[`on${name}Uncheck`]) {
            this.onSecondary(true);
            const config = {
                actions: {
                    primary: this.onPrimary.bind(this),
                    secondary: this.onSecondary.bind(this, true)
                }
            };
            this.modal.show(MODAL_WINDOWS.CONFIRM_AUTOPAY_DISABLE, config);
        }
    }

    onPrimary() {
        this.modal.close();
        this.onSecondary(false);
    }

    onSecondary(value) {
        this.group.controls[this.item.name].setValue(value);
        this.group.controls[this.item.name].updateValueAndValidity();
    }
}
