import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {AuthenticateComponent} from '@app/core/components/authenticate/authenticate.component';
import {DocumentsViewerComponent} from '@app/core/components/documents-viewer/documents-viewer.component';
import {LogoutComponent} from '@app/core/components/logout/logout.component';
import {EditAutopayModalComponent} from '@app/core/components/modals/edit-autopay-modal/edit-autopay-modal.component';
import {SignComponent} from '@app/core/components/sign/sign.component';
import {BaseComponent} from '@lib/components/base/base.component';
import {FormComponent} from '@lib/components/form/form/form.component';
import {SectionHeadingComponent} from '@lib/components/form/section-heading/section-heading.component';
import {LibModule} from '@lib/lib.module';
import {BasePageComponent} from '@lib/components/base-page/base-page.component';
import {PageActionsComponent} from '@lib/flow/page-actions/page-actions.component';
import {PageBaseActionComponent} from '@lib/flow/page-base-action/page-base-action.component';
import {PageComponent} from '@lib/flow/page/page.component';
import {DocumentsListComponent} from './components/documents-list/documents-list.component';
import {FundComponent} from './components/fund/fund.component';
import {LoginComponent} from './components/login/login.component';
import {ConfirmModalComponent} from './components/modals/confirm-modal/confirm-modal.component';
import {IframeModalComponent} from './components/modals/iframe-modal/iframe-modal.component';
import {OfferDetailComponent} from './components/offers/offer-detail/offer-detail.component';
import {OfferPaymentTypesComponent} from './components/offers/offer-payment-types/offer-payment-types.component';
import {OfferSliderComponent} from './components/offers/offer-slider/offer-slider.component';
import {OffersComponent} from './components/offers/offers.component';
import {PayrollComponent} from './components/payroll/payroll.component';
import {ReviewComponent} from './components/review/review.component';
import {UploadDocumentsComponent} from './components/upload-documents/upload-documents.component';
import {AccountBasePageComponent} from './pages/account-base-page/account-base-page.component';
import {AccountDocumentsComponent} from './pages/account-documents/account-documents.component';
import {AccountOverviewComponent} from './pages/account-overview/account-overview.component';
import {ErrorPageComponent} from './pages/auxilliary/error-page/error-page.component';
import {ForbiddenComponent} from './pages/auxilliary/forbidden/forbidden.component';
import {LayoutComponent} from './pages/layout/layout.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {OneTimePaymentsComponent} from './pages/one-time-payments/one-time-payments.component';
import {PaymentHistoryComponent} from './pages/payment-history/payment-history.component';
import {PaymentMethodsComponent} from './pages/payment-methods/payment-methods.component';
import {PaymentComponent} from './pages/payment/payment.component';
import { ContentComponent } from './components/content/content.component';
import { CollapsibleSectionComponent } from './components/collapsible-section/collapsible-section.component';
import { AccountOverviewEmptyComponent } from './pages/account-overview-empty/account-overview-empty.component';
import {AccountDetailsComponent} from './pages/account-details/account-details.component';

const COMPONENTS = [
    PageComponent,
    ErrorPageComponent,
    BaseComponent,
    BasePageComponent,
    LayoutComponent,
    FormComponent,
    ForbiddenComponent,
    SectionHeadingComponent,
    OffersComponent,
    OfferPaymentTypesComponent,
    OfferDetailComponent,
    OfferSliderComponent,
    DocumentsViewerComponent,
    SignComponent,
    ReviewComponent,
    ContentComponent,
    PayrollComponent,
    AuthenticateComponent,
    LogoutComponent,
    PageActionsComponent,
    PageBaseActionComponent,
    ConfirmModalComponent,
    UploadDocumentsComponent,
    AccountOverviewComponent,
    AccountOverviewEmptyComponent,
    FundComponent,
    CollapsibleSectionComponent,
    PaymentHistoryComponent,
    PaymentMethodsComponent,
    NotFoundComponent,
    LoginComponent,
    IframeModalComponent,
    EditAutopayModalComponent,
    PaymentComponent,
    AccountBasePageComponent,
    OneTimePaymentsComponent,
    AccountDocumentsComponent,
    AccountDetailsComponent,
    DocumentsListComponent
];

@NgModule({
    declarations: [
        ...COMPONENTS
    ],
    exports: [
        ...COMPONENTS
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LibModule
    ]
})
export class CoreModule {
}
