import {IFormField} from '../cms/models/cms.model';
import {Message} from 'primeng/api';

export const FILE_MIME_TYPES = {
    JSON: 'application/json',
    PDF: 'application/pdf',
    STREAM: 'application/octet-stream'
};

export const TAB_STATUS = {
    WAITING_FOR_DOCS: {
        icon: 'pi pi-clock',
        value: 'WAITING_FOR_DOCS'
    },
    PASS: {
        icon: 'pi pi-check-circle',
        value: 'PASS'
    },
    FAIL: {
        icon: 'pi pi-times-circle',
        value: 'FAIL'
    },
    INCOMPLETE: {
        icon: 'pi pi-exclamation-circle',
        value: 'INCOMPLETE'
    }
};

export const ALERT_TYPES = {
    ERROR: 'error',
    WARN: 'warn',
    INFO: 'info',
    SUCCESS: 'success',
    CUSTOM: 'custom'
};

export const HTTP_METHODS = {
    GET: 'get',
    PUT: 'put',
    PATCH: 'patch',
    POST: 'post',
    DELETE: 'delete'
};

export interface IMenuItem {
    type: 'link' | 'button' | 'tab';
    url?: string;
    title?: string;
    id?: string;
    name?: string;
    showAlways?: boolean;
    authenticated?: boolean;
    onClick?: string;
    icon?: string;
    enabled?: boolean;
    hideMenuUrls?: string[];
    position?: string;
    label?: string;
    actualStatus?: string;
    indicator?: string;
    category?: string;
    order?: number;
    groupBy?: string;
}

export interface IDialog {
    name?: string;
    titleIcon?: string;
    body?: string;
    config?: any;
    klass?: string;
    formFields?: IFormField[];
    extendedParams?: string;
}

export const MESSAGES = {
    PAGE_UPDATE: 'page:update',
    PAGE_SUBMIT: 'page:submit',
    TILE_SUBMIT: 'tile:submit',
    REFRESH_GROUPS: 'refresh:groups',
    SUBFORM_TOUCHED: 'subform:touched',
    LAUNCH_PAYROLL: 'payroll:launch',
    REDIRECT: 'page:redirect'
};

export interface IUrlContext {
    id: number | string;
    name: string;
    itemId?: string;
}

export interface IAlert extends Message {
    errorCode?: number | string;
}

export interface IComponentTypes {
}
