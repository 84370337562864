import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {AppState} from '@app/app.state';
import {APP_PAGE_ROUTES, IPaymentMethod, MODAL_WINDOWS, PAYMENT_METHOD_TYPE} from '@app/core/models/core.model';
import {AccountBasePageComponent} from '../account-base-page/account-base-page.component';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {ModalService} from '@lib/services/modal.service';
import {IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {StorageService} from '@lib/services/storage.service';
import {UtilService} from '@lib/services/util.service';
import {Store} from '@ngrx/store';
import {EnumType} from 'json-to-graphql-query';

@Component({
    selector: 'app-account-details',
    templateUrl: './account-details.component.html',
    styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent extends AccountBasePageComponent implements OnInit, OnDestroy {
    principalBalance: string;
    currentBalance: string;
    collateralBalance: string;
    creditLimit: string;
    recurringFeeAmount: number | null;
    loanType: string;
    availableCredit: number | null;
    availableToWithdraw: number | null;
    noOfPayments: number;
    nextPayment: number;
    nextPaymentDate: number;
    iEndPoint = IEndPoint;
    paymentMethods: IPaymentMethod[];
    fg: UntypedFormGroup = new UntypedFormGroup({
        autopay: new UntypedFormControl(false)
    });
    autopayLoading = false;
    autopay: any;

    constructor(
        protected store$: Store<AppState>,
        protected storage: StorageService,
        protected authProvider: AuthProviderService,
        protected qs: QueryApiService,
        protected router: Router,
        private ms: ModalService
    ) {
        super(store$, storage, authProvider, qs, router);
    }

    ngOnInit(): void {
        super.getLoanData(APP_PAGE_ROUTES.ACCOUNT_DETAILS, this.getAccountSummary.bind(this));
    }

    /**
     * Outer query is to get LoanId by email
     * Inner query is to get Loan summary by LoanId
     */
    getAccountSummary() {
        if (!this.page) {
            return false;
        }
        const qc = this.page.extendedParams.queryConfig;

        const ls = qc['getLoanDetails'] && qc['getLoanDetails']['loan'];
        if (ls) {
            const query = {
                ...ls,
                '__args': ls['__args'],
                appliedTransactions: {
                    items: ls['appliedTransactions']['items'],
                    '__args': {
                        skip: 0,
                        take: 3,
                        where: {type: {eq: 'Payment'}},
                        order: {effectiveDate: new EnumType('DESC')}
                    }
                }
            };
            this.qs.getQuery({
                endPoint: IEndPoint.LMS_ENDPOINT,
                query: this.qs.getQueryBody('loan', query, {id: this.loanId})
            }).subscribe(res => {
                this.data = res.data.loan || {};
                this.data.loanId = this.loanId;
                this.paymentMethods = this.data.paymentMethods?.items || [];
                this.autopay = this.data.autopay?.paymentSchedule || {};
                this.fg.controls['autopay'].setValue(!!this.autopay.isActive);
                this.populateDataPoints();
            });
        }

        const qt = this.page.extendedParams.queryConfig['queryTransactionsConfig'];
        this.page = Object.assign({}, {
            ...this.page,
            appId: this.loanId,
            queryConfig: {
                ...qt,
                query: {
                    loan: {
                        '__args': {
                            id: true
                        },
                        appliedTransactions: {
                            totalCount: true,
                            items: qt.query?.loan?.appliedTransactions?.items || "{items}",
                            '__args': {
                                skip: 0,
                                take: qt.config.rowsPerPageOptions[0],
                                where: {type: {eq: 'Payment'}},
                                order: {effectiveDate: new EnumType('DESC')}
                            }
                        }
                    }
                }
            }
        });

        return true;
    }

    productHasField(field: string) : boolean {
        const productList = {
            "LineOfCredit": ["creditLimit", "availableCredit", "availableToWithdraw" ]
        };
        return productList[this.loanType]?.includes(field);
    }

    populateDataPoints() {
        const us = UtilService;
        const cd = this.data.loanTerms?.startDate;
        const format = this.page.formFields['dueDate'].format;
        const cdOffset = new Date(us.formatDate(us.displayDate(cd, format, this.appConfig?.companyOffset)));
        const currentDate = new Date();
        this.loanType = this.data.loanTerms?.product?.type;
        this.collateralBalance = this.data.state.details?.collateralBalance;
        this.creditLimit = this.data.loanTerms?.product?.creditLimit ?? 0;
        this.availableCredit = (this.data.loanTerms?.product?.creditLimit - this.data.state.details?.principal) ?? 0;
        this.availableToWithdraw = this.availableCredit < 500 ? 0 : this.availableCredit;
        this.recurringFeeAmount = this.data.loanTerms?.product?.amount;
        if (cdOffset < currentDate) {
            this.principalBalance = this.data.state.details?.principal;
            this.currentBalance = this.data.state.details?.balance;
            this.noOfPayments = this.data.state.details?.remainingPayments;
            this.nextPayment = this.data.state.details?.nextPaymentAmount;
            this.nextPaymentDate = this.data.state.details?.nextPaymentDate;
        } else {
            this.principalBalance = this.data.loanTerms?.product?.loanAmount;
            this.currentBalance = this.data.loanTerms?.product?.loanAmount;
            this.noOfPayments = this.data.loanTerms?.product?.plan.numberOfPayments;
            this.nextPayment = this.data.loanTerms?.calculatedSchedule?.scheduledPayments[0].paymentAmount;
            this.nextPaymentDate = this.data.loanTerms?.calculatedSchedule?.scheduledPayments[0].effectiveDate;
        }
    }

    makePayment() {
        this.router.navigate([APP_PAGE_ROUTES.PAYMENT]);
    }

    launchAutopayModal(e) {
        const autoPayControl = this.fg.controls['autopay'];
        if (this.page.formFields['autopay'].extendedParams['readonly']) {
            e.preventDefault();
            autoPayControl.setValue(autoPayControl.value);
            autoPayControl.updateValueAndValidity();
            return false;
        }

        this.autopayLoading = true;
        let type = MODAL_WINDOWS.ENABLE_AUTOPAY;
        let data = {paymentMethods: this.paymentMethods.filter(pm => pm.authorized && pm.paymentMethodTypeId === PAYMENT_METHOD_TYPE.ACH)} as any;
        if (!autoPayControl.value) {
            // Activate
            type = MODAL_WINDOWS.DISABLE_AUTOPAY;
            data = this.autopay.paymentMethod;
        }
        this.ms.show(
            type,
            {
                data,
                loanId: this.loanId,
                parentCms: this.page.formFields,
                actions: {primary: this.submitAutopayModal.bind(this), close: this.closeAutopayModal.bind(this)}
            });
        return true;
    }

    submitAutopayModal(res: {mutation: string, autopayEnabled: boolean, paymentMethod: any}) {
        this.closeAutopayModal();
        this.autopay = {
            isActive: res.autopayEnabled,
            paymentMethod: res.paymentMethod
        };
    }

    /**
     * Revert to original state when modal close is clicked
     * @param e
     */
    closeAutopayModal(e?: any) {
        if (e) {
            const ap = this.fg.controls['autopay'];
            ap.setValue(!ap.value);
        }
        this.autopayLoading = false;
        this.ms.close();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.page = null;
        this.loading = false;
        this.loanId = null;
        this.data = null;
    }
}
