import { Component, OnDestroy, OnInit } from '@angular/core';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import { ICmsPage } from '@lib/cms/models/cms.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { loadCmsPageContent, loadCmsPageContentSuccess } from '@lib/cms/store/cms/cms.actions';
import { getCmsPageContent } from '@lib/cms/store/cms/cms.selector';
import { Store } from '@ngrx/store';
import { UnsubscribeOnDestroyAdapter } from '@lib/adapters/unsub-on-destroy.adapter';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
  page$: Observable<ICmsPage>;

  constructor(private store$: Store, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.store$.dispatch(loadCmsPageContent({ pathName: APP_PAGE_ROUTES.PAGE_404 }));
    this.page$ = this.store$.select(getCmsPageContent);
  }

  onSubmit(cta: string) {
    this.router.navigate([cta || '/']);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.dispatch(loadCmsPageContentSuccess({}));
  }
}
