import {Component, OnInit} from '@angular/core';
import {BaseModalComponent} from '@lib/components/base-modal/base-modal.component';
import {IDialog} from '@lib/models/lib.model';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent extends BaseModalComponent implements OnInit {
    formFieldKeys: string[];
    cms: IDialog;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
        super(ref, config);
    }

    ngOnInit(): void {
        this.formFieldKeys = Object.keys(this.config.data?.cms.formFields);
    }

}
