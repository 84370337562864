<h1>{{page?.title}}</h1>
<lib-spinner *ngIf="!((page && data) || noLoansFound)"></lib-spinner>
<ng-container *ngIf="page && page.title">
    <div class="account-overview no-loans" *ngIf="noLoansFound && page">
        <markdown [data]="page.kicker" *ngIf="page.kicker"></markdown>
    </div>
    <div class="payment-methods" *ngIf="page && paymentMethods">
        <div class="summary-section section" *ngFor="let pm of paymentMethods">
            <ng-container *ngIf="!pm.editMode">
                <div class="label-value">
                    <div class="data-label">{{page.formFields['name'].label}}</div>
                    <div class="data-value">{{pm.name}}</div>
                </div>
                <div class="label-value">
                    <div class="data-label">{{page.formFields['type'].label}}</div>
                    <div class="data-value">{{pm.paymentMethodType.display}}</div>
                </div>
                <div class="label-value routing-number" *ngIf="pm.routingOrCard">
                    <div class="data-label">{{page.formFields['routingOrCard'].label}}</div>
                    <div class="data-value" [ngClass]="{'small': pm.routingOrCard?.length > 12}">
                        {{pm.routingOrCard}}
                    </div>
                </div>
                <div class="label-value" *ngIf="pm.accountNumber">
                    <div class="data-label">{{page.formFields['accountNumber'].label}}</div>
                    <div class="data-value">{{"****" + pm.accountNumber.slice(-4)}}</div>
                </div>
                <div class="label-value" *ngIf="!pm.id && pm.paymentMethodTypeId === 2">
                    <div class="pending-pm">
                        {{page.formFields['debitCard'].extendedParams['pending']}}
                    </div>
                </div>
                <div class="section-actions" *ngIf="pm.id">
                    <a class="section-actions-item edit" tabindex="0" (click)="editMode(pm)"
                       (keyup.enter)="editMode(pm)" *ngIf="!pm.editing">
                        <i class="pi pi-pencil"></i>
                    </a>
                    <lib-spinner *ngIf="pm.editing"></lib-spinner>
                    <a class="section-actions-item deauthorize" tabindex="0" (click)="deAuthorize(pm)"
                       (keyup.enter)="deAuthorize(pm)" *ngIf="!pm.removing">
                        <i class="pi pi-trash"></i>
                    </a>
                    <lib-spinner *ngIf="pm.removing"></lib-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="pm.editMode">
                <ng-container *ngTemplateOutlet="form;context:{pm}"></ng-container>
            </ng-container>
        </div>
        <lib-button
                [label]="page.formFields['addNewPayment'].label"
                class="flex-center"
                [klass]="page.formFields['addNewPayment'].klass"
                *ngIf="!showNewForm"
                (click)="toggleNewForm()"
                (keyup.enter)="toggleNewForm()"
        ></lib-button>
        <div class="summary-section section" *ngIf="showNewForm">
            <ng-container *ngTemplateOutlet="form"></ng-container>
        </div>
    </div>
</ng-container>
<ng-template #form let-pm=pm>
    <form (ngSubmit)="onSubmit(pm)" [formGroup]="fg" autocomplete="off" class="form-container">
        <lib-lookup class="form-field" [group]="fg" [item]="paymentMethodOption"
                    *ngIf="paymentMethodOption?.option?.values"></lib-lookup>
        <lib-input class="form-field" [group]="fg" [item]="page.formFields['name']"
                   *ngIf="pm?.editMode || getType(paymentMethodOptionValues[fg.controls['type'].value]) !== PM_SUPPORTED_TYPE.THIRD_PARTY "></lib-input>
        <ng-container
                *ngIf="pm?.editMode || getType(paymentMethodOptionValues[fg.controls['type'].value]) !== PM_SUPPORTED_TYPE.THIRD_PARTY">
            <lib-lookup class="form-field" [group]="fg" [item]="accountTypeOption"
                        *ngIf="accountTypeOption?.option?.values && getType(paymentMethodOptionValues[fg.controls['type'].value]) !== PM_SUPPORTED_TYPE.THIRD_PARTY">
            </lib-lookup>
            <lib-input class="form-field" [group]="fg" [item]="page.formFields['routingOrCard']"></lib-input>
            <lib-input class="form-field" [group]="fg" [item]="page.formFields['accountNumber']"></lib-input>
        </ng-container>
        <ng-container
                *ngIf="getType(paymentMethodOptionValues[fg.controls['type'].value]) === PM_SUPPORTED_TYPE.THIRD_PARTY">
            <markdown [data]="page.formFields['debitCard'].sectionInfo" class="text-center"></markdown>
        </ng-container>
        <div class="cta-container">
            <lib-button
                    [label]="page.formFields['cancel'].label"
                    class="action"
                    type="button"
                    [klass]="page.formFields['cancel'].klass"
                    (click)="toggleNewForm(pm)"
                    (keyup.enter)="toggleNewForm(pm)"
            ></lib-button>
            <lib-button
                    [label]="page.formFields['submit'].label"
                    class="action"
                    [klass]="page.formFields['submit'].klass"
                    [loading]="loading"
            ></lib-button>
        </div>
    </form>
</ng-template>
