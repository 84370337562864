import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from '../base-component/base-form.component';

@Component({
  selector: 'lib-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends BaseFormComponent implements OnInit {
  @Input() values: any[];
  @Input() hideError: boolean;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  setAction(e) {
    this.formControl.setValue(e.target.value);
    this.onChange.emit(e.target.value);
  }
}
