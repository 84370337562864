import { Component } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '../../adapters/unsub-on-destroy.adapter';

@Component({
  selector: 'lib-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent extends UnsubscribeOnDestroyAdapter {
  constructor() {
    super();
  }

  trackByFn(index: number, el: any): number {
    return el?.id;
  }
}
