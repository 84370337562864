import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppState} from '@app/app.state';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {AccountBasePageComponent} from '@app/core/pages/account-base-page/account-base-page.component';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {StorageService} from '@lib/services/storage.service';
import {Store} from '@ngrx/store';

@Component({
    selector: 'app-account-documents',
    templateUrl: './account-documents.component.html',
    styleUrls: ['./account-documents.component.scss']
})
export class AccountDocumentsComponent extends AccountBasePageComponent implements OnInit, OnDestroy {
    documents = {};

    constructor(
        protected store$: Store<AppState>,
        protected storage: StorageService,
        protected authProvider: AuthProviderService,
        protected qs: QueryApiService,
        protected router: Router
    ) {
        super(store$, storage, authProvider, qs, router);
    }

    ngOnInit(): void {
        super.getLoanData(APP_PAGE_ROUTES.ACCOUNT_DOCUMENTS, this.getDocuments.bind(this));
    }

    getDocuments() {
        if (!this.page) {
            return false;
        }

        const cmsDocs = this.page.extendedParams.documents;
        this.qs.getQuery({
            endPoint: IEndPoint.LMS_ENDPOINT,
            query: this.qs.getQueryBody('loan', this.page.extendedParams.queryConfig['getDocuments']['loan'], {id: this.loanId})
        }).subscribe(res => {
            this.data = res.data.loan || {};
            this.data.documents.items.map(doc => {
                const d = this.documents;
                const t = cmsDocs[doc.documentType].order;
                d[t] = d[t] || {};
                d[t]['label'] = cmsDocs[doc.documentType].label;
                d[t]['items'] = d[t]['items'] || [];
                d[t]['items'].push(doc);
            });
        });

        return true;
    }
}
