<lib-spinner *ngIf="!((page && data) || noLoansFound)"></lib-spinner>
<div class="account-overview no-loans" *ngIf="noLoansFound">
    <markdown [data]="page.kicker" *ngIf="page.kicker"></markdown>
</div>
<div class="account-overview" *ngIf="page && data as d">
    <div class="summary-section section">
        <ng-container *ngIf="paymentMethods?.length">
            <div class="autopay space-between">
                <div class="data-label">{{page.formFields['autopay'].label}}</div>
                <div class="data-value">
                    <form [formGroup]="fg" *ngIf="!autopayLoading">
                        <lib-input-switch [group]="fg" [item]="page.formFields['autopay']"
                                          (click)="launchAutopayModal($event)"
                                          [readonly]="page.formFields['autopay'].extendedParams['readonly']"
                                          (keyup.enter)="launchAutopayModal($event)"
                                          *ngIf="!page.formFields['autopay'].extendedParams['readonly']"></lib-input-switch>
                        <p *ngIf="page.formFields['autopay'].extendedParams['readonly']" class="autopay-value"
                           [ngClass]="{'enable': fg.controls['autopay'].value}">
                            {{fg.controls['autopay'].value ? page.formFields['autopay']['extendedParams'].enabledText : page.formFields['autopay']['extendedParams'].disabledText}}
                        </p>
                    </form>
                    <lib-spinner *ngIf="autopayLoading" size="25px"></lib-spinner>
                </div>
            </div>
        </ng-container>
        <div class="label-value">
            <div class="data-label">{{page.formFields['nextPayment'].label}}</div>
            <div class="data-value">{{(nextPayment || 0) | currency}}</div>
        </div>
        <div class="label-value">
            <div class="data-label">{{page.formFields['dueDate'].label}}</div>
            <div class="data-value">{{nextPaymentDate | date: page.formFields['dueDate'].format}}</div>
        </div>
        <markdown class="autopay-content"
                  [ngClass]="{'readonly': page.formFields['autopay'].extendedParams['readonly']}">
            {{page.formFields['autopay'].sectionInfo}}
        </markdown>
    </div>
    <div class="section recent-payments">
        <h4>{{page.formFields['onetimePayment'].label}}</h4>
        <table>
            <tr>
                <th>{{page.formFields['effectiveDate'].label}}</th>
                <th>{{page.formFields['type'].label}}</th>
                <th>{{page.formFields['amount'].label}}</th>
                <th>{{page.formFields['payMethod'].label}}</th>
            </tr>
            <ng-container *ngIf="schedules?.length">
                <tr *ngFor="let schedule of schedules">
                    <td>
                        {{schedule.scheduledPayments[0].effectiveDate | dataFormat: {
                        model: {
                            format: page.formFields['effectiveDate'].format,
                            type: page.formFields['effectiveDate'].type
                        },
                        timezone: {companyOffset: appConfig.companyOffset}
                    }
                        }}
                    </td>
                    <td>{{paymentTypes[schedule.payment.paymentTypeId].display}}</td>
                    <td>{{schedule.scheduledPayments[0].amount | currency}}</td>
                    <td>{{schedule.paymentMethod.name | titlecase}}</td>
                </tr>
            </ng-container>
            <ng-container *ngIf="!schedules?.length">
                <tr>
                    <td colspan="100%" class="no-payments">
                        {{page.extendedParams.noPaymentsText}}
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
    <div class="make-payment">
        <lib-button
                [label]="page.formFields['makePayment'].label"
                [klass]="page.formFields['makePayment'].klass"
                (click)="makePayment()"
                (keyup.enter)="makePayment()"
                *ngIf="page.formFields['makePayment']"
        ></lib-button>
    </div>
    <markdown class="autopay-content">
        {{page.formFields['makePayment'].sectionInfo}}
    </markdown>
</div>
