import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalConstants} from '@app/core/constants/global.constants';
import {BaseComponent} from '@lib/components/base/base.component';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'app-base-modal',
    templateUrl: './base-modal.component.html',
    styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent extends BaseComponent implements OnInit, OnDestroy {
    loading = false;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
        super();
    }

    ngOnInit(): void {
    }

    get data() {
        return this.config?.data;
    }

    cta(action?: string, actions?: any, response?: any) {
        if (action === 'close') {
            this.ref.close();
            this.ref.destroy();

            if (actions?.close) {
                actions.close(actions);
            }
        } else if (actions.primary) {
            this.loading = true;
            actions.primary(response);
        } else if (actions?.secondary) {
             actions.secondary(response);
        }
    }

    close(actions?: any) {
        this.cta('close', actions);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.ref.destroy();
    }
}
