import {NgModule} from '@angular/core';
import {APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {QueryClientService} from './services/query-client.service';

export function configureNamedClient(queryClient: QueryClientService, httpLink: HttpLink) {
    return queryClient.configureNamedClient(httpLink);
}

export function configureDefaultClient(queryClient: QueryClientService, httpLink: HttpLink) {
    return queryClient.configureDefaultClient(httpLink);
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: configureNamedClient,
            deps: [QueryClientService, HttpLink]
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: configureDefaultClient,
            deps: [QueryClientService, HttpLink]
        }
    ]
})
export class GraphQLModule {
}
