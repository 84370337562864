import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOffer } from '@app/core/components/offers/offers.component';

@Component({
  selector: 'app-offer-slider',
  templateUrl: './offer-slider.component.html',
  styleUrls: ['./offer-slider.component.scss']
})
export class OfferSliderComponent implements OnInit {
  @Input() title: string;
  @Input() steps: IOffer[];
  @Input() value : number;
  @Output() change: EventEmitter<any> = new EventEmitter();
  showSlider = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  /**
   * Event on slider change
   * show : only when offers are more than 1
   * value : slider value
   * @param e
   */
  filterOffers(e) {
    setTimeout(() => {
      this.showSlider = e.show;
      this.change.emit(e.value);
    });
  }

}
