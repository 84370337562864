<ng-container [formGroup]="group">
    <div class="field-textarea">
        <textarea class="pInputTextarea" [rows]="rows" [formControlName]="item.name" [id]="item.name"
                  [placeholder]="item.placeholder">
        </textarea>
        <ng-container *ngIf="formControl?.invalid && (formControl?.dirty || formControl?.touched)">
            <small class="p-error block"
                   *ngFor="let errKey of errorKeys; trackBy: trackByFn;">{{item.validations[errKey]?.message}}</small>
        </ng-container>
    </div>
</ng-container>
