import { Component, HostListener, OnInit } from '@angular/core';
import { ICmsPage } from '@lib/cms/models/cms.model';
import { getCmsPageContent } from '@lib/cms/store/cms/cms.selector';
import { UnsubscribeOnDestroyAdapter } from '@lib/adapters/unsub-on-destroy.adapter';
import { IAlert } from '@lib/models/lib.model';
import { Store } from '@ngrx/store';
import { getRouteParams } from '@lib/router/router.selector';
import { getAlerts } from '@lib/store/shared.selector';
import { Observable } from 'rxjs';
import { ModalService } from '@lib/services/modal.service';
import { MODAL_WINDOWS } from '@app/core/models/core.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  routeParams$: Observable<any>;
  alert$: Observable<IAlert>;
  cmsPage$: Observable<ICmsPage>;

  @HostListener('click', ['$event']) handleHostClick(event: PointerEvent) {
    const t = <any>event.target;
    if (t.nodeName === 'A' && t.type === 'modal') {
      this.modal.show(MODAL_WINDOWS[t.rel]);
    }


    return true;
  }


  constructor(private store$: Store, private modal: ModalService) {
    super();
  }



  ngOnInit(): void {
    this.routeParams$ = this.store$.select(getRouteParams);
    this.alert$ = this.store$.select(getAlerts);
    this.cmsPage$ = this.store$.select(getCmsPageContent);
  }

}
