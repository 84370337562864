<div *ngIf="(global$ | async) as nav" class="footer-content">
    <div class="container-max">
        <markdown [data]="nav.footerDisclaimer"></markdown>
        <div class="menus">
            <a [routerLink]="[]" *ngFor="let menu of nav.footerMenus; trackBy: trackByFn;" class="" tabindex="0"
            (click)="onClick(menu, $event)" (keyup.enter)="onClick(menu, $event)">
                {{menu.title}}
            </a>
        </div>
    </div>
    <div class="footer-disclosures" *ngIf="(page$ | async) as page">
        <markdown [data]="page.footerDisclosures"></markdown>
    </div>
</div>
