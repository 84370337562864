<ng-container [formGroup]="group" *ngIf="!readonly">
    <div *ngFor="let option of item.options;" class="field-radiobutton">
        <div class="field-radiobutton__input">
            <input type="radio" [name]="item.name" class="p-radiobutton" value="{{option.value}}"
                   [attr.id]="option.key"
                   [disabled]="option.disabled"
                   (click)="setAction($event)"
                   (keyup.enter)="setAction($event)"
                   [checked]="formControl.value === option.value ||  option.checked"
                   [attr.data-id]="option['data-id']"
            />
            <label [for]="option.key">{{option.key}}</label>
        </div>
        <div *ngIf="option.description" class="field-radiobutton__description">{{option.description}}</div>
    </div>
    <ng-container *ngIf="!hideError && formControl.invalid && (formControl.dirty || formControl.touched)">
        <small class="p-error block"
               *ngFor="let errKey of errorKeys; trackBy: trackByFn;">{{item.validations[errKey]?.message}}</small>
    </ng-container>
</ng-container>
<ng-container *ngIf="readonly">
    <div class="data-field">
        <div class="data-field-label">{{item.label}}</div>
        <div class="data-field-value">{{value}}</div>
    </div>
</ng-container>
