<div *ngIf="gc?.title" class="p-toolbar header"
     [ngClass]="{'menu-only': gc?.extendedParams['menuOnly']}">
    <div class="header__row container-max">
        <div class="header__row-col menu left-menu" *ngIf="hasLeftMenu && ((routeParams$) | async) as routeParams">
            <ng-container *ngIf="gc.hideHeaderNavUrls !== (currentUrl$ | async) && routeParams.page">
                <div class="header__row-col menu left">
                    <ng-container
                            *ngTemplateOutlet="menuItems;context:{position:'left',id:routeParams.id}"></ng-container>
                </div>
                <button (click)="toggleSideNav('leftSideNav', true)" (keyup.enter)="toggleSideNav('leftSideNav', true)"
                        class="hamburger inline-flex" *ngIf="hasLeftMenu">
                    <i class="pi pi-bars"></i>
                </button>
            </ng-container>
        </div>
        <div class="header__row-col logo">
            <a routerLink="/">
                <img src="{{cmsUrl}}{{gc?.logoUrl?.data?.attributes?.url}}" height="100%" width="100%"
                     style="{{gc.logoStyle}}"
                     (click)="gotoLanding(gc.extendedParams['partnerDetails'] && gc.extendedParams['partnerDetails']['landingUrl'])">
            </a>
        </div>
        <div class="header-content" *ngIf="selectedLoanId">Loan# {{selectedLoanId}}</div>
        <div class="header__row-col menu">
            <ng-container *ngIf="gc.hideHeaderNavUrls !== (currentUrl$ | async)">
                <div class="header__row-col menus" *ngIf="rightMenus?.length == 1" [ngClass]="{'only-one': rightMenus?.length === 1}">
                    <ng-container
                            *ngTemplateOutlet="menuItems;context:{items:rightMenus,position:'right'}"></ng-container>
                </div>
                <button (click)="toggleSideNav('rightSideNav',true)"
                        (keyup.enter)="toggleSideNav('rightSideNav',true)"
                        class="hamburger right inline-flex" *ngIf="rightMenus?.length > 1">
                    <ng-container *ngIf="profileName">
                        Hi {{profileName}}
                    </ng-container>
                    <ng-container *ngIf="!profileName">
                        <i class="pi pi-bars"></i>
                    </ng-container>
                </button>
            </ng-container>
        </div>
    </div>
    <p-sidebar [(visible)]="leftSideNav" position="left" class="mobile-menu">
        <ng-container
                *ngTemplateOutlet="menuItems;context:{position:'left'}"></ng-container>
    </p-sidebar>
    <p-sidebar [(visible)]="rightSideNav" position="right" class="mobile-menu">
        <ng-container
                *ngTemplateOutlet="menuItems;context:{position:'right'}"></ng-container>
    </p-sidebar>
</div>
<!-- Control items to show -->
<ng-template #menuItems let-position="position" let-id="id" let-items="items">
    <ng-container *ngFor="let menuItem of (items || menus); trackBy: trackByFn">
        <a (click)="performAction($event, menuItem)"
           (keyup.enter)="performAction($event, menuItem)"
           *ngIf="menuItem.enabled && meetsRequirements(menuItem.extendedParams?.requires)
       && position === menuItem.position
       && !menuItem.hideMenuUrls?.includes((currentUrl$ | async))
       && ((isAuthenticated && menuItem.authenticated) || (!isAuthenticated && !menuItem.authenticated))"
           [ngClass]="{'show-always': menuItem.showAlways}"
           routerLink="{{id ? '/applications/' + id + menuItem.url: menuItem.url}}"
           routerLinkActive="active"
           [routerLinkActiveOptions]="{exact:routerLinkActiveExact}"
           class="menu-item {{menuItem.type}}"
           tabindex="0"
        >
            <ng-container *ngIf="menuItem.type === 'link' || menuItem.type === 'tab'">
                <i *ngIf="menuItem.icon" class="{{menuItem.icon}}"></i>
                {{menuItem.title}}
            </ng-container>
            <button *ngIf="menuItem.type === 'button'" class="p-button p-button-sm">{{menuItem.title}}</button>
        </a>
    </ng-container>
</ng-template>
