<lib-spinner *ngIf="!((page && data) || noLoansFound)"></lib-spinner>
<div class="account-overview no-loans" *ngIf="noLoansFound">
    <h4>{{page.title}}</h4>
    <markdown [data]="page.kicker" *ngIf="page.kicker"></markdown>
</div>
<div class="documents-list account-page" *ngIf="page && data as d">
    <h1>{{page.title}}</h1>
    <ng-container class="section">
        <lib-accordion template="accordion-tab-document" [model]="{data: documents, appId: loanId}"
                       [cms]="page.extendedParams['documents']">
        </lib-accordion>
    </ng-container>
</div>
