import {Injectable} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {environment} from "@environment/environment";

function getWindow():any {
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(private pConfig: PrimeNGConfig) {
    }

    initConfig() {
        this.pConfig.ripple = true;
    }

    loadjscssfile(config: { url: string, type: string, cache: boolean }) {
        let fileRef;
        const head = document.getElementsByTagName('head')[0];
        if (config.type === "js") {
            fileRef = document.createElement('script');
            fileRef.type = "text/javascript";
            if (!config.cache) {
                fileRef.href = `${config.url}?dt=${new Date().getTime()}`;
            } else {
                fileRef.href = config.url;
            }
            fileRef.setAttribute("type", "text/javascript");
            fileRef.setAttribute("src", config.url);
            fileRef.setAttribute("defer", '');
        } else if (config.type === "css") {
            fileRef = document.createElement("link");
            fileRef.rel = "stylesheet";
            fileRef.type = "text/css";
            if (!config.cache) {
                fileRef.href = `${config.url}?dt=${new Date().getTime()}`;
            } else {
                fileRef.href = config.url;
            }
            fileRef["referrerpolicy"] = "no-referrer";
        }
        head.appendChild(fileRef);
    }

    get nativeWindow() {
        return getWindow();
    }
}
