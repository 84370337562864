import {Injectable} from '@angular/core';

export const APP_STORAGE = {
    POST_LOGIN_RESPONSE: 'post-login-response',
    EMAIL: 'email'
};

@Injectable({
    providedIn: 'root'
})
export class AppStorageService {

    constructor() {
    }

    getItem(name: string) {
        return JSON.parse(sessionStorage.getItem(name));
    }

    setItem(name: string, payload: any) {
        sessionStorage.setItem(name, JSON.stringify(payload));
    }

    removeItem(name) {
        sessionStorage.removeItem(name);
    }
}
