import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppState} from '@app/app.state';
import { APP_PAGE_ROUTES } from '@app/core/models/core.model';
import {UnsubscribeOnDestroyAdapter} from '@lib/adapters/unsub-on-destroy.adapter';
import {ICmsPage} from '@lib/cms/models/cms.model';
import {loadCmsPageContent} from '@lib/cms/store/cms/cms.actions';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import {getUserProfile} from '@lib/cms/store/common/common.selector';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {StorageService} from '@lib/services/storage.service';
import { setLoanSelected } from '@lib/store/shared.actions';
import {getAppConfig, getAuthState, getLoanSelected} from '@lib/store/shared.selector';
import {IAppConfig} from '@lib/store/shared.state';
import {Store} from '@ngrx/store';
import {forkJoin, take} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

type LoanProduct = {
    loanAmount: number;
}

type LoanTerms = {
    product: LoanProduct | null
}

type AppliedTransaction = {
  
}

type ItemsResult<T> = {
    items: T[]
}

export type LoanOption = {
    loanId : string;
    loanNumber: string;
    loanTerms: LoanTerms | null;
    appliedTransactions: ItemsResult<AppliedTransaction>;
};

@Component({
    selector: 'app-account-base-page',
    templateUrl: './account-base-page.component.html',
    styleUrls: ['./account-base-page.component.scss']
})
export class AccountBasePageComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
    id: any;
    page: ICmsPage;
    data: any;
    email: string;
    loading: boolean;
    loanId: number;
    loans: LoanOption[];
    noLoansFound: boolean;
    appConfig: IAppConfig;

    constructor(
        protected store$: Store<AppState>,
        protected storage: StorageService,
        protected authProvider: AuthProviderService,
        protected qs: QueryApiService,
        protected router: Router
    ) {
        super();
    }

    ngOnInit(): void {

    }

    getLoanData(pathName, callback) {
        this.store$.dispatch(loadCmsPageContent({pathName}));
        forkJoin(
            [this.store$.select(getAuthState).pipe(take(1))] // Is Authenticated
        ).subscribe(results => {
            if (!results[0] || !(this.storage.token && this.storage.idToken)) {
                this.authProvider.login();
                return false;
            }

            this.store$.select(getUserProfile)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(profile => {
                    if (profile.email) {
                        this.email = profile.email;
                        this.getLoanId(callback);
                    }
                });

            this.store$.select(getCmsPageContent)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(page => {
                    this.page = page;
                    if (this.page?.name === this.router.url.substring(1)) {
                        this.getLoanId(callback);
                    } else {
                        this.page = null;
                    }
                });

            this.store$.select(getAppConfig)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(config => {
                    if (config) {
                        this.appConfig = config;
                    }
                });

            return true;
        });
    }

    /**
     * Outer query is to get LoanId by email
     * Inner query is to get Loan summary by LoanId
     */
    getLoanId(callback) {
        this.loanId = parseInt(sessionStorage.getItem('loanId'));
        if (!this.loanId) this.router.navigate([APP_PAGE_ROUTES.ACCOUNT_OVERVIEW]);
        if (!this.store$.select(getLoanSelected)) this.store$.dispatch(setLoanSelected({status: true}));
        callback();
        return true;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.page = null;
        this.email = null;
        this.data = null;
    }

}
