import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from '../base/base.component';

@Component({
    selector: 'app-dynamic-base-component',
    templateUrl: './dynamic-base.component.html',
    styleUrls: ['./dynamic-base.component.scss']
})
export class DynamicBaseComponent extends BaseComponent {
    @Input() updated;
    @Input() model;
    @Input() template;
    @Output() onSubmit: EventEmitter<any> = new EventEmitter();

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
