<div *ngIf="pageLoading || !currentPage || polling" class="loading">
    <lib-spinner></lib-spinner>
</div>
<div *ngIf="cmsPage && !pageLoading && !cmsPage.ignoreFlow && currentPage">
    <lib-page-actions [actions]="currentPage?.actions" [appId]="urlContext.id"></lib-page-actions>
    <form (ngSubmit)="onSubmit()" [formGroup]="fg" autocomplete="off" class="form {{urlContext.name}}" id="page-form">
        <!-- Description loads from page -->
        <markdown [data]="cmsPage.description" class="description" *ngIf="cmsPage.description"></markdown>
        <!-- Sub Description loads from page component if exists and consumed eg: offer page -->
        <lib-form *ngIf="formSubmitSchema && cmsPage && fg" [cmsPage]="cmsPage" [data]="page.data" [group]="fg"
                  [schema]="formSubmitSchema" [pageGetSchema]="pageGetSchema"></lib-form>
        <!-- Disclaimer loads from page -->
        <markdown [data]="cmsPage.disclaimer" class="disclaimer" *ngIf="cmsPage.disclaimer"></markdown>
        <div class="cta-container"
             *ngIf="cmsPage.primaryCtaText && !(cmsPage.extendedParams && cmsPage.extendedParams['hidePagePrimary'])">
            <lib-button [label]="cmsPage.primaryCtaText" [loading]="loading"></lib-button>
        </div>
    </form>
</div>
<div class="" *ngIf="cmsPage && cmsPage.ignoreFlow">
    <ng-container
            appDynamicPageRender
            [cmsPage]="cmsPage"
            [data]="page.data"
            [formSubmitSchema]="formSubmitSchema"
            [pageGetSchema]="pageGetSchema"
            *ngIf="page"
    ></ng-container>
</div>
