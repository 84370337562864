import { Component, Input, OnInit } from '@angular/core';
import { IOffer, IOfferPage } from '@app/core/components/offers/offers.component';
import { IFormField } from '@lib/cms/models/cms.model';

@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit {
  @Input() cmsPage: IOfferPage;
  @Input() offer: IOffer;
  @Input() noOffer: boolean;
  @Input() item: IFormField;
  @Input() pageGetSchema: {
    [key: string]: any;
  };

  constructor() {
  }

  ngOnInit(): void {
  }

}
