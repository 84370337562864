import { HttpClient } from '@angular/common/http';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {IUrlContext} from '@lib/models/lib.model';
import { ApiService } from '@lib/services/api.service';
import {Store} from '@ngrx/store';
import { BaseFormComponent } from '../base-component/base-form.component';

enum ACTIONS {
    LOGIN,
    RESUME
};

export enum POST_LOGIN_METHOD {
    PASSWORD,
    CHALLENGE,
    OTP
};

export enum POST_LOGIN_ACTION {
    ACCOUNT,
    APPLICATION
};

type PostLoginResponse = {
    method: POST_LOGIN_METHOD;
    postLogin: POST_LOGIN_ACTION,
    email? : string,
    phone? : string,
    applicationId : string
}

type AuthenticatedResponse = {
    accessToken: string
}

@Component({
    selector: 'lib-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss']
})
export class OtpComponent extends BaseFormComponent implements OnInit, OnDestroy {
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    loading: boolean;
    urlContext? : IUrlContext;
    ACTIONS = ACTIONS;
    resendDisabled: boolean;
    otpType: string;

    constructor(
        private store$: Store,
        private http: HttpClient,
        private apiService: ApiService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.otpType = this.item.extendedParams?.otpType ?? "email";
        this.urlContext = <IUrlContext>JSON.parse(sessionStorage.getItem('launchParams'));
    }

    blur(e) {
        if (e.target.value) {
            e.target.value = e.target.value.trim();
            if (!this.item.subType) {
                this.formControl.setValue(e.target.value);
            }
            this.formControl.updateValueAndValidity();
        }
        if (this.onBlur) {
            this.onBlur.emit(e);
        }
    }

    resendOtp($event: any) : boolean {
        this.loading = true;
        this.resendDisabled = true;
        this.http.post(this.apiService.buildUrl("otp/"+ this.otpType), {
            applicationId: this.urlContext.id,
        }).subscribe({next: r => {
            this.loading = false;
            setTimeout(this.reenableResend.bind(this), 30000);
        }});
        return false;

    }

    reenableResend() {
        this.resendDisabled = false;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
