import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppState} from '@app/app.state';
import {setAuthState} from '@lib/store/shared.actions';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AuthProviderService, IAuthTokens} from './authenticate/auth-provider.service';
import {ConfigService} from './config.service';
import {StorageService} from './storage.service';

export interface IUser {
    given_name?: string;
    family_name?: string;
    email?: string;
    name?: string; // first & last name
    role?: string[];
    email_verified?: boolean;
    sub?: string; // user Id ??
    preferred_username?: string; // email is populated
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private store: Store<AppState>,
        private storage: StorageService,
        private cs: ConfigService,
        private http: HttpClient,
        private authProvider: AuthProviderService
    ) {
    }

    getProfile(): Observable<IUser> {
        const url = `${this.cs.get('keycloakUrl')}userinfo`;
        return this.http.get(url) as Observable<IUser>;
    }

    logIn(tokens?: IAuthTokens) {
        if (tokens?.access_token || this.storage.token) {
            this.storage.token = tokens?.access_token || this.storage.token;
            this.storage.idToken = tokens?.id_token || this.storage.idToken;
            this.storage.refreshToken = tokens?.refresh_token || this.storage.refreshToken;
            this.store.dispatch(setAuthState({status: true, credentialsExist: !!this.storage.idToken}));
        }
    }

    logout() {
        this.storage.clearTokens();
        this.store.dispatch(setAuthState({status: false, credentialsExist: false}));
    }
}
