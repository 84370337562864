<div *ngIf="group && pageFields?.length" class="form-container">
    <div *ngFor="let field of pageFields; trackBy: trackByFn;"
         [ngClass]="{'has-sub-form': field.subFormFields?.length}"
         class="form-field {{field?.klass}}" style="{{field.style}}">
        <ng-container
                *ngIf="!field.subFormFields?.length && !field.hide"
                [group]="group"
                [item]="field"
                [readonly]="readonly"
                [data]="data"
                [pageGetSchema]="pageGetSchema"
                [formSubmitSchema]="schema"
                [page]="cmsPage"
                appDynamicFormRender
        ></ng-container>
        <ng-container *ngIf="field.subFormFields?.length && !field.hide">
            <ng-container
                    *ngIf="cmsPage.type"
                    [group]="group"
                    [item]="field"
                    [data]="data"
                    [pageGetSchema]="pageGetSchema"
                    [formSubmitSchema]="schema"
                    [page]="cmsPage"
                    appDynamicFormRender
            ></ng-container>
            <div class="sub-form {{field.klass}}" style="{{field.style}}">
                <div *ngFor="let subField of field.subFormFields; trackBy: trackByFn;" [class]="subField?.klass"
                     class="form-field">
                    <ng-container
                            *ngIf="!field.hide"
                            [group]="group.controls[field.name]"
                            [item]="subField"
                            [readonly]="readonly"
                            [data]="data"
                            [pageGetSchema]="pageGetSchema"
                            [formSubmitSchema]="schema"
                            [page]="cmsPage"
                            appDynamicFormRender
                    ></ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>
