import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UnsubscribeOnDestroyAdapter} from '@lib/adapters/unsub-on-destroy.adapter';

@Component({
  selector: 'app-page-base-action',
  templateUrl: './page-base-action.component.html',
  styleUrls: ['./page-base-action.component.scss']
})
export class PageBaseActionComponent extends UnsubscribeOnDestroyAdapter {
  @Input() appId: string;
  @Input() actionType: string;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }
}
