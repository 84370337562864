import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseFormComponent } from '@lib/components/form/base-component/base-form.component';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent extends BaseFormComponent implements OnInit, OnDestroy {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
