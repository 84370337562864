import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {ICmsPage} from '@lib/cms/models/cms.model';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import {BaseFormComponent} from '@lib/components/form/base-component/base-form.component';
import {IUrlContext} from '@lib/models/lib.model';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-fund',
    templateUrl: './fund.component.html',
    styleUrls: ['./fund.component.scss']
})
export class FundComponent extends BaseFormComponent implements OnInit {
    page$: Observable<ICmsPage>;
    urlContext: IUrlContext;

    constructor(
        private store$: Store,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.page$ = this.store$.select(getCmsPageContent);
        this.route.params.subscribe(() => {
            this.urlContext = {
                id: this.route.snapshot.paramMap.get('id'),
                name: this.route.snapshot.paramMap.get('page') || this.route.snapshot.paramMap.get('id'),
                itemId: this.route.snapshot.paramMap.get('itemId')
            };
        });
    }

    submit() {
        this.router.navigate([APP_PAGE_ROUTES.ACCOUNT_OVERVIEW]);
    }

}
