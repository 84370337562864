import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * A class that automatically unsubscribes all observables when the object is destroyed.
 */
@Injectable()
export class UnsubscribeOnDestroyAdapter implements OnDestroy {
  unsubscribe: Subject<void> = new Subject();

  /**
   * Lifecycle hook that will unsusbscribe all subscriptions.
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
