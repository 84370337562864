import {Injectable, Injector} from '@angular/core';
import {ILookupConfig} from '../../components/form/lookup/lookup.component';
import {AddressService} from './address.service';
import {BankService} from './bank.service';
import {EmployerService} from './employer.service';

export interface ILookupService {
    getConfig(): ILookupConfig;

    getDisplayOption(option: any): string;

    formatDisplayOptions(options: any[]): any[];

    setOptionToForm(option: any, key: string): any;
    setCustomToForm(value: string) : any;
}

const lookupInstanceMap = {
    address: AddressService,
//    employer: EmployerService,
//    bank: BankService
};

@Injectable({
    providedIn: 'root'
})
export class LookupService {

    constructor(private injector: Injector) {
    }

    /**
     * Get instance configured from an available Lookup Instances
     */
    getInstance(type): ILookupService {
        if (!lookupInstanceMap[type]) return null;
        return this.injector.get<any>(lookupInstanceMap[type]);
    }

    getConfig(type, config: ILookupConfig): ILookupConfig {
        return this.getInstance(type)?.getConfig() || config;
    }

    getDisplayOption(type: string, option: any): any {
        return this.getInstance(type)?.getDisplayOption(option) || option;
    }

    formatDisplayOptions(type: string, options?: any): any {
        return this.getInstance(type)?.formatDisplayOptions(options) || options;
    }

    setOptionToForm(type: string, options?: any, key?: string): any {
        return this.getInstance(type)?.setOptionToForm(options, key) || options[key];
    }

    setCustomToForm(type: string, value: any) : any {
        return this.getInstance(type)?.setCustomToForm(value) || value;
    }
}
