import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {AppState} from '@app/app.state';
import {getCurrentUrl, getRouteParams} from '@lib/router/router.selector';
import {IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {setAppConfig, setLoanSelected, setMobileMode} from '@lib/store/shared.actions';
import {getAuthState, getCredentialsState, getLoanSelected} from '@lib/store/shared.selector';
import {IAppConfig} from '@lib/store/shared.state';
import {Store} from '@ngrx/store';
import {debounceTime, fromEvent, Observable, takeUntil} from 'rxjs';
import {CMS_PAGE_ROUTES, IGlobal} from '@lib/cms/models/cms.model';
import {loadGlobalContent} from '@lib/cms/store/cms/cms.actions';
import {getGlobalContent} from '@lib/cms/store/cms/cms.selector';
import {loadUserProfileContent} from '@lib/cms/store/common/common.actions';
import {getUserProfile} from '@lib/cms/store/common/common.selector';
import {IMenuItem} from '@lib/models/lib.model';
import {AppApiService} from '@lib/services/app-api.service';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {CommonService} from '@lib/services/common.service';
import {ConfigService} from '@lib/services/config.service';
import {ScrollToService} from '@lib/services/scroll-to.service';
import {StorageService} from '@lib/services/storage.service';
import {UserService} from '@lib/services/user.service';
import {UtilService} from '@lib/services/util.service';
import {BaseComponent} from '../base/base.component';
import {environment} from "@environment/environment";

const MAX_MOBILE_SIZE = 480;

const MENUS_POSITION = {
    LEFT: 'left',
    RIGHT: 'right'
};

@Component({
    selector: 'lib-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit, AfterViewInit {
    @Input() routerLinkActiveExact = true;
    navContent$: Observable<any>;
    gc: IGlobal;
    isAuthenticated: boolean;
    credentialsExist: boolean;
    currentUrl$: Observable<any>;
    userProfile$: Observable<any>;
    routeParams$: Observable<any>;
    isMobile: boolean;
    leftSideNav = false;
    rightSideNav = false;
    menus: IMenuItem[];
    selectedMenuItem: IMenuItem = {type: 'link'};
    baseUrl = '';
    hasLeftMenu: boolean;
    rightMenus: IMenuItem[];
    profileName: string;
    now = Date.now();
    cmsUrl: string;
    loanSelected: boolean;
    selectedLoanId: string | null;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private store: Store<AppState>,
        private scrollToService: ScrollToService,
        private userService: UserService,
        private title: Title,
        private common: CommonService,
        private authProviderService: AuthProviderService,
        private storage: StorageService,
        private cs: ConfigService,
        private api: AppApiService,
        private qs: QueryApiService
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(loadGlobalContent());
        this.cmsUrl = this.cs.get('cmsAssetUrl');
        if (this.storage.token && this.storage.idToken) {
            this.store.dispatch(loadUserProfileContent());
        }

        this.currentUrl$ = this.store.select(getCurrentUrl);
        this.navContent$ = this.store.select(getGlobalContent);
        this.baseUrl = this.cs.get('baseUrl');
        this.userProfile$ = this.store.select(getUserProfile);
        this.routeParams$ = this.store.select(getRouteParams);
        this.profileName = sessionStorage.getItem('firstName');

        this.store.select(getCredentialsState).pipe(takeUntil(this.unsubscribe))
            .subscribe(credentialsExist => {
                this.credentialsExist = credentialsExist;
                const ep = this.gc?.extendedParams;
                if (ep && ep['appConfig'] && ep['appConfig']['auth'] === credentialsExist) {
                    this.getAppConfig(ep['appConfig']);
                }
            });

        this.store.select(getLoanSelected)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(v => {
            this.loanSelected = v;
            this.selectedLoanId = this.isAuthenticated ? sessionStorage.getItem("loanId") : null;
            this.populateMenus(this.isAuthenticated);
        });

        this.store.select(getAuthState)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(authenticated => {
                this.isAuthenticated = authenticated;
                
                this.selectedLoanId = this.isAuthenticated ? sessionStorage.getItem("loanId") : null;
                if (this.selectedLoanId) {
                    this.store.dispatch(setLoanSelected({status: true}));
                }
                this.populateMenus(authenticated);
            });

        this.navContent$.pipe(takeUntil(this.unsubscribe)).subscribe((gc: IGlobal) => {
            this.gc = gc;
            if (gc.headerMenus) {
                this.menus = gc.headerMenus;
            }

            this.hasLeftMenu = this.menus?.filter(menu => menu.position === MENUS_POSITION.LEFT).length > 1;
            this.selectedLoanId = this.isAuthenticated ? sessionStorage.getItem("loanId") : null;
            this.populateMenus(this.isAuthenticated);
            const partnerDetails = gc?.extendedParams && gc.extendedParams['partnerDetails'];
            if (partnerDetails) {
                const csPath = this.cs.get('clientStylesPath');
                partnerDetails?.assets?.forEach(asset => {
                    this.common.loadjscssfile({
                        url: environment.production ? asset.path : (asset.name === 'clientStyles') ? csPath : asset.path,
                        type: asset.type,
                        cache: asset.cache
                    });
                });

            }

            this.title.setTitle(gc.title);
            const ac = gc.extendedParams && gc.extendedParams['appConfig'] || {};
            if (ac['auth'] === this.credentialsExist) {
                this.getAppConfig(gc.extendedParams['appConfig']);
            }

            return true;
        });

        fromEvent(window, 'resize')
            .pipe(
                debounceTime(250),
                takeUntil(this.unsubscribe))
            .subscribe((event: any) => {
                this.onMobileCheck(event);
            });
    }

    onMobileCheck(event) {
        if (event.target?.innerWidth <= MAX_MOBILE_SIZE) {
            this.isMobile = true;
            this.store.dispatch(setMobileMode({status: true}));
        } else {
            this.isMobile = false;
            this.store.dispatch(setMobileMode({status: false}));
            this.toggleSideNav('', false);
        }
    }

    meetsRequirements(requires: any) {
        
        if (requires) {
            return requires.every(v => this[v]);
        }

        return true;
    }

    toggleSideNav(side?: string, isOpen?: boolean) {
        if (side) {
            this[side] = !!isOpen;
        } else {
            this.leftSideNav = isOpen;
            this.rightSideNav = isOpen;
        }
    }

    /* istanbul ignore next */
    ngAfterViewInit(): void {
        this.route.fragment.subscribe(fragment => {
            if (fragment) {
                setTimeout(() => {
                    this.scrollToService.scrollTo('#' + fragment);
                });
            }
        });
    }

    populateMenus(authenticated, position = 'right') {
        this[position + 'Menus'] = this.menus?.filter(menu => {
            return (menu.position === MENUS_POSITION.RIGHT) && (authenticated === menu.authenticated);
        });
    }

    performAction($event: Event, menuItem: IMenuItem) {
        $event.stopPropagation();
        this.leftSideNav = false;
        this.rightSideNav = false;
        this.selectedMenuItem = menuItem;
        if (menuItem.onClick) {
            this[menuItem.onClick]();
        }
    }

    login() {
        this.router.navigate([CMS_PAGE_ROUTES.LOGIN]);
    }

    authLogin() {
        this.authProviderService.login();
    }

    getAppConfig(appConfig) {
        if (appConfig.url === 'query.config') {
            this.qs.getQuery({
                endPoint: appConfig?.endPoint || IEndPoint.API_ENDPOINT,
                query: this.qs.getQueryBody('configuration', this.gc.extendedParams['query']['configuration'], {})
            }).subscribe(res => {
                const config: IAppConfig = this.mapAppConfig(res.data.configuration);
                this.store.dispatch(setAppConfig(config as any));
            });
        } else {
            this.api.getData(appConfig.url).subscribe(config => {
                this.store.dispatch(setAppConfig(config));
            });
        }
    }

    mapAppConfig(obj): IAppConfig {
        const config = {} as IAppConfig;
        Object.keys(obj).map(prop => {
            let cmsTypes = {};
            if (this.gc[prop]) {
                cmsTypes = UtilService.arrayToObjectByKey(this.gc[prop]);
            }
            config[prop] = obj[prop];
            if (Array.isArray(config[prop])) {
                config[prop].map(cp => {
                    const cmsType = cmsTypes['id' + cp['id']]; // eg: id2, id4 etc
                    if (cmsType) {
                        cp['key'] = cp['id'];
                        cp['value'] = cmsType['title'];
                        cp['display'] = cmsType['title'];
                        cp['order'] = cmsType['order'];
                        cp['type'] = cmsType['type'];
                    } else {
                        cp['key'] = cp['id'];
                        cp['value'] = cp['display'];
                    }
                });
                // sort by order
                let types = config[prop];
                if (types?.length && Object.prototype.hasOwnProperty.call(types[0], 'order')) {
                    types = UtilService.sort(types, 'order');
                }
                config[prop] = types;
            }
        });

        return config;
    }

    logout() {
        if (this.storage.idToken) {
            this.authProviderService.logOut();
        } else {
            this.userService.logout();
            this.router.navigate(['/']);
        }
    }

    gotoLanding(url?: string) {
        if (!url) {
            this.router.navigate(['/']);
        } else {
            document.location.href = url;
        }
    }
}
