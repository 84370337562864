<h1>{{paymentSuccess ? page?.formFields['paymentSuccess'].label : page?.title}}</h1>
<lib-spinner *ngIf="!((page && data) || noLoansFound)"></lib-spinner>
<ng-container *ngIf="page && page.title">
    <div class="account-overview no-loans" *ngIf="noLoansFound">
        <markdown [data]="page.kicker" *ngIf="page.kicker"></markdown>
    </div>
    <div class="payment" *ngIf="paymentMethods && data">
        <ng-container *ngIf="!paymentSuccess">
            <form [formGroup]="fg" class="form-container" autocomplete="off" (ngSubmit)="onSubmit()">
                <p class="payment__amount-type">{{page.formFields['paymentAmountType'].label}}</p>
                <lib-radio [item]="paymentAmountTypeField" [group]="fg"
                           class="payment__amount-options" (onchange)="onPaymentAmountTypeChange"></lib-radio>
                <markdown class="payment__description"
                          [ngClass]="{'past-due': data.details?.amountPastDue, 'no-past-due': !data.details?.amountPastDue}">
                    {{page.description | stringReplace: {
                    '{amountPastDue}': data.state.details?.amountPastDue | currency,
                    '{currentPaymentAmount}': ((data.state.details?.nextPaymentAmount || 0) - data.details?.amountPastDue) | currency,
                    '{nextPaymentAmount}': data.state.details?.nextPaymentAmount | currency,
                    '{nextPaymentDate}': data.state.details?.nextPaymentDate | dataFormat: {
                        model: {
                            format: page.formFields['date'].extendedParams['displayFormat'],
                            type: page.formFields['date'].type
                        },
                        timezone: {companyOffset: appConfig.companyOffset}
                    }
                } }}
                </markdown>
                <markdown [data]="page.subDescription" class="payment__sub-description"
                          [ngClass]="{'autopay': autopayEnabled}"></markdown>
                <lib-input class="form-field" [item]="page.formFields['amount']" [group]="fg"></lib-input>
                <p-dropdown
                    [options]="paymentMethodOption.option.values"
                    [group]="true"
                    [placeholder]="paymentMethodOption.placeholder || paymentMethodOption.label || paymentMethodOption.name"
                    [showClear]="false"
                    [optionLabel]="paymentMethodOption.option?.filterBy"
                    [optionValue]="paymentMethodOption.option?.return"
                    formControlName="paymentMethodId"
                    *ngIf="!paymentMethodOption.option?.isFilterable"
                    [ngClass]="{'ng-invalid ng-dirty': fg.get('paymentMethodId').invalid && (fg.get('paymentMethodId').dirty || fg.get('paymentMethodId').touched)}"
                    [appendTo]="'body'"
                    [disabled]="false"
                    [group]="true"
                    [panelStyleClass]="'payment-method'"
                    id="{{paymentMethodOption.name}}"
                ></p-dropdown>
                <lib-datepicker [item]="page.formFields['date']" [group]="fg" [date]="dateObj.current"
                                [minDate]="dateObj.minDate" [maxDate]="dateObj.maxDate"></lib-datepicker>
                <markdown class="disclaimer"
                          *ngIf="page.disclaimer"
                          [data]="page.disclaimer | stringReplace: {
                          '{fullName}': fullName,
                          '{amount}': fg.controls['amount'].value | currency,
                          '{loanNumber}': loanId,
                          '{accountLastFour}': isAch && (selectedPaymentMethod?.paymentDetails?.accountLastFour || ''),
                          '{effectiveDate}': effectiveDate | date: page.formFields['date']['extendedParams']['disclaimerEffectiveDateFormat'],
                          '{processDate}': fg.controls['date'].value | date: page.formFields['date']['extendedParams']['disclaimerProcessDateFormat']
                          }"
                          [ngClass]="{'third-party': !isAch, 'form': isAch, 'hide': isAch && !selectedPaymentMethod }">
                </markdown>
                <lib-button
                        [label]="page.formFields['submit'].label"
                        class="flex-center make-payment"
                        [loading]="loading"
                        [klass]="page.formFields['submit'].klass"
                ></lib-button>
            </form>
        </ng-container>
        <ng-container *ngIf="paymentSuccess">
            <markdown class="payment__success-description">
                {{page.formFields['paymentSuccess'].sectionInfo | stringReplace: {
                '{paymentAmount}': fg.controls['amount'].value | currency,
                '{paymentDate}': fg.controls['date'].value  | dataFormat: {
                    model: {
                        format: page.formFields['date'].extendedParams['displayFormat'],
                        type: page.formFields['date'].type
                    }
                }
            } }}
            </markdown>
            <lib-button
                    [label]="page.formFields['continue'].label"
                    class="flex-center make-payment"
                    (click)="goToAccountOverview()"
                    (keyup.enter)="goToAccountOverview()"
                    [loading]="loading"
                    [klass]="page.formFields['continue'].klass"
            ></lib-button>
        </ng-container>
    </div>
</ng-container>
