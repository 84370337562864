import {loadDocumentsContentSuccess, loadOffersContentSuccess} from '@app/core/store/app-data.actions';
import {UtilService} from '@lib/services/util.service';
import {createReducer, on} from '@ngrx/store';
import {initialState} from './app-data.state';

function processDocuments(content) {
    const data = content.map(item => {
        const itemCopy = Object.assign({}, item);
        const actions = itemCopy.availableCmsActions;
        itemCopy.availableCmsActions = UtilService.arrayToObjectByKey(actions, 'actionId');
        item = Object.assign({}, itemCopy);
        return item;
    });
    return UtilService.arrayToObjectByKey(data, 'documentId');
}

const _appDataReducer = createReducer(
    initialState,
    on(loadOffersContentSuccess, (state, action) => {
        return {
            ...state,
            offers: action.content
        };
    }),
    on(loadDocumentsContentSuccess, (state, action) => {
        return {
            ...state,
            documents: processDocuments(action.content)
        };
    })
);

export function AppDataReducer(state, action) {
    return _appDataReducer(state, action);
}
