import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {AppService} from '@app/core/services/app.service';
import {UserService} from '@lib/services/user.service';
import {UnsubscribeOnDestroyAdapter} from '@lib/adapters/unsub-on-destroy.adapter';
import {IUrlContext} from '@lib/models/lib.model';
import {AppApiService} from '@lib/services/app-api.service';
import {CommonService} from '@lib/services/common.service';
import {Store} from '@ngrx/store';
import {setAlertMessage, setApplication} from '@lib/store/shared.actions';
import {BehaviorSubject, filter, map} from 'rxjs';
import {TRACK_EVENTS, TrackingService} from "@lib/services/tracking/tracking.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
    appExists = new BehaviorSubject(false);
    urlContext: IUrlContext;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appService: AppService,
        private store$: Store,
        private user: UserService,
        private common: CommonService,
        private ts: TrackingService
    ) {
        super();
        this.resumeApplication();
    }

    @HostListener('click', ['$event.target'])
    onClick(e) {
        if (e.classList.contains(TRACK_EVENTS.CLICK)) {
            this.ts.logEvent({
                event: TRACK_EVENTS.CLICK,
                category: TRACK_EVENTS.PAGE_SUBMIT,
                action: e.nodeName,
                label: e.innerText
            })
        }
    }

    ngOnInit(): void {
        this.ts.init();
        this.common.initConfig();
        this.user.logIn();
    }

    /**
     * Page refresh stays on same if page exists in allowed pages
     */
    resumeApplication() {
        this.router.events.pipe(
            // identify navigation end
            filter((event) => event instanceof NavigationEnd),
            // now query the activated route
            map(() => this.rootRoute(this.route)),
            filter((route: ActivatedRoute) => route.outlet === 'primary')
        ).subscribe((route: ActivatedRoute) => {
            this.onNavigationEnd(route);
        });
    }

    private rootRoute(route: ActivatedRoute): ActivatedRoute {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }

    onNavigationEnd(route: ActivatedRoute) {
        this.ts.logPageView(this.router.url);
        this.store$.dispatch(setAlertMessage(null));
        this.urlContext = {
            id: route.snapshot.paramMap.get('id'),
            name: route.snapshot.paramMap.get('page'),
            itemId: route.snapshot.paramMap.get('itemId')
        };

        if (this.urlContext.itemId) {
            sessionStorage.setItem('launchParams', JSON.stringify(this.urlContext));
        }

        const uc = this.urlContext;
        if (!uc.name) {
            return false;
        } else if (uc.id === APP_PAGE_ROUTES.GET_STARTED) {
            this.store$.dispatch(setApplication({applicationId: 0, progressSteps: [], pages: {}}));
        } else if (uc.id && uc.id !== APP_PAGE_ROUTES.STATIC_PAGES && uc.name && !uc.itemId && !this.appExists.value) {
            this.appService.getCurrentApplication(<string>uc.id, uc.name, this.appExists);
        } else if (uc.id === APP_PAGE_ROUTES.STATIC_PAGES) {
            this.store$.dispatch(setApplication({applicationId: 0, progressSteps: [], pages: {}}));
        } else if (uc.id && !uc.name) {
            this.router.navigate([APP_PAGE_ROUTES.PAGE_404]);
        }
        return true;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.ts.clear();
    }
}
