<ng-container *ngIf="!readonly && item && item.subType === 'address'">
    <h5 class="field-heading" *ngIf="item.actionLabel">{{item.actionLabel}}</h5>
    <div class="field">
        <label [for]="item.name" class="p-float-label" *ngIf="item.label">{{ item.label }}</label>
        <span class="p-input-icon-right">
            <input #input
                   autocomplete="off"
                   [attr.aria-describedby]="item.name + '-help'"
                   [placeholder]="item.placeholder || item.name"
                   [id]="item.name"
                   (blur)="setOrPrefill($event, 'set')"
                   (keyup.enter)="setOrPrefill($event, 'set')"
                   [ngClass]="{'ng-invalid ng-dirty': formControl.invalid && (formControl.dirty || formControl.touched)}"
                   class="p-inputtext float-input track-click-event"
            />
            <small [id]="item.name + '-help'" class="hint block"
                   *ngIf="item.hint && !(formControl.invalid && (formControl.dirty || formControl.touched))">
                {{item.hint}}
            </small>
        </span>
        <ng-container *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
            <small class="p-error block"
                   *ngFor="let errKey of errorKeys; trackBy: trackByFn;">{{item.validations[errKey]?.message}}</small>
        </ng-container>
    </div>
</ng-container>
<ng-container [formGroup]="group" *ngIf="!readonly && item && item.subType !== 'address'">
    <h5 class="field-heading" *ngIf="item.actionLabel">{{item.actionLabel}}</h5>
    <div class="field">
        <label [for]="item.name" class="p-float-label" *ngIf="item.label">{{ item.label }}</label>
        <span class="p-input-icon-right">
            <input #input
                   autocomplete="off"
                   [formControlName]="item.name"
                   [type]="item.format ? 'text' : subType"
                   [mask]="item.format || ''"
                   [patterns]="item.subType === 'ssn' ? patterns : null"
                   [hiddenInput]="maskInput"
                   [attr.aria-describedby]="item.name + '-help'"
                   [maxLength]="item.maxLength || maxLength"
                   [minLength]="item.minLength || minLength"
                   [min]="item.extendedParams && item.extendedParams['min'] || ''"
                   [max]="item.extendedParams && item.extendedParams['max'] || ''"
                   [step]="(item.extendedParams && item.extendedParams['step']) && 0.01"
                   [placeholder]="item.placeholder || item.name"
                   [prefix]="item.subType === 'currency'? '$ ': ''"
                   [suffix]="item.subType === 'percentage'? '%': ''"
                   [thousandSeparator]="item.subType === 'currency'? ',' : ''"
                   [id]="item.name"
                   (blur)="blur($event)"
                   [ngClass]="{'ng-invalid ng-dirty': formControl.invalid && (formControl.dirty || formControl.touched)}"
                   class="p-inputtext float-input"
            />
            <small [id]="item.name + '-help'" class="hint block"
                   *ngIf="item.hint && !(formControl.invalid && (formControl.dirty || formControl.touched))">
                {{item.hint}}
            </small>
            <ng-container *ngIf="item.subType === 'ssn'">
                <i tabindex="0" class="pi pi-eye" *ngIf="!maskInput" (click)="togglePassword()"
                   (keyup.enter)="togglePassword()"></i>
                <i tabindex="0" class="pi pi-eye-slash" *ngIf="maskInput" (click)="togglePassword()"
                   (keyup.enter)="togglePassword()"></i>
            </ng-container>
            <ng-container *ngIf="item.subType === 'password'">
                <i tabindex="0" class="pi pi-eye" *ngIf="hideInputs.includes(subType)" (click)="togglePassword()"
                   (keyup.enter)="togglePassword()"></i>
                <i tabindex="0" class="pi pi-eye-slash" *ngIf="subType === 'text'" (click)="togglePassword()"
                   (keyup.enter)="togglePassword()"></i>
            </ng-container>
        </span>
        <ng-container *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
            <small class="p-error block"
                   *ngFor="let errKey of errorKeys; trackBy: trackByFn;">{{item.validations[errKey]?.message}}</small>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="readonly">
    <div class="data-field">
        <div class="data-field-label">{{item.label}}</div>
        <div class="data-field-value">{{value}}</div>
    </div>
</ng-container>
