import {loadUserProfileContentSuccess} from './common.actions';
import {createReducer, on} from '@ngrx/store';
import {initialState} from './common.state';

const _commonReducer = createReducer(
    initialState,
    on(loadUserProfileContentSuccess, (state, action) => {
        return {
            ...state,
            userProfile: action.content
        };
    })
);

export function CommonReducer(state, action) {
    return _commonReducer(state, action);
}
