import {Injectable} from '@angular/core';
import {AppState} from '@app/app.state';
import {UtilService} from '@lib/services/util.service';
import {CMS} from '../../models/cms.model';
import {CmsService} from '../../services/cms.service';
import {
    CMS_ACTIONS,
    loadCmsPageContent,
    loadGlobalContent,
    loadGlobalContentSuccess
} from './cms.actions';
import {getGlobalContent} from './cms.selector';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {switchMap, map, withLatestFrom} from 'rxjs';

@Injectable()
export class CmsEffects {
    getGlobalContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadGlobalContent),
            withLatestFrom(this.store.select(getGlobalContent)),
            // map(() => loadGlobalContentSuccess({}))
            switchMap(() => {
                return this.cmsService.getGlobalContent(CMS.GLOBAL_CONTENT).pipe(
                    map((content) => {
                        const sortedOptions = UtilService.sort(content[1]);
                        const options = UtilService.arrayToObjectByKey(sortedOptions, 'groupBy', true);
                        const global = {...content[0], ...options};
                        return loadGlobalContentSuccess({content: global});
                    })
                );
            })
        );
    });

    getCmsPageContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCmsPageContent),
            switchMap((action) =>
                this.cmsService.getPathDetails(action.pathName, action.nestedParams)
                    .pipe(
                        map((page) => ({
                            type: CMS_ACTIONS.LOAD_CMS_PAGE_CONTENT_SUCCESS,
                            payload: page
                        }))
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private cmsService: CmsService,
        private store: Store<AppState>
    ) {
    }
}
