<ng-container [formGroup]="group" *ngIf="data['offers'].length > 1 && (offerPage$ | async) as page">
    <ng-container *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
        <lib-alerts
                [msgObj]="{ detail: item.validations[errKey]?.message, severity: ALERT_TYPES.ERROR, summary: ALERT_TYPES.ERROR}"
                *ngFor="let errKey of errorKeys; trackBy: trackByFn;"></lib-alerts>
    </ng-container>
    <div class="offers" *ngIf="(offerPage$ | async) as offerPage">
        <div class="offers__heading">
            <h1>{{page.title}}</h1>
            <h3>{{page.kicker | stringReplace : {'{amount}': '$' + maxOfferAmount} }}</h3>
        </div>
        <div class="offers__container" [ngClass]="{'no-options': !item.options?.length}">
            <div class="offers__slider">
                <app-offer-slider [steps]="lOffers" [title]="offerPage.amountNeededHeading"
                                  (change)="sliderSelected($event)"
                                  [value]="selectedOffer?.loanAmount"></app-offer-slider>
            </div>
            <div class="offers__payment-types">
                <app-offer-payment-types [formGroup]="group" [item]="item" [types]="paymentTypes"
                                         [cmsPage]="offerPage" [offers]="lOffers"
                                         [selectedOffer]="selectedOffer"
                                         [amount]="selectedAmount"
                                         (onChange)="chooseOffer($event)"></app-offer-payment-types>
            </div>
        </div>
        <div class="offers__detail">
            <app-offer-detail [offer]="selectedOffer || defaultOffer" [cmsPage]="offerPage" [noOffer]="noOffer"
                              [pageGetSchema]="pageGetSchema" [item]="item"></app-offer-detail>
        </div>
    </div>
</ng-container>

<ng-container [formGroup]="group" *ngIf="data['offers'].length === 1 && (page$ | async) as page">
    <markdown>
        {{page.subDescription | stringReplace : {
        '{amount}': (maxOfferAmount | currency),
        '{numberOfPayments}': data.offers[0]?.numberOfPayments,
        '{averagePaymentAmount}': (data.offers[0]?.averagePaymentAmount | currency),
        '{payFrequency}': pageGetSchema['payFrequency'][data.offers[0].payFrequency || 0].value.toLowerCase()
    } }}
    </markdown>
</ng-container>
