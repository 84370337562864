import {
    ComponentRef,
    Directive,
    Input,
    OnDestroy,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import {PAGE_COMPONENT_TYPES} from '@app/core/models/core.model';
import {BasePageComponent} from '@lib/components/base-page/base-page.component';
import {ICmsPage} from '@lib/cms/models/cms.model';

@Directive({
    selector: '[appDynamicPageRender]'
})
export class DynamicPageRenderDirective implements OnInit, OnDestroy {
    @Input() cmsPage: ICmsPage;
    @Input() data: any;
    @Input() pageGetSchema: any;
    @Input() formSubmitSchema: any;

    componentRef: ComponentRef<BasePageComponent>;

    constructor(public viewContainerRef: ViewContainerRef) {
    }

    ngOnInit(): void {
        const types = {...PAGE_COMPONENT_TYPES};
        this.viewContainerRef.clear();
        if (types[this.cmsPage.name]) {
            this.componentRef = this.viewContainerRef.createComponent(types[this.cmsPage.name]);
            this.componentRef.instance.cmsPage = this.cmsPage;
            this.componentRef.instance.data = this.data;
            this.componentRef.instance.pageGetSchema = this.pageGetSchema;
            this.componentRef.instance.formSubmitSchema = this.formSubmitSchema;
        }
    }

    ngOnDestroy(): void {
    }
}
