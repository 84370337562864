import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UnsubscribeOnDestroyAdapter} from '@lib/adapters/unsub-on-destroy.adapter';
import {CMS_PAGE_ROUTES, ICmsPage} from '@lib/cms/models/cms.model';
import {clearPageContent, loadCmsPageContent, loadCmsPageContentSuccess} from '@lib/cms/store/cms/cms.actions';
import {ALERT_TYPES, HTTP_METHODS, IAlert, IUrlContext, MESSAGES} from '@lib/models/lib.model';
import {IApplicationResponse, IPage, IPageResponse} from '@lib/models/page.model';
import {AppApiService} from '@lib/services/app-api.service';
import {FlowService} from '@lib/services/flow/flow.service';
import {MessageService} from '@lib/services/message.service';
import {UserService} from '@lib/services/user.service';
import {UtilService} from '@lib/services/util.service';
import {clearAlertMessage, setAlertMessage, setCurrentPage} from '@lib/store/shared.actions';
import {Store} from '@ngrx/store';
import {forkJoin, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CmsService} from "@lib/cms/services/cms.service";

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
    cmsPage: ICmsPage;
    currentPage: IPage;
    pageGetSchema: any;
    formSubmitSchema: any;
    loading = false;
    fg: UntypedFormGroup = null;
    urlContext: IUrlContext;
    pageLoading = true;
    sub: Subscription;
    polling = false;
    
    constructor(
        private router: Router,
        private store$: Store<any>,
        private route: ActivatedRoute,
        private appApiService: AppApiService,
        private user: UserService,
        private ms: MessageService,
        private fs: FlowService,
        private fb: UntypedFormBuilder,
        private cs: CmsService) {
        super();
        this.route.params.subscribe(() => {
            this.urlContext = {
                id: this.route.snapshot.paramMap.get('id'),
                name: this.route.snapshot.paramMap.get('page') || this.route.snapshot.paramMap.get('id'),
                itemId: this.route.snapshot.paramMap.get('itemId')
            };
            this.pageLoading = true;
            sessionStorage.setItem('launchParams', JSON.stringify(this.urlContext));
            this.resetPage();
            this.initPage();

        });

        this.sub = this.ms.message.subscribe(obj => {
            if (obj.type === MESSAGES.PAGE_SUBMIT) {
                this.onSubmit(obj.data);
            }
            else if (obj.type === MESSAGES.PAGE_UPDATE) {
                this.getPageData();
            }
            else if (obj.type === MESSAGES.REDIRECT) {
                this.redirect(obj);
            }   
        });
    }

    get page() {
        return this.currentPage;
    }

    get verb() {
        return this.page?.methods?.filter(m => {
            return m === HTTP_METHODS.PUT || m === HTTP_METHODS.PATCH || m === HTTP_METHODS.POST;
        })[0];
    }

    get isAppStart() {
        return this.urlContext.name === CMS_PAGE_ROUTES.GET_STARTED;
    }

    ngOnInit() {
    }

    initPage(): void {
        this.pageLoading = true;
        this.store$.dispatch(loadCmsPageContent({pathName: this.urlContext.name}));
        if (this.isAppStart) {
            this.currentPage = {data: {}, methods: [HTTP_METHODS.POST]};
            this.loadApplication();
        } else if (this.urlContext.id !== CMS_PAGE_ROUTES.STATIC_PAGES) {
            this.getPageData();
        } else {
            this.pageLoading = false;
        }
    }

    getPageData() {
        this.appApiService.getPageData(this.urlContext.id, this.urlContext.name, this.urlContext.itemId)
        .subscribe(
            {
                next: (pageResponse: IPageResponse) => {
                    const currPage = pageResponse.pages[this.urlContext.name];
                    this.currentPage = {data: pageResponse.data, ...currPage};
                    if (pageResponse.data && pageResponse.data['url']) {
                        this.pageLoading = true;
                        this.currentPage = null;
                        this.loading = false;
                        this.store$.dispatch(setCurrentPage(null));
                        document.location.href = pageResponse.data['url'];
                    } else {
                        this.loading = false;
                        this.store$.dispatch(setCurrentPage({currentPage: pageResponse}));
                        this.loadApplication();
                    }
                },
                error: (err) => {
                    if (err?.includes('404')) {
                        this.router.navigate([CMS_PAGE_ROUTES.PAGE_404]);
                    } else {
                        this.store$.dispatch(setAlertMessage({
                            detail: err,
                            severity: ALERT_TYPES.ERROR,
                            summary: ALERT_TYPES.ERROR
                        } as IAlert));
                    }
                }
            });

    }

    /**
     * GET CMS page data to prefill
     * GET schema of PUT / PATCH - A subset of page data
     * Missing data points in PATCH are disabled
     * 1: CMS content
     * 2: schema
     * 3: pageGetSchema
     * @param pageService
     * @param page
     */
    loadApplication(): boolean {
        this.cs.getPathDetails(this.urlContext.name)
            .subscribe((page: ICmsPage) => {
                this.onPageLoadCms(page);
            });

        return true;
    }

    /**
     * On Page load, pull page CMS,Schema GET call based on HTTP methods
     *
     * @param page
     */
    onPageLoadCms(page: ICmsPage) {
        const orchestrator = [] as any;
        const uc = this.urlContext;
        if (page.extendedParams?.pollPage) {
            setTimeout(this.pollPage.bind(this), page.extendedParams.pollPage);
        }
        if (this.verb && this.verb !== HTTP_METHODS.GET && !this.isAppStart) {
            orchestrator.push(this.appApiService.pageSchema(uc.name, this.verb, uc.itemId));
        } else if (this.isAppStart) {
            orchestrator.push(this.appApiService.pageSchema('', this.verb, uc.itemId));
        }

        if (!this.isAppStart) {
            orchestrator.push(this.appApiService.pageSchema(uc.name, HTTP_METHODS.GET, uc.itemId));
        }
        forkJoin(orchestrator).pipe(takeUntil(this.unsubscribe)).subscribe((results: any[]) => {
            if (page.name === this.urlContext.name) {
                this.fg = this.getFormGroup();
                this.cmsPage = Object.assign({}, page);
                this.formSubmitSchema = results[0];
                this.pageGetSchema = results[1] && this.processPageGetSchema(results[1]);
                this.pageLoading = false;
            }
        });
    }

    redirect(msg : any) {
        this.router.navigate([this.urlContext.id, msg.page]);
        this.ms.setMessage({type: ''});
    }

    triggerMessage() {
        this.ms.setMessage(this.cmsPage.extendedParams.message);
    }

    pollPage() {
        this.polling = true;
        const pollPage = this.cmsPage.extendedParams?.pollPage;
        this.getPageData();
        if (pollPage) {
            setTimeout(this.pollPage.bind(this), pollPage);
        }
        else {
            this.polling = false;
        }
    }

    /**
     * Redirect to page if there is a route in cms
     * if readonly (review page) - skip form and submit get data
     * primaryCta is a custom page submit - get started
     * @param pageService
     * @param cta
     */
    onSubmit(data?: any): boolean {
        if (this.loading && !this.fg) {
            return false;
        }

        const isReadonlyPage = this.cmsPage?.type === 'readonly';
        if (this.fg.invalid && !isReadonlyPage) {
            this.fg.markAllAsTouched();
            this.fg.updateValueAndValidity();

            // Any subform like password confirm gets called
            this.ms.setMessage({type: MESSAGES.SUBFORM_TOUCHED, data: {}});
            return false;
        }

        this.loading = true;
        if (this.cmsPage?.primaryCta) {
            this[this.cmsPage?.primaryCta]();
            return false;
        }

        const payload = data || (isReadonlyPage ? this.currentPage.data : this.fg.value);
        const uc = this.urlContext;
        const action = uc.itemId ? `${uc.name}/${uc.itemId}` : uc.name;
        this.appApiService.submitPage(this.urlContext.id, action, payload, this.verb)
            .subscribe((response: IPageResponse) => {
                this.goToNextPage(response);
                this.loading = false;
            }, () => {
                this.loading = false;
            });
        return true;
    }

    processPageGetSchema(pageGetSchema: any): any {
        const pgs = {};
        Object.keys(pageGetSchema).map((key) => {
            switch (pageGetSchema[key].type) {
                case 'select':
                    pgs[key] = UtilService.arrayToObjectByKey(pageGetSchema[key].option.values, 'id');
                    break;
                default:
                    pgs[key] = pageGetSchema[key];
            }
        });

        return pgs;
    }

    /**
     * Custom page submit
     */
    getStarted() {
        this.appApiService.postApplication(this.fg.value).subscribe((response: IApplicationResponse) => {
            this.loading = false;
            this.user.logIn({
                access_token: response.entity.accessToken,
                id_token: '',
                refresh_token: ''
            });
            this.fs.persistApplicationData(response);
        }, () => {
            this.loading = false;
        });
    }

    /**
     * Custom route submit
     */
    goHome() {
        this.router.navigate(['/']);
    }

    goGetStarted() {
        this.router.navigate([CMS_PAGE_ROUTES.GET_STARTED]);
    }

    goAccountOverview() {
        this.router.navigate([this.urlContext.id, CMS_PAGE_ROUTES.ACCOUNT_OVERVIEW]);
    }

    goToNextPage(response: IPageResponse) {
        if (response.data && response.data['accessToken']) {
            this.user.logIn({
                access_token: response.data['accessToken'],
                id_token: response.data['idToken'],
                refresh_token: response.data['refresh_token']
            });
        }
        const nextPage: any = UtilService.nextPage(response.pages);
        this.currentPage = nextPage.data;
        this.resetPage();
        this.router.navigate([this.urlContext.id, nextPage.page]);
    }

    resetPage() {
        if (this.page) {
            this.page.data = null;
        }
        this.cmsPage = null;
        this.currentPage = null;
        this.formSubmitSchema = null;
        this.fg = null;
        this.store$.dispatch(loadCmsPageContentSuccess({}));
        this.store$.dispatch(clearAlertMessage());
        this.store$.dispatch(clearPageContent());
    }

    getFormGroup() {
        return this.fb.group({}) as UntypedFormGroup;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.sub.unsubscribe();
        this.cmsPage = {} as ICmsPage;
    }

    protected readonly Object = Object;
}