import { Component, OnInit } from '@angular/core';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {ICmsPage } from '@lib/cms/models/cms.model';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { loadCmsPageContent, loadCmsPageContentSuccess } from '@lib/cms/store/cms/cms.actions';
import { getCmsPageContent } from '@lib/cms/store/cms/cms.selector';
import { Observable } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from '@lib/adapters/unsub-on-destroy.adapter';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  page$: Observable<ICmsPage>;

  constructor(private store$: Store, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.store$.dispatch(loadCmsPageContent({ pathName: APP_PAGE_ROUTES.FORBIDDEN }));
    this.page$ = this.store$.select(getCmsPageContent);
  }

  onSubmit(cta: string) {
    this.router.navigate([cta || '/']);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.dispatch(loadCmsPageContentSuccess({}));
  }

}
