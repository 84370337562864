import {Component, OnInit} from '@angular/core';
import {ICmsPage} from '@lib/cms/models/cms.model';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import {getCredentialsState, getCurrentPageData} from '@lib/store/shared.selector';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Component({
    selector: 'lib-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
    cmsPage$: Observable<ICmsPage>;
    pageData$: Observable<any>;
    isAuthenticated$: Observable<any>;

    constructor(private store$: Store) {
    }

    ngOnInit(): void {
        this.cmsPage$ = this.store$.select(getCmsPageContent);
        this.pageData$ = this.store$.select(getCurrentPageData);
        this.isAuthenticated$ = this.store$.select(getCredentialsState);
    }

}
