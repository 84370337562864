import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ILoanPaymentMethod, IPaymentMethod} from '@app/core/models/core.model';
import {IAppDataState} from '@app/core/store/app-data.state';
import {IApplicationResponse} from '@lib/models/page.model';
import {AppApiService} from '@lib/services/app-api.service';
import {FlowService} from '@lib/services/flow/flow.service';
import {UtilService} from '@lib/services/util.service';
import {Store} from '@ngrx/store';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    constructor(
        private store$: Store<IAppDataState>,
        private router: Router,
        private apiAppService: AppApiService,
        private flowService: FlowService
    ) {
    }

    /**
     * if allowed route - load same route
     * if not allowed route - redirect to lastPage
     * if no route - redirect to lastPage
     * @param pages
     * @param currentRoute
     */
    navToCurrentRoute(appResponse: IApplicationResponse, currentRoute: string) {
        const allowedUrls = Object.keys(appResponse.pages);
        if (!currentRoute || !allowedUrls.includes(currentRoute)) {
            const pageObj: any = UtilService.nextPage(appResponse.pages);
            this.router.navigate([appResponse.entity?.id, pageObj.page]);
        }
    }

    mapPaymentMethodOptions(lmps: ILoanPaymentMethod[]): IPaymentMethod[] {
        const pms: IPaymentMethod[] = [];
        lmps.map((lmp: ILoanPaymentMethod) => {
            lmp.paymentMethods.filter(p => p.authorized).map(o => {
                pms.push({
                    ...o,
                    key: 'METHOD-' + o.id,
                    value: this.getPaymentMethodValue(o, lmp)
                });
            });
        });

        return pms;
    }

    getPaymentMethodValue(pm, lmp) {
        const accLast4 = pm.accountNumber.slice(-4);
        return `${pm.name} ( ${pm.paymentMethodType?.display} : ${accLast4})`;
    }

    getCurrentApplication(appId: string, pageName: string, appExists?: BehaviorSubject<boolean>) {
        this.apiAppService.getApplication(appId)
            .subscribe((response: IApplicationResponse) => {
                if (appExists) {
                    appExists.next(true);
                }
                this.flowService.persistApplicationData(response, pageName);
            });
    }
}
