import {createAction, props} from '@ngrx/store';

export enum ACTIONS {
    LOAD_OFFERS_CONTENT = '[CMS Action] LOAD offers content',
    LOAD_OFFERS_CONTENT_SUCCESS = '[CMS Action] LOAD offers content success',
    LOAD_DOCUMENTS_CONTENT = '[CMS Action] LOAD documents content',
    LOAD_DOCUMENTS_CONTENT_SUCCESS = '[CMS Action] LOAD documents content success'
}

export const loadOffersContent = createAction(ACTIONS.LOAD_OFFERS_CONTENT);

export const loadOffersContentSuccess = createAction(
    ACTIONS.LOAD_OFFERS_CONTENT_SUCCESS,
    props<any>()
);

export const loadDocumentsContent = createAction(ACTIONS.LOAD_DOCUMENTS_CONTENT);

export const loadDocumentsContentSuccess = createAction(
    ACTIONS.LOAD_DOCUMENTS_CONTENT_SUCCESS,
    props<any>()
);
