import {Directive, DoCheck} from '@angular/core';
import {NgxMaskService, NgxMaskPipe} from 'ngx-mask';
import {Calendar} from 'primeng/calendar';

@Directive({
    selector: '[dateMask]',
    providers: [NgxMaskPipe]
})
export class DateMaskDirective implements DoCheck {
    constructor(private primeCalendar: Calendar, private maskPipeService: NgxMaskService) {
    }

    ngAfterViewInit() {
    }

    getHTMLInput(): HTMLInputElement {
        return this.primeCalendar.el.nativeElement.querySelector('input');
    }

    ngDoCheck(): void {
        const htmlInput = this.getHTMLInput();
        if (htmlInput) {
            htmlInput.value = this.maskPipeService.applyMask(htmlInput.value, '00/00/0000');
        }
    }
}
