<ng-container [formGroup]="group" *ngIf="readonly">
    <p style="{{item.style}}">
        <ng-container *ngIf="!item.subType && item.extendedParams">
            {{item.extendedParams[data[item.name]]}}
        </ng-container>
        <ng-container *ngIf="item.subType === 'currency'">
            {{item.label | stringReplace : {'{data}': (data[item.name] | currency)} }}
        </ng-container>
    </p>
</ng-container>
