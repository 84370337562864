<ng-container [formGroup]="group">
    <div class="field-checkbox">
        <p-inputSwitch [formControl]="$any(group).controls[item.name]" [id]="item.name"
                       [disabled]="readonly"></p-inputSwitch>
    </div>
    <ng-container *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
        <small class="p-error block"
               *ngFor="let errKey of errorKeys; trackBy: trackByFn;">{{item.validations[errKey]?.message}}</small>
    </ng-container>
</ng-container>
