import {IUser} from '../../../services/user.service';

export interface ICommonState {
  userProfile: IUser;
}

export const initialState: ICommonState = {
  userProfile: {
    given_name: ''
  }
};
