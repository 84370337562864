import { createAction, props } from '@ngrx/store';

export enum COMMON_ACTIONS {
  LOAD_USER_PROFILE_CONTENT = '[COMMON Action] LOAD user profile content',
  LOAD_USER_PROFILE_CONTENT_SUCCESS = '[COMMON Action] On LOAD user profile content success',
}

export const loadUserProfileContent = createAction(COMMON_ACTIONS.LOAD_USER_PROFILE_CONTENT);

export const loadUserProfileContentSuccess = createAction(
    COMMON_ACTIONS.LOAD_USER_PROFILE_CONTENT_SUCCESS,
    props<any>()
);
