import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, InjectionToken, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {APP_ROUTES} from '@app/app.routes';
import {appReducer} from '@app/app.state';
import {CoreModule} from '@app/core/core.module';
import {AppDataEffects} from '@app/core/store/app-data.effects';
import {environment} from '@environment/environment';
import {ErrorHandlerInterceptorService} from '@lib/interceptors/error-handler-interceptor.service';
import {TokenInterceptorService} from '@lib/interceptors/token-interceptor.service';
import {LibModule} from '@lib/lib.module';
import {CustomSerializer} from '@lib/router/custom-serializer';
import {AuthProviderClientIdService} from '@lib/services/auth-provider-client-id.service';
import {ConfigService} from '@lib/services/config.service';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AppComponent} from './app.component';

export function loadConfig(configService: ConfigService, configDeps: (() => void)[]) {
    return () => configService.loadConfigurations(configDeps);
}

export function getClientName(apcs: AuthProviderClientIdService) {
    return () => apcs.getClientName('keycloakClientId');
}

const ConfigDeps = new InjectionToken<(() => void)[]>('configDeps');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        LibModule,
        StoreModule.forRoot(appReducer),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer
        }),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
        HttpClientModule,
        EffectsModule.forFeature([AppDataEffects]),
        RouterModule.forRoot(APP_ROUTES, {scrollPositionRestoration: 'enabled'}),
        RouterModule.forChild([
            {
                path: '',
                loadChildren: () => import('./core/core.module').then(m => m.CoreModule)
            },
            {
                path: '',
                loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule)
            }
        ])
    ],
    exports: [CoreModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [ConfigService, ConfigDeps],
            multi: true
        },
        {
            provide: ConfigDeps,
            useFactory: (apcs: AuthProviderClientIdService) => {
                return [getClientName(apcs)];
            },
            deps: [AuthProviderClientIdService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
