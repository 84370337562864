import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from '@lib/services/config.service';
import {BaseFormComponent} from '@lib/components/form/base-component/base-form.component';
import {ModalService} from "@lib/services/modal.service";
import { AssetLoadService } from '@lib/services/asset-load.service';
import { MessageService } from '@lib/services/message.service';
import { MESSAGES } from '@lib/models/lib.model';

@Component({
    selector: 'app-payroll',
    templateUrl: './payroll.component.html',
    styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent extends BaseFormComponent implements OnInit, OnDestroy {
    constructor(public assetLoadService: AssetLoadService,
        private ms: ModalService,
        private cs: ConfigService,
        private msg : MessageService
    ) {
        super();
    }
    scriptLoaded: boolean = false;
    setupRequestId: string = "";
    launched: boolean = false;
    modal : any;
    errored: boolean = false;

    ngOnInit(): void {
        this.errored = false;
        this.setupRequestId = this.data.setupId;
        
        if (this.item.extendedParams?.launchType === "onClick") {
            this.scriptLoaded = true;
            this.msg.message.subscribe(v =>
                {
                    if (v?.type == MESSAGES.LAUNCH_PAYROLL && !this.launched) {
                        this.msg.setMessage({ type: ''});
                        this.loadScript(this.initPayroll.bind(this));
                        //this.msg.clearMessage();
                    }
                });
        }
        else
        {
            this.loadScript(this.initPayroll.bind(this));
        }
    }

    ngOnDestroy() {
        this.msg.setMessage({ type: '' });
        if (this.modal) {
            //this.modal.close();
            this.modal.destroy();
        }
        super.ngOnDestroy();
    }

    loadScript(callback) {
        const scriptUrl = this.cs.get('payrollScript');
        this.assetLoadService.load({name: 'payroll', src: scriptUrl}).then(script => {
            if ((script[0] as any).loaded) {
                this.scriptLoaded = true;
                callback();
            }
        });
    }

    onClose() {
        this.launched = false;
        if (this.errored && this.item.extendedParams?.onError) {
            this.errored = false;
            this.msg.setMessage(this.item.extendedParams.onError);
        }
        else {
            setTimeout(this.updatePage.bind(this), 2000);
        }
    }
    onComplete() {
        this.launched = false;
        setTimeout(this.updatePage.bind(this), 2000);
    }

    onDepositSwitchError() {
    }

    onConnectError() {
        this.errored = true;
    }    

    onError() {
        //TODO: what to do when an error occurs?   
        this.errored = true;
    }

    updatePage() {
        this.msg.setMessage({ type: MESSAGES.PAGE_UPDATE });
    }


    initPayroll() {
        if (this.launched) return;
        this.launched = true;
        this.errored = false;
          this.modal = Catalyst.createPayroll({
            setupId: this.setupRequestId,
            onClose: () => { this.onClose(); },
            onError: () =>  {this.onError(); }, 
            onComplete: () => { this.onComplete(); },
            onConnectError: () => {this.onConnectError()},
            onDepositSwitchError: () => { this.onDepositSwitchError();}
        });
    }


}
