import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {UnsubscribeOnDestroyAdapter} from '@lib/adapters/unsub-on-destroy.adapter';
import {ICmsPage} from '@lib/cms/models/cms.model';
import {loadCmsPageContent, loadCmsPageContentSuccess} from '@lib/cms/store/cms/cms.actions';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

    page$: Observable<ICmsPage>;

    constructor(private store$: Store, private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.store$.dispatch(loadCmsPageContent({pathName: APP_PAGE_ROUTES.NOT_FOUND}));
        this.page$ = this.store$.select(getCmsPageContent);
    }

    onSubmit(cta: string) {
        this.router.navigate([cta || '/']);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.store$.dispatch(loadCmsPageContentSuccess({}));
    }

    getDescription(page: ICmsPage) : string {
        return page.description;
    }
}
