import {Routes} from '@angular/router';
import {AuthenticateComponent} from '@app/core/components/authenticate/authenticate.component';
import {LoginComponent} from '@app/core/components/login/login.component';
import {LogoutComponent} from '@app/core/components/logout/logout.component';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {AccountDocumentsComponent} from '@app/core/pages/account-documents/account-documents.component';
import {ForbiddenComponent} from '@app/core/pages/auxilliary/forbidden/forbidden.component';
import {AccountOverviewComponent} from '@app/core/pages/account-overview/account-overview.component';
import {NotFoundComponent} from '@app/core/pages/not-found/not-found.component';
import {OneTimePaymentsComponent} from '@app/core/pages/one-time-payments/one-time-payments.component';
import {PaymentHistoryComponent} from '@app/core/pages/payment-history/payment-history.component';
import {PaymentMethodsComponent} from '@app/core/pages/payment-methods/payment-methods.component';
import {PaymentComponent} from '@app/core/pages/payment/payment.component';
import {ErrorPageComponent} from './core/pages/auxilliary/error-page/error-page.component';
import {PageComponent} from '../app-tools/flow/page/page.component';
import {LandingPageComponent} from './marketing/landing-page/landing-page.component';
import {AccountDetailsComponent} from '@app/core/pages/account-details/account-details.component';

export const APP_ROUTES: Routes = [
    {
        path: '',
        component: LandingPageComponent
    },
    {
        path: APP_PAGE_ROUTES.AUTHENTICATE,
        component: AuthenticateComponent
    },
    {
        path: APP_PAGE_ROUTES.LOGIN,
        component: LoginComponent
    },
    {
        path: APP_PAGE_ROUTES.LOGOUT,
        component: LogoutComponent
    },
    {
        path: APP_PAGE_ROUTES.PAGE_404,
        component: ErrorPageComponent
    },
    {
        path: APP_PAGE_ROUTES.FORBIDDEN,
        component: ForbiddenComponent
    },
    {
        path: APP_PAGE_ROUTES.NOT_FOUND,
        component: NotFoundComponent
    },
    {
        path: 'account-details',
        component: AccountDetailsComponent
    },
    {
        path: 'account-overview',
        component: AccountOverviewComponent
    },
    {
        path: 'payment-activity',
        component: PaymentHistoryComponent
    },
    {
        path: 'payment-methods',
        component: PaymentMethodsComponent
    },
    {
        path: 'payment',
        component: PaymentComponent
    },
    {
        path: 'scheduled-payments',
        component: OneTimePaymentsComponent
    },
    {
        path: 'documents',
        component: AccountDocumentsComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: ':id',
        children: [
            {
                path: '',
                component: PageComponent
            },
            {
                path: ':page',
                children: [
                    {
                        path: '',
                        component: PageComponent
                    },
                    {
                        path: ':itemId',
                        component: PageComponent
                    }
                ]
            }
        ]
    },
    {
        path: '**',
        component: ErrorPageComponent
    }
];
