import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DynamicBaseComponent} from '../../dynamic-base-component/dynamic-base.component';

@Component({
    selector: 'lib-data-table-cell',
    templateUrl: './data-table-cell.component.html',
    styleUrls: ['./data-table-cell.component.scss']
})
export class DataTableCellComponent extends DynamicBaseComponent implements OnInit, OnChanges, OnDestroy {
    showMore = true;
    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    moreOrLess() {
        this.showMore = !this.showMore;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
