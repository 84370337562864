import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOffer, IOfferPage } from '@app/core/components/offers/offers.component';
import { IFormField } from '@lib/cms/models/cms.model';
import { UtilService } from '@lib/services/util.service';
import { EntryFields } from 'contentful';
import Object = EntryFields.Object;

@Component({
  selector: 'app-offer-payment-types',
  templateUrl: './offer-payment-types.component.html',
  styleUrls: ['./offer-payment-types.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => OfferPaymentTypesComponent)
    }
  ]
})
export class OfferPaymentTypesComponent implements OnInit, OnChanges {
  @Input() cmsPage: IOfferPage;
  @Input() types: number[];
  @Input() offers: IOffer[];
  @Input() selectedOffer: IOffer;
  @Input() amount: number;
  @Input() formGroup: UntypedFormGroup;
  @Input() item: IFormField;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  filteredOffersObj: {
    [key: number]: IOffer;
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  /**
   * First active order should always be selected if no offer is chosen
   * Chosen offer payment type should be preselected (though offer not available), offer detail should remain old offer
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['amount']?.currentValue && changes['amount'].previousValue !== changes['amount'].currentValue || changes['cmsPage']) {
      setTimeout(() => {
        this.buildOffersByAmount();
        this.buildPaymentTypes();
      });
    }
  }

  filterOffers() {
    let o = this.offers.filter((offer: IOffer) => offer.loanAmount === this.amount);
    o = UtilService.sort(o, 'paymentType');
    return o;
  }

  buildOffersByAmount() {
    const o = this.filterOffers();
    this.filteredOffersObj = UtilService.arrayToObjectByKey(o, 'paymentType');
  }

  /**
   * Build paymentTypes by available(enable) & unavailable offers(disable)
   * Radio button label is key
   * Only available product lines should enable when none selected by default
   * On throw error in detail section when product not available with selected paymentType
   */
  buildPaymentTypes() {
    this.item.options = [];
    const types = Object.assign([], this.cmsPage.paymentTypes);
    types.sort((a, b) => a.order - b.order);
    types.map((cmsPt) => {
      const option = {} as any;
      option.key = cmsPt.title;
      option.description = cmsPt.description;
      option.value = cmsPt.paymentTypeId;
      option['data-id'] = this.filteredOffersObj[cmsPt.paymentTypeId]?.offerId;
      option.disabled = !this.filteredOffersObj[cmsPt.paymentTypeId];
      option.checked = this.isOptionChecked(option, cmsPt);
      this.item.options.push(option);
    });
  }

  isOptionChecked(option, cmsPt): boolean {
    if (this.selectedOffer) {
      const offerExists = !!this.filteredOffersObj[this.selectedOffer?.paymentType];
      this.chooseOffer(this.selectedOffer?.paymentType, offerExists, false);
      return cmsPt.paymentTypeId == this.selectedOffer?.paymentType;
    } else {
      const pt = this.filterOffers()[0].paymentType;
      this.chooseOffer(pt, true, true);
      return cmsPt.paymentTypeId == pt;
    }
  }

  chooseOffer(paymentType, offerExists = true, isDefault?: boolean) {
    this.onChange.emit({ offerId: this.filteredOffersObj[paymentType]?.offerId, isDefault, offerExists });
  }

  writeValue() {
  }

  registerOnChange() {
  }

  registerOnTouched() {
  }

}
