import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output, Renderer2,
    ViewChild
} from '@angular/core';
import {BaseFormComponent} from '../base-component/base-form.component';
import {AddressService} from '@lib/services/lookup/address.service';

@Component({
    selector: 'lib-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent extends BaseFormComponent implements OnInit, AfterViewInit {
    @ViewChild('input') el: ElementRef;
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    minLength = 0;
    maxLength = 125;
    hideInputs = ['password', 'ssn'];
    subType = '';
    maskInput = false;
    patterns = {
        D: {
            pattern: new RegExp('\\d'),
            symbol: '*'
        }
    };
    tempData: any;

    constructor(private readonly cdRef: ChangeDetectorRef, private address: AddressService, private renderer: Renderer2) {
        super();
    }

    ngOnInit(): void {
        this.tempData = Object.assign({}, this.data);
        this.subType = this.item?.subType;
        this.maskInput = this.subType === 'ssn' ? true : false;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.formatCurrency();
            if (this.data) {
                this.setOrPrefill(null, 'prefill');
            }
        });
    }

    /**
     * Call set or prefill method - as only address, no condition check
     * @param e
     * @param action
     */
    setOrPrefill(e: any, action: string) {
        switch (this.item.subType) {
            case 'address' :
                this[`${action}Address`](e);
                break;
        }
    }

    prefillAddress() {
        this.el.nativeElement.value = this.address.getDisplayOption(this.data[this.item.name]);
        this.formControl.markAsTouched();
        this.formControl.updateValueAndValidity();
    }

    setAddress(e) {
        const value = this.address.getInputAddress(e.target.value);
        if (!value) {
            this.formControl.setValue(null);
            this.formControl.setErrors({required: true});
        } else {
            const d = this.tempData[this.item.name];
            if (d?.type) {
                value.type = d.type;
            }
            if (d?.addressId) {
                value.addressId = d.addressId;
            }
            this.formControl.setValue(value);
        }
        this.formControl.markAsTouched();
        this.formControl.updateValueAndValidity();
    }

    togglePassword() {
        this.maskInput = !this.maskInput;
        this.subType = (this.subType !== 'text') ? 'text' : this.item.subType;
        const val = this.formControl.value;
        setTimeout(() => this.formControl.setValue(val));
    }

    blur(e) {
        if (e.target.value) {
            e.target.value = e.target.value.trim();
            if (!this.item.subType) {
                this.formControl.setValue(e.target.value);
            }
            this.formatCurrency(e);
            this.formControl.updateValueAndValidity();
        }
        if (this.onBlur) {
            this.onBlur.emit(e);
        }
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    formatCurrency(e?: any) {
        if (this.item.subType === 'currency') {
            const fAmount = Number.parseFloat(this.formControl.value || 0).toFixed(2);
            this.formControl.setValue(parseFloat(fAmount));
            setTimeout(() => {
                if (e) {
                    e.target.value = '$ ' + fAmount;
                } else {
                    this.el.nativeElement.value = '$ ' + fAmount;
                }
            });
        }
    }
}
