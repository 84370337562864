<ng-container [formGroup]="tempGroup" *ngIf="!readonly">
    <div class="field">
        <label [for]="item.name" class="p-float-label">{{ item.label }}</label>
        <p-dropdown
                [options]="item.option.values"
                [placeholder]="item.placeholder || item.label || item.name"
                [showClear]="false"
                [optionLabel]="item.option?.filterBy"
                [optionValue]="item.option?.return"
                (onChange)="onSelectEvent($event, true)"
                (onBlur)="dropdownBlur($event)"
                (onClear)="onClear($event)"
                formControlName="tempControl"
                [ngClass]="{'ng-invalid ng-dirty': formControl.invalid && (formControl.dirty || formControl.touched)}"
                *ngIf="!item.option?.isFilterable"
                [appendTo]="item.extendedParams?.appendTo || 'body'"
                [disabled]="formControl.disabled"
                [group]="isGroup"
                [panelStyleClass]="panelStyleClass"
                id="{{item.name}}"
        >
        </p-dropdown>
        <p-autoComplete
                [suggestions]="item.option.values"
                [field]="item.option.filterBy"
                [placeholder]="item.placeholder || item.label"
                [delay]="item.option.debounceTimeMs || 300"
                (completeMethod)="filterData($event)"
                (onFocus)="filterData(formControl.value)"
                (onBlur)="onBlurEvent($event)"
                (onSelect)="onSelectEvent($event, false)"
                (onClear)="onClear($event)"
                (onKeyUp)="onKeyUp($event)"
                [forceSelection]="!this.allowCustom"
                [showEmptyMessage]="false"
                [minLength]="item.option.minSearchLength"
                [appendTo]="item.extendedParams?.appendTo || 'body'"
                formControlName="tempControl"
                [ngClass]="{'ng-invalid ng-dirty': formControl.invalid && (formControl.dirty || formControl.touched)}"
                *ngIf="item.option?.isFilterable"
                [panelStyleClass]="panelStyleClass"
                id="{{item.name}}"
        ></p-autoComplete>
        <ng-container *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
            <small class="p-error block"
                   *ngFor="let errKey of errorKeys; trackBy: trackByFn;">{{item.validations[errKey]?.message}}</small>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="readonly">
    <div class="data-field">
        <div class="data-field-label">{{item.label}}</div>
        <div class="data-field-value">{{value}}</div>
    </div>
</ng-container>
