import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilService} from '../../services/util.service';

@Component({
    selector: 'lib-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
    @Input() label: string;
    @Input() groupName: string;
    @Input() documentType: string;
    @Input() disabled: boolean;
    @Input() params: any;
    @Input() accept: string;
    @Input() klass: string;
    @Input() files: any[] = [];
    uploadedFiles: any[] = [];
    @Output() select: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
        this.uploadedFiles = [];
    }

    onSelect(e) {
        this.uploadedFiles = [];
        for (let file of e.currentFiles) {
            file.customName = `${this.groupName}_${file.name}`;
            file.documentName = this.documentType;
            this.uploadedFiles.push(file);
        }

        const files = {};
        files[this.documentType] = this.uploadedFiles;
        this.select.emit(files);
    }

    onRemove(e) {
        this.uploadedFiles = UtilService.removeItemFromList(this.uploadedFiles, 'name', e.file.name);
        const files = {};
        files[this.documentType] = this.uploadedFiles;
        this.select.emit(files);
    }
}
