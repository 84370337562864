import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {IAppDataState} from '@app/core/store/app-data.state';
import {IApplicationResponse} from '@lib/models/page.model';
import {UtilService} from '@lib/services/util.service';
import {setApplication} from '@lib/store/shared.actions';
import {Store} from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class FlowService {

    constructor(
        private store$: Store<IAppDataState>,
        private router: Router
    ) {
    }

    /**
     * On GET application/{id} - stay on url if allowed ( on refresh )
     * * On GET application/{id} - go to last page only ( on resume )
     * On PUT/PATCH or Refresh, go if pathName available in actions, else go to last action
     * @param appResponse
     * @param applicationId
     */
    persistApplicationData(
        appResponse: IApplicationResponse,
        pageName?: string
    ) {
        this.store$.dispatch(setApplication({
            applicationId: appResponse.entity?.id,
            progressSteps: appResponse.progressSteps,
            pages: appResponse.pages
        }));

        if (appResponse.pages) {
            this.navToCurrentRoute(appResponse, pageName);
        }
    }

    /**
     * if allowed route - load same route
     * if not allowed route - redirect to lastPage
     * if no route - redirect to lastPage
     * @param pages
     * @param currentRoute
     */
    navToCurrentRoute(appResponse: IApplicationResponse, currentRoute: string) {
        const allowedUrls = Object.keys(appResponse.pages);
        if (!currentRoute || !allowedUrls.includes(currentRoute)) {
            const pageObj: any = UtilService.nextPage(appResponse.pages);
            this.router.navigate([appResponse.entity?.id, pageObj.page]);
        }
    }
}
