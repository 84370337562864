import {OnChanges, Pipe, PipeTransform, SimpleChanges} from '@angular/core';

@Pipe({
    name: 'stringReplace'
})
export class StringReplacePipe implements PipeTransform, OnChanges {
    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
      console.log(changes);
    }

    transform(value: string, args?: any, subType?: string): string {
        Object.keys(args).forEach((key) => {
            value = value?.split(key).join(args[key]);
        });

        return value;
    }

}
