import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormComponent} from '@lib/components/form/base-component/base-form.component';
import {UtilService} from '@lib/services/util.service';

@Component({
    selector: 'app-review',
    templateUrl: './review.component.html',
    styleUrls: ['./review.component.scss']
})
export class ReviewComponent extends BaseFormComponent implements OnInit, OnDestroy {
    payFrequencyOptions: any;
    templateList = ['address', 'income'];
    subItems: string[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        const items = this.item.extendedParams?.items || [];
        this.subItems = UtilService.sort(items.slice());
        if (this.pageGetSchema && ['primary', 'secondary'].includes(this.item.name)) {
            const values = this.pageGetSchema[this.item.name]?.payFrequency?.option?.values;
            this.payFrequencyOptions = UtilService.arrayToObjectByKey(values, 'id');
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
