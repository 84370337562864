import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPage} from '@lib/models/page.model';
import {ICmsPage, IGlobal} from '../models/cms.model';
import {ConfigService} from '@lib/services/config.service';
import {UtilService} from '@lib/services/util.service';
import {forkJoin, from, map, merge, mergeAll, mergeMap, Observable} from 'rxjs';

interface IStrapiResponse {
    data: {
        id: number;
        attributes: any;
    };
    meta: {}
}

interface IStrapiCollectionResponse {
    data: [{
        id: number;
        attributes: any;
    }];
    meta: {}
}

@Injectable({
    providedIn: 'root'
})
export class StrapiService {

    constructor(private cs: ConfigService, private http: HttpClient) {
    }

    getName() {
        return 'StrapiService';
    }

    public getHeaders(token: string): HttpHeaders {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        });
        return headers;
    }

    /**
     * Dispatch a CMS action and transform data
     */
    getGlobalContent(name: string, isCollection?: boolean): Observable<any> {
        const global = this.getContentByName(name, isCollection);
        const options = this.retrieveContent(`options`, true);
        return forkJoin(global, options)
            .pipe(map(res => {
                for (const obj of res[1]) {
                    delete obj.createdAt;
                    delete obj.updatedAt;
                    delete obj.publishedAt;
                }
                return res;
            }));
    }

    getContentByName(name: string, isCollection?: boolean) {
        return this.retrieveContent(`${name}?populate=*`, isCollection);
    }

    getContent(name: string, isCollection?: boolean): Observable<any> {
        return from(this.getContentByName(name, isCollection));
    }

    getPathDetails(name: string, nestedParams?: string[]): Observable<IPage> {
        let nestedParamsQs = '', url;
        nestedParams?.forEach((param, index) => {
            nestedParamsQs += '&populate[' + index + ']=' + param;
        });
        if (nestedParams?.length) {
            url = `pages?filters[name][$eq]=${name}&${nestedParamsQs}`;
        } else {
            url = `pages?filters[name][$eq]=${name}&populate=*${nestedParamsQs}`;
        }
        return from(this.retrieveContent(url));
    }

    getModalDetails(name: string, nestedParams?: string[]): Observable<IPage> {
        let nestedParamsQs = '', url;
        nestedParams?.forEach((param, index) => {
            nestedParamsQs += '&populate[' + index + ']=' + param;
        });
        if (nestedParams?.length) {
            url = `dialogs?filters[name][$eq]=${name}&${nestedParamsQs}`;
        } else {
            url = `dialogs?filters[name][$eq]=${name}&populate=*${nestedParamsQs}`;
        }
        return from(this.retrieveContent(url));
    }

    retrieveContent(name: string, isCollection?: boolean): any {
        const url = this.cs.get('strapi.url');
        const token = this.cs.get('strapi.token');
        return this.http.get(`${url}/${name}`, {
            headers: this.getHeaders(token),
            params: {'x-bearer': true}
        }).pipe(
            map((res: any) => {
                return isCollection ? this.processCollection(res) : this.processData(res);
            }),
            map(
                (item: ICmsPage) => {
                    if (item.formFields?.length || item.tiles?.length) {
                        return {
                            ...item,
                            formFields: UtilService.arrayToObjectByKey(item.formFields),
                            tiles: UtilService.arrayToObjectByKey(item.tiles, 'position')
                        };
                    } else {
                        return item;
                    }
                }
            )
        );
    }

    processData(res: IStrapiResponse) {
        return (Array.isArray(res.data) ? res.data?.length && res.data[0]?.attributes : res.data?.attributes);
    }

    processCollection(response: IStrapiCollectionResponse): any {
        return response.data.map(item => item.attributes);

    }
}
