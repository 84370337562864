import {createReducer, on} from '@ngrx/store';
import {clearPageContent, loadCmsPageContentSuccess, loadGlobalContentSuccess} from './cms.actions';
import {initialState} from './cms.state';

const _cmsReducer = createReducer(
    initialState,
    on(loadGlobalContentSuccess, (state, action) => {
        return {
            ...state,
            global: action.content
        };
    }),
    on(loadCmsPageContentSuccess, (state, action) => {
        return {
            ...state,
            page: action.payload
        };
    }),
    on(clearPageContent, (state) => {
        return {
            ...state,
            page: null
        };
    })
);

export function CmsReducer(state, action) {
    return _cmsReducer(state, action);
}
