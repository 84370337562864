import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {CMS_PAGE_ROUTES, ICmsPage} from '@lib/cms/models/cms.model';
import {loadCmsPageContent, loadCmsPageContentSuccess} from '@lib/cms/store/cms/cms.actions';
import {getCmsPageContent} from '@lib/cms/store/cms/cms.selector';
import {UnsubscribeOnDestroyAdapter} from '@lib/adapters/unsub-on-destroy.adapter';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
    page$: Observable<ICmsPage>;
    messages: any[] = [];
    application: any;
    actions: any;

    constructor(
        private router: Router,
        private store$: Store,
        private authProviderService: AuthProviderService
    ) {
        super();
    }

    ngOnInit(): void {
        this.store$.dispatch(loadCmsPageContent({pathName: APP_PAGE_ROUTES.LANDING}));
        this.page$ = this.store$.select(getCmsPageContent);
    }

    onSubmit(cta: string) {
        this[cta]();
    }

    login() {
        this.router.navigate([APP_PAGE_ROUTES.LOGIN]);
    }

    authLogin() {
        this.authProviderService.login();
    }

    getStarted() {
        this.router.navigate([APP_PAGE_ROUTES.GET_STARTED]);
    }

    resumeApp() {
        this.router.navigate([APP_PAGE_ROUTES.LOGIN]);
    }

    ngOnDestroy() {
        this.store$.dispatch(loadCmsPageContentSuccess({}));
        super.ngOnDestroy();
        this.page$ = null;
    }
}
