import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmModalComponent} from '@app/core/components/modals/confirm-modal/confirm-modal.component';
import {MODAL_WINDOWS} from '@app/core/models/core.model';
import {loadDocumentsContent} from '@app/core/store/app-data.actions';
import {getDocumentsContent} from '@app/core/store/app-data.selector';
import {clearPageContent} from '@lib/cms/store/cms/cms.actions';
import {IPageResponse} from '@lib/models/page.model';
import {BaseFormComponent} from '@lib/components/form/base-component/base-form.component';
import {AppApiService} from '@lib/services/app-api.service';
import {ModalService} from '@lib/services/modal.service';
import {UtilService} from '@lib/services/util.service';
import {getApplicationId} from '@lib/store/shared.selector';
import {Store} from '@ngrx/store';
import {Observable, Subscription, takeUntil} from 'rxjs';

export interface IDocument {
    id: number,
    acceptableActions: number[];
    availableCmsActions?: {
        id: number;
        name: string;
        actionId: number;
    };
    loading: boolean[];
    action?: any;
    documentId?: number;
    name?: string;
    footNote?: string;
}

enum DOCUMENT_STATUS {
    APPROVE,
    SKIP,
    DECLINE
}

@Component({
    selector: 'lib-documents-viewer',
    templateUrl: './documents-viewer.component.html',
    styleUrls: ['./documents-viewer.component.scss']
})
export class DocumentsViewerComponent extends BaseFormComponent implements OnInit, OnDestroy {
    documentIndex = 0;
    viewingDocument: IDocument;
    content: Blob;
    base64 : Promise<string>;
    viewDocumentSub: Subscription;
    appId: any;
    showViewer = false;
    processDocuments: boolean[];
    documentCms$: Observable<{
        [key: number]: IDocument
    }>;

    constructor(
        private store$: Store,
        private api: AppApiService,
        private router: Router,
        private modal: ModalService
    ) {
        super();
    }

    ngOnInit(): void {
        this.processDocuments = [];
        this.store$.dispatch(loadDocumentsContent());
        this.documentCms$ = this.store$.select(getDocumentsContent);
        this.viewDocumentSub = this.store$.select(getApplicationId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(appId => {
                this.appId = appId;
                this.loadDocument();
            });
    }

    loadNextDocument() {
        this.showViewer = false;
        this.documentIndex++;
        this.loadDocument();
    }

    loadDocument() {
        this.viewingDocument = this.data.documents[this.documentIndex];
        this.api.getDocument(this.appId, this.viewingDocument.id)
            .subscribe(
                (document: Blob) => {
                    this.showViewer = true;
                    this.content = document;
                    this.base64 = this.blobToBase64(document);
                },
                () => {
                }
            );
    }

    updateDocumentStatus(params: {actionId: number, modalName?: string}, confirm?: boolean) {
        const actionId = params.actionId;
        this.processDocuments[actionId] = true;

        if (params.modalName && !confirm) {
            this.processDocuments[actionId] = false;
            const config = {actions: {primary: this.updateDocumentStatus.bind(this, {actionId}, true)}};
            this.modal.show({name: params.modalName, component: ConfirmModalComponent}, config);
            return false;
        }

        this.modal.close();
        this.api.putDocument(this.appId, this.viewingDocument.id, actionId)
            .subscribe(
                (response: IPageResponse) => {
                    this.processDocuments[actionId] = false;
                    this.processNext(response);
                },
                () => {
                    this.processDocuments[actionId] = false;
                }
            );

        return true;
    }

    blobToBase64(blob): Promise<string> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
              const dataUrl = reader.result as string;
              const base64 = dataUrl.split(',')[1];
              resolve(base64);
          };
          reader.readAsDataURL(blob);
        });
      }

    processNext(response: IPageResponse) {
        const urlParams = this.router.url.split('/');
        const currentRoute = urlParams[urlParams.length - 1];
        const currentPage: any = UtilService.nextPage(response.pages);
        if (currentPage.page !== currentRoute) {
            this.router.navigate([this.appId, currentPage.page]);
        } else {
            this.loadNextDocument();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.showViewer = false;
        this.processDocuments = [];
        this.viewDocumentSub.unsubscribe();
        this.store$.dispatch(clearPageContent());
    }

}
