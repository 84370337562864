import {ILookupConfig} from '../../components/form/lookup/lookup.component';
import {IMenuItem} from '../../models/lib.model';
import {IApplicationQuery} from '../../services/query-api.service';

export const CMS_PAGE_ROUTES = {
    ACCOUNT_OVERVIEW: 'account-overiview',
    GET_STARTED: 'get-started',
    GLOBAL: 'global',
    AUTHENTICATE: 'authenticate',
    LOGOUT: 'logout',
    PAGE_404: 'page-404',
    FORBIDDEN: 'forbidden',
    SESSION_TIMEOUT: 'timeout',
    STATIC_PAGES: 'public',
    NOT_FOUND: 'not-found',
    LOGIN: 'login'
};

export const CMS_PAGE_TYPES = {
    FORM: 'form',
    READ_ONLY: 'readonly'
};

export interface IGlobal {
    logo?: string;
    logoStyle?: string;
    title?: string;
    hideHeaderNavUrls?: string[];
    menus?: IMenuItem[];
    headerMenus?: IMenuItem[];
    footerMenus?: IMenuItem[];
    footerDisclaimer?: any;
    themeUrl?: string;
    extendedParams?: {
        [key: string]: any
    },
    logoUrl?: {
        data: {
            attributes: {
                url: string;
            }
        }
    }
}

export interface ICannedFilter {
    label?: string;
    filterString?: string;
    checked?: boolean;
}

export interface ICmsPage {
    id?: string;
    name?: string;
    type?: string;
    title?: string;
    kicker?: string;
    klass?: string;
    disclaimer?: string;
    footerDisclosures?: string;
    primaryCtaText?: string;
    primaryCta?: string;
    secondaryCtaText?: string;
    secondaryCta?: string;
    formFields?: IFormField[] | any;
    tiles?: ITile[] | any;
    hideProgressStep?: boolean;
    queryConfig?: {
        query: {
            [key: string]: IApplicationQuery;
        },
        mutations: {
            [key: string]: any;
        },
        mutation: {
            [key: string]: any;
        },
        dataNodeName?: string;
        dataParentNodeName?: string;
        cannedFilters: {
            type: 'single' | 'multiple';
            options: ICannedFilter[];
        };
        config: {
            paginator: {
                show: boolean;
                paginatorDropdownAppendTo?: string;
            };
            filters: {
                showAddButton?: boolean;
            }
            rowsPerPageOptions: number[];
            showCurrentPageReport: boolean;
            isSelectable: boolean;
            multipleSelection?: boolean;
            lazy: boolean;
            emptyMessage?: string;
            readOnly?: boolean;
            searchable?: boolean;
            currentPageReportTemplate?: string;
            responsiveLayout?: string;
            rowExpandDataKey?: string;
            rowExpandTemplate?: string;
        }
    };
    ignoreFlow?: boolean;
    extendedParams?: any;
    appId?: string | number;
    description?: string;
    subDescription?: string;
    customHeader?: boolean;
}

export interface ITile {
    name?: string;
    hide?: boolean;
    queryConfig?: any;
    tabs?: IMenuItem[];
    position?: string;
    extendedParams?: any;
    klass?: string;
    style?: string;
    formFields?: IFormField[];
}

export interface IFormField {
    type: string;
    subType?: string;
    customType?: string;
    nullable?: string;
    name: string;
    label: string;
    sectionInfo : string;
    actionLabel?: string;
    validations?: IFormFieldValidation[];
    options?: {
        key?: string,
        value: string | number,
        id?: string,
        description?: string,
        checked: boolean,
        disabled: boolean
    }[];
    option?: ILookupConfig;
    klass?: string;
    style?: string;
    guardMessage?: string;
    tooltip?: string;
    placeholder?: string;
    disabled?: any;
    maxLength?: number;
    minLength?: number;
    hide?: boolean;
    format?: string;
    description?: string;
    subFormFields?: IFormField[];
    extendedParams?: any;
    cta?: string;
    icon?: string;
    order?: number;
    hint?: string;
}

export interface IFormFieldValidation {
    [key: string]: {
        value?: string | Function,
        message?: string
    }
}

export const CMS = {
    GLOBAL_CONTENT: 'global',
    PAGE_CONTENT: 'page',
    OFFERS_CONTENT: 'offer',
    DOCUMENTS_CONTENT: 'documents'
};
