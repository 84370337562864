import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConfigService} from '@lib/services/config.service';

@Injectable({
    providedIn: 'root'
})
export class AuthProviderClientIdService {
    public clientId;

    constructor(private http: HttpClient, private cs: ConfigService) {
    }

    public async getClientName(name: string): Promise<any> {
        this.clientId = this.cs.get(name);
        return {};
    }

    getClientId() {
        return this.clientId;
    }
}
