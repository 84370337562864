import {NgModule} from '@angular/core';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SidebarModule} from 'primeng/sidebar';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {TabMenuModule} from 'primeng/tabmenu';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';

@NgModule({
    declarations: [],
    exports: [
        ButtonModule,
        InputTextModule,
        InputNumberModule,
        MessagesModule,
        MessageModule,
        CheckboxModule,
        CalendarModule,
        DropdownModule,
        AutoCompleteModule,
        RadioButtonModule,
        ToolbarModule,
        SidebarModule,
        StepsModule,
        ProgressSpinnerModule,
        TableModule,
        TabMenuModule,
        FileUploadModule,
        DynamicDialogModule,
        InputTextModule,
        ToggleButtonModule,
        InputSwitchModule,
        AccordionModule
    ],
    imports: []
})
export class PrimengModule {
}
