<ng-container [formGroup]="group" class="viewer">
    <h4>{{item.label}} </h4>
    <div class="loading"><lib-spinner></lib-spinner></div>
    <div *ngIf="showViewer" class="viewer">
        <ngx-extended-pdf-viewer [base64Src]="base64 | async"
                                 class="viewer__content"></ngx-extended-pdf-viewer>
        <div class="viewer__footer">
            <div class="viewer__footer-content container-max" *ngIf="(documentCms$ | async) as document">
                <p>{{document[viewingDocument.id]?.name}}</p>
                <div class="viewer__footer-content__actions">
                    <lib-button *ngFor="let actionId of viewingDocument.acceptableActions;"
                                [label]="document[viewingDocument.id]?.availableCmsActions[actionId]?.name"
                                type="button"
                                [klass]="document[viewingDocument.id]?.availableCmsActions[actionId]?.klass"
                                [loading]="processDocuments[actionId]"
                                (click)="updateDocumentStatus({actionId, modalName: document[viewingDocument.id]?.availableCmsActions[actionId]?.confirmDialog})"
                    ></lib-button>
                </div>
                <p>{{document[viewingDocument.id]?.footNote}}</p>
            </div>
        </div>
    </div>
</ng-container>
