<ng-container *ngIf="!readonly">
    <div class="field">
        <label [for]="item.name" class="p-float-label">{{ item.label }}</label>
        <p-calendar
                [defaultDate]="defaultDate"
                [(ngModel)]="date"
                [dateFormat]="item.format"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [showIcon]="true"
                (onBlur)="onBlur($event)"
                (ngModelChange)="onModalChange()"
                dateMask
                [placeholder]="item.placeholder || item.label"
                inputId="icon"
                [appendTo]="item.extendedParams?.appendTo || 'body'"
                [disabled]="formControl.disabled"
                [panelStyleClass]="item.klass"
                id="{{item.name}}"
        ></p-calendar>
        <ng-container [formGroup]="group" *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
            <small class="p-error block"
                   *ngFor="let errKey of errorKeys; trackBy: trackByFn;">{{item.validations[errKey]?.message}}</small>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="readonly">
    <div class="data-field">
        <div class="data-field-label">{{item.label}}</div>
        <div class="data-field-value">{{value?.replace('Z', '') | date: item.format}}</div>
    </div>
</ng-container>

