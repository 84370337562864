import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {APP_PAGE_ROUTES} from '@app/core/models/core.model';
import {UserService} from '@lib/services/user.service';
import {ICmsPage} from '@lib/cms/models/cms.model';
import {clearPageContent, loadCmsPageContent} from '@lib/cms/store/cms/cms.actions';
import {getCmsPageContent, getGlobalContent} from '@lib/cms/store/cms/cms.selector';
import {BaseComponent} from '@lib/components/base/base.component';
import {AppApiService} from '@lib/services/app-api.service';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends BaseComponent implements OnInit, OnDestroy {
    page$: Observable<ICmsPage>;

    constructor(
        private route: ActivatedRoute,
        private authProviderService: AuthProviderService,
        private user: UserService,
        private router: Router,
        private api: AppApiService,
        private store$: Store
    ) {
        super();
    }

    ngOnInit(): void {
        this.store$.dispatch(loadCmsPageContent({pathName: APP_PAGE_ROUTES.LANDING}));
        this.page$ = this.store$.select(getCmsPageContent);
        this.store$.select(getGlobalContent).subscribe(gc => {
            if (gc && gc.extendedParams) {
                const url = gc.extendedParams['partnerDetails'] && gc.extendedParams['partnerDetails']['landingUrl'];
                this.onLogout(url);
            }
        });
    }

    onLogout(url) {
        this.user.logout();
        if (!url) {
            this.router.navigate(['/']);
        } else {
            document.location.href = url;
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.store$.dispatch(clearPageContent());
    }

}
