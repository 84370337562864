//import { LabelType, Options } from '@angular-slider/ngx-slider';
import { formatCurrency } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, AfterViewInit {
  @Input() value: number;
  @Input() steps: Array<any>;
  @Input() key: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  options: any = {
    showTicksValues: false,
    showSelectionBar: true,
    showTicks: true,
    //translate: this.translate,
    vertical: true
  };

  constructor() {
  }

  /**
   * Remove duplicate amounts from slider display
   * Sort Descending order
   * Value : populate based on selected offer, default - highest amount
   */
  ngOnInit(): void {
    this.options.stepsArray = this.steps.filter((step, index, array) => {
      return array.findIndex(t => t[this.key] === step[this.key]) === index;
    });
    this.options.stepsArray.sort((a, b) => a[this.key] - b[this.key]);
    this.value = this.value || this.options.stepsArray[this.options.stepsArray.length - 1][this.key];
  }

  // translate(value: number, label: LabelType): string {
  //   switch (label) {
  //     case LabelType.Ceil:
  //       return '';
  //     case LabelType.Floor:
  //       return '';
  //     default:
  //       return formatCurrency(value, 'en', '$', 'USD', '1.0-0');
  //   }
  // }

  ngAfterViewInit(): void {
    this.sliderChange({ value: this.value });
  }

  sliderChange(obj) {
    obj.show = this.options.stepsArray.length > 1;
    if (this.onChange) {
      this.onChange.emit(obj);
    }
  }
}
