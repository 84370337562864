<lib-spinner *ngIf="!((page && data) || noLoansFound)"></lib-spinner>
<div class="account-overview no-loans" *ngIf="noLoansFound">
    <h4>{{page.formFields['accountSummary'].label}}</h4>
    <markdown [data]="page.kicker" *ngIf="page.kicker"></markdown>
</div>
<div *ngIf="page && loans">
    <div class="account-overview" *ngFor="let d of data">
        <div class="summary-section section">
            <h4>{{page.formFields['accountSummary'].label}}</h4>
            <div class="label-value">
                <div class="data-label">{{page.formFields['loanId'].label}}</div>
                <div class="data-value">{{d.loanId}}</div>
            </div>
            <div class="label-value" *ngIf="d.loanAmount">
                <div class="data-label">{{page.formFields['originalNoteAmount'].label}}</div>
                <div class="data-value">{{d.loanAmount | currency}}</div>
            </div>
            <div class="label-value" *ngIf="d.creditLimit">
                <div class="data-label">{{page.formFields['creditLimit'].label}}</div>
                <div class="data-value">{{d.creditLimit | currency}}</div>
            </div>
            <div class="label-value">
                <div class="data-label">{{page.formFields['loanType'].label}}</div>
                <div class="data-value">{{d.type }}</div>
            </div>            
            <div class="label-value">
                <div class="data-label">{{page.formFields['nextPayment'].label}}</div>
                <div class="data-value">{{(d.nextPaymentAmount || 0) | currency}}</div>
            </div>
            <div class="label-value">
                <div class="data-label">{{page.formFields['dueDate'].label}}</div>
                <div class="data-value">{{d.nextPaymentDate | date: page.formFields['dueDate'].format}}</div>
            </div>
            <lib-button
                    [label]="page.formFields['viewLoanDetails'].label"
                    [klass]="page.formFields['viewLoanDetails'].klass"
                    (click)="viewLoanDetails(d)"
                    (keyup.enter)="viewLoanDetails(d)"
                    *ngIf="page.formFields['viewLoanDetails']"
            ></lib-button>
        </div>
    </div>
</div>
