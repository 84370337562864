//import { NgxSliderModule } from '@angular-slider/ngx-slider/angular-slider-ngx-slider';
import { NgModule } from '@angular/core';
import { DateMaskDirective } from './directives/date-mask.directive';
import {GraphQLModule} from './graphQl.module';
import {NgxSignaturePadModule} from '@eve-sama/ngx-signature-pad';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {AngularImageViewerModule} from '@hreimer/angular-image-viewer';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {MarkdownModule} from 'ngx-markdown';
import {NgxMaskDirective, provideNgxMask, NgxMaskPipe} from 'ngx-mask';

@NgModule({
    declarations: [DateMaskDirective],
    imports: [
        MarkdownModule.forRoot(),
        AngularImageViewerModule,
        NgxMaskPipe,
        NgxMaskDirective,
        NgxExtendedPdfViewerModule,
        NgxSignaturePadModule,
        GraphQLModule
    ],
    exports: [
        GraphQLModule,
        MarkdownModule,
        NgxMaskDirective,
        NgxMaskPipe,        
        NgxExtendedPdfViewerModule,
        DateMaskDirective,
        NgxSignaturePadModule,
        NgxJsonViewerModule,
        AngularImageViewerModule
    ],
    providers: [provideNgxMask()]
})
export class AppNgxModule {
}
