import {
    ComponentRef,
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import {APP_COMPONENT_TYPES} from '@app/core/models/core.model';
import {DynamicBaseComponent} from '@lib/components/dynamic-base-component/dynamic-base.component';

@Directive({
    selector: '[appDynamicComponentRender]'
})
export class DynamicComponentDirective implements OnInit, OnChanges, OnDestroy {
    @Input() updated;
    @Input() model;
    @Input() template;
    @Output() onSubmit: EventEmitter<any> = new EventEmitter();
    componentRef: ComponentRef<DynamicBaseComponent>;

    constructor(public viewContainerRef: ViewContainerRef) {
    }

    ngOnInit(): void {
        this.viewContainerRef.clear();
        this.componentRef = this.viewContainerRef.createComponent(APP_COMPONENT_TYPES[this.template]);
        this.componentRef.instance.updated = this.updated;
        this.componentRef.instance.model = this.model;
        this.componentRef.instance.template = this.template;
        this.componentRef.instance.onSubmit = this.onSubmit;
    }

    ngOnChanges(changes: SimpleChanges): boolean {
        if (!this.componentRef) {
            return false;
        }

        this.componentRef.instance.updated = this.updated;
        this.componentRef.instance.model = Object.assign({}, this.model);
        return true;
    }

    ngOnDestroy(): void {
    }
}
