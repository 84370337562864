<ng-container [formGroup]="form" *ngIf="item && page">
    <lib-input [group]="form" [item]="item" [readonly]="readonly" [pageGetSchema]="pageGetSchema"
               [formSubmitSchema]="formSubmitSchema"></lib-input>
    <lib-input [group]="form" [item]="confirmItem" [readonly]="readonly"
               [pageGetSchema]="pageGetSchema"
               (paste)="$event.preventDefault();"
               [formSubmitSchema]="formSubmitSchema"></lib-input>
    <small class="p-error block" *ngIf="form.errors && form.errors['passwordNotMatch'] && confirmFormControl.valid">
        {{passwordNotMatchValidator['message']}}
    </small>
</ng-container>
