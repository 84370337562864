<div *ngIf="!cms" class="loading">
    <lib-spinner></lib-spinner>
</div>
<div *ngIf="cms?.queryConfig?.config" class="datatable" [ngClass]="{'no-records': !dt?.totalCount}">
    <p-table [value]="dt.data"
             [loading]="dt.loading"
             [totalRecords]="dt.totalCount"
             [rows]="cms.queryConfig.config.rowsPerPageOptions[0]"
             [lazy]="cms.queryConfig.config.lazy"
             [globalFilterFields]=""
             [responsiveLayout]="cms.queryConfig.config.responsiveLayout || 'scroll'"
             [currentPageReportTemplate]="(cms.queryConfig.config.currentPageReportTemplate || 'Showing {first} to {last} of {totalRecords} entries')"
             [showCurrentPageReport]="cms.queryConfig.config.showCurrentPageReport"
             [rowsPerPageOptions]="cms.queryConfig.config.rowsPerPageOptions"
             [paginator]="cms.queryConfig.config.paginator.show"
             [(selection)]="selectedItems"
             (onLazyLoad)="getData($event)"
             [dataKey]="cms.queryConfig.config.rowExpandDataKey"
             [paginatorDropdownAppendTo]="cms.queryConfig.config.paginator.paginatorDropdownAppendTo || 'body'"
             #datatable
             styleClass="p-datatable-striped">
        <ng-template pTemplate="caption"
                     *ngIf="cms.queryConfig.config.searchable || cms.queryConfig.cannedFilters?.options?.length">
            <div class="table-header">
                <lib-button label="Clear" klass="p-button p-button-outlined auto-width" icon="pi pi-filter-slash"
                            (click)="clear(dataTable)" (keyup.enter)="clear(dataTable)"></lib-button>
                <div class="canned-filters" *ngIf="cms.queryConfig.cannedFilters?.options?.length">
                    <lib-toggle-button [label]="option.label"
                                       [checked]="!!option.checked"
                                       (callback)="onCannedFilters($event, option)"
                                       *ngFor="let option of cannedFilters"
                    ></lib-toggle-button>
                </div>
                <lib-input [item]="cms.formFields['searchTerm']" [group]="fg"
                           (onBlur)="!fg.controls['searchTerm'].value && getData({globalFilter: cms.formFields['searchTerm'].name})"
                           (keyup.enter)="getData({globalFilter: cms.formFields['searchTerm'].name})"
                           *ngIf="cms.queryConfig.config.searchable"
                ></lib-input>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngIf="cms.queryConfig.config.rowExpandDataKey" class="row-expand-header"></th>
                <th *ngIf="cms.queryConfig.config.isSelectable"></th>
                <th *ngFor="let col of dt.cols" [pSortableColumn]="col.field"
                    [ngClass]="{'hide' : col.hide || !col.label}">
                    <p-sortIcon [field]="col.field"
                                *ngIf="!(col.extendedParams?.filterOptions?.disableSorting || readOnly)"></p-sortIcon>
                    {{col.header}}
                    <p-columnFilter [type]="COL_TYPE[col.subType || col.type || 'string']" display="menu"
                                    [field]="col.field"
                                    [showAddButton]="!!cms.queryConfig.config.filters?.showAddButton"
                                    [minFractionDigits]="col.extendedParams?.filterOptions?.minFractionDigits || col.subType === 'currency' ? 2 : 0 || 0"
                                    [maxFractionDigits]="col.extendedParams?.filterOptions?.maxFractionDigits || col.subType === 'currency' ? 2 : 0 || 0"
                                    *ngIf="!(col.extendedParams?.filterOptions?.disableFiltering || readOnly)">
                    </p-columnFilter>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
            <tr>
                <td *ngIf="cms.queryConfig.config.rowExpandDataKey">
                    <button type="button" pButton pRipple [pRowToggler]="rowData"
                            class="p-button-text p-button-rounded p-button-plain row-toggler"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td *ngIf="cms.queryConfig.config.isSelectable">
                    <p-tableCheckbox [value]="rowData" (click)="onSelection($event, false)"
                                     *ngIf="cms.queryConfig.config.multipleSelection"></p-tableCheckbox>
                    <p-tableRadioButton [value]="rowData" (click)="onSelection($event, true)"
                                        *ngIf="!cms.queryConfig.config.multipleSelection"></p-tableRadioButton>
                </td>
                <td *ngFor="let col of dt.cols;" [ngClass]="{'hide' : col.hide || !col.label}" valign="middle"
                    class="{{col.field}}">
                    <a tabindex="0" *ngIf="col.cta"
                       (click)="onItemClick($event, rowData, col.cta)"
                       (keyup.enter)="onItemClick($event, rowData, col.cta)">{{rowData[col.field]}}</a>
                    <ng-container appDynamicComponentRender [model]="{rowData, col, cms, timezone}"
                                  [updated]="!!rowData.updated"
                                  template="data-table-cell" *ngIf="!col.cta"></ng-container>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" *ngIf="cms.queryConfig.config.emptyMessage">
            <tr>
                <td colspan="100%">{{cms.queryConfig.config.emptyMessage}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData>
            <tr>
                <td colspan="100%" class="expanded-row">
                    <ng-container
                            [model]="{data: rowData, cms, timezone}"
                            [template]="cms.queryConfig.config.rowExpandTemplate"
                            (onSubmit)="initTable()"
                            appDynamicComponentRender
                    ></ng-container>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft"></ng-template>
    </p-table>
</div>
