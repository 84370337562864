import {IDocument} from '@app/core/components/documents-viewer/documents-viewer.component';
import {IOfferPage} from '@app/core/components/offers/offers.component';
import {IAppConfig} from '@lib/store/shared.state';

export interface IAppDataState extends IAppCmsState {
    appConfig?: IAppConfig
}

export const initialState: IAppDataState = {
    offers: {} as IOfferPage,
    documents: {}
};

export interface IAppCmsState {
    offers?: IOfferPage;
    documents?: {
        [key: number]: IDocument
    };
}


