import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {AuthProviderService, IAuthTokens} from '../services/authenticate/auth-provider.service';
import {ConfigService} from '../services/config.service';
import {UserService} from '../services/user.service';
import {Observable, of} from 'rxjs';
import {StorageService} from '../services/storage.service';
import {switchMap, catchError, throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService {

    constructor(
        private storage: StorageService,
        private authProvider: AuthProviderService,
        private user: UserService,
        private config: ConfigService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.getHeaders(req);
        return next.handle(req).pipe(catchError(error => {
            if (error.status === 401 && this.storage.refreshToken) {
                return this.authProvider.refreshToken().pipe(
                    switchMap((tokens: IAuthTokens) => {
                        this.user.logIn(tokens);
                        req = this.getHeaders(req);
                        return next.handle(req);
                    })
                );
            }
            return throwError(error);
        }));
    }

    getHeaders(req: HttpRequest<any>): HttpRequest<any> {
        const token: any = this.storage.token;
        const customHeaders = <any>{};

        if (!req.url.includes("config.json") && this.config.get("clientId") && (req.url.includes(this.config.get("apiUrl")))) {
            customHeaders.clientId = this.config.get("clientId");
        }

        if (token && !req.params.get('x-bearer')) {
            customHeaders.Authorization = `Bearer ${token}`;
        }

        req = req.clone({
            setHeaders: customHeaders
        });
        return req;
    }
}
