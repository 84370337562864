import {IAlert} from '@lib/models/lib.model';
import {IPageResponse} from '@lib/models/page.model';

export interface ISharedState {
    showLoading: boolean;
    mobileMode: boolean;
    authenticated: boolean;
    credentialsExist: boolean;
    alert: IAlert;
    loanSelected: boolean;
    currentPage: IPageResponse;
    applicationId: number;
    progressSteps: string[];
    appConfig: IAppConfig;
}

export interface IAppConfig {
    loanStatuses?: any;
    items?: any,
    categories?: {
        [key: string]: string
    },
    groups?: {
        [key: string]: {
            [key: string]: string,
            documentTypes: any
        }
    },
    documentTypes?: {
        [key: string]: any
    },
    procedures?: {
        [key: string]: any
    },
    procedureGroups?: {
        [key: string]: string
    }
    categoryDocumentTypes?: {
        [key: string]: string[]
    },
    documentGroups?: {
        [key: string]: {
            documentTypes: string[]
        }
    },
    verificationGroups?: {
        [key: string]: {
            items: {
                [key: string]: {
                    procedures: {
                        [key: string]: {
                            documentGroups: string[]
                        }
                    }
                }
            }
        }
    },
    companyOffset?: string;
    companyTimezone?: string;
    currentCompanyTime?: string;
    paymentMethodTypes?: any;
    disbursementTypes?: any;
    paymentTypes?: any;
    paymentAmountRange?: {
        min?: number;
        max?: number;
    },
    maxPaymentDaysInAdvance?: number;
}

export const initialState: ISharedState = {
    applicationId: 0,
    loanSelected: false,
    mobileMode: false,
    showLoading: false,
    alert: {errorCode: 0, detail: '', severity: 'error', summary: 'Error', data: []},
    authenticated: false,
    credentialsExist: false,
    currentPage: {} as IPageResponse,
    progressSteps: [],
    appConfig: {} as IAppConfig
};
