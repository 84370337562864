<div class="page p-card">
    <lib-page-header
            *ngIf="(routeParams$ | async)?.page || (routeParams$ | async)?.id === 'get-started'"></lib-page-header>
    <lib-page-breadcrumbs
            *ngIf="(routeParams$ | async)?.page && !(cmsPage$ | async)?.hideProgressStep"></lib-page-breadcrumbs>
    <lib-alerts [msgObj]="(alert$ | async)"></lib-alerts>
    <router-outlet></router-outlet>
    <div class="loading">
        <lib-spinner></lib-spinner>
    </div>
</div>
