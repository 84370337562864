import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'lib-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() label: string;
    @Input() klass = '';
    @Input() type = 'submit';
    @Input() loading = false;
    @Input() icon = '';
    @Input() disabled: boolean;
    @Input() iconPos: any;
    @Output() callback: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    handleClick($event) {
        if (this.loading) {
            return false;
        }

        if (this.callback) {
            this.callback.emit($event);
        }
        return true;
    }

}
