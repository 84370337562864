import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormComponent} from '@lib/components/form/base-component/base-form.component';
import { renderString } from 'nunjucks';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent extends BaseFormComponent implements OnInit, OnDestroy {
    payFrequencyOptions: any;
    templateList = ['address', 'income'];
    subItems: string[];
    content : string;

    constructor() {
        super();
    }

    ngOnInit(): void {
        
        this.content = renderString(this.item.sectionInfo, this.data);
        console.log(this.content);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
