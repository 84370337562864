import {Injectable, Injector} from '@angular/core';
import {ConfigService} from '../config.service';
import {KeycloakService} from './keycloak.service';
import {Observable} from 'rxjs';

export interface IAuthTokens {
    access_token: string;
    id_token: string;
    refresh_token: string;
}

export interface IAuthProvider {
    initLogin(): void;

    processCode(object: any): Observable<IAuthTokens>;

    refreshToken(): Observable<IAuthTokens>;

    logOut(): void;
}

const authProviderMap = {
    keycloak: KeycloakService
};

@Injectable({
    providedIn: 'root'
})
export class AuthProviderService {

    constructor(private injector: Injector, private cs: ConfigService) {
    }

    getInstance(): IAuthProvider {
        return this.injector.get<any>(authProviderMap[this.cs.get('authProvider')]);
    }

    login() {
        return this.getInstance().initLogin();
    }

    processCode(object: any): Observable<IAuthTokens> {
        return this.getInstance().processCode(object);
    }

    refreshToken(): Observable<IAuthTokens> {
        return this.getInstance().refreshToken();
    }

    logOut() {
        return this.getInstance().logOut();
    }

}
