import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {IPageResponse} from '@lib/models/page.model';
import {HTTP_METHODS} from '@lib/models/lib.model';
import {AppApiService} from '@lib/services/app-api.service';
import {StorageService} from '@lib/services/storage.service';
import {UtilService} from '@lib/services/util.service';
import {IPageAction} from '@lib/models/page.model';

@Component({
    selector: 'lib-page-actions',
    templateUrl: './page-actions.component.html',
    styleUrls: ['./page-actions.component.scss']
})
export class PageActionsComponent implements OnInit {
    @Input() actions: IPageAction;
    @Input() appId: number | string;
    actionKeys: string[];

    constructor(private api: AppApiService, private router: Router, private storage: StorageService) {
    }

    verb(type) {
        return this.actions[type].methods.filter(m => {
            return m === HTTP_METHODS.PUT || m === HTTP_METHODS.PATCH || m === HTTP_METHODS.POST;
        })[0];
    }

    ngOnInit(): void {
        this.actionKeys = this.actions && Object.keys(this.actions);
    }

    submit(obj): void {
        this.api.submitPage(this.appId, obj.action, obj.params, this.verb(obj.action))
            .subscribe((response: IPageResponse) => {
                this.goToNextPage(response);
            }, () => {
            });
    }

    goToNextPage(response: IPageResponse) {
        const nextPage: any = UtilService.nextPage(response.pages);
        this.router.navigate([this.storage.launchParams.id, nextPage.page]);
    }

}
