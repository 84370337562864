<ng-container [formGroup]="group" *ngIf="!readonly" class="esign">
    <div class="field">
        <ngx-signature-pad
            #signature
            [options]="options"
            (beginSign)="onBeginSign()"
            (endSign)="onEndSign()"
            [ngClass]="{'ng-invalid ng-dirty': formControl.invalid && (formControl.dirty || formControl.touched)}"
        >
        </ngx-signature-pad>
        <ng-container *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
            <small class="p-error block"
                   *ngFor="let errKey of errorKeys; trackBy: trackByFn;">{{item.validations[errKey]?.message}}</small>
        </ng-container>
        <lib-button (click)="clear()" label="Clear" *ngIf="showClear" type="button" klass="p-button-sm"></lib-button>
    </div>
</ng-container>
