import {Injectable} from '@angular/core';
import {ContentfulClientApi, createClient, CreateClientParams, Entry, EntryCollection} from 'contentful';
import {from, map, Observable, of} from 'rxjs';
import {IMenuItem} from '@lib/models/lib.model';
import {ConfigService} from '@lib/services/config.service';
import {ICmsPage, IFormField, IGlobal} from '../models/cms.model';
import {CmsVendor} from '../models/cmsVendor.model';

@Injectable({
    providedIn: 'root'
})
export class ContentfulService implements CmsVendor {
    private client: ContentfulClientApi;

    constructor(private cs: ConfigService) {
        const clientParams: CreateClientParams = {
            space: this.cs.get('contentfulSpace'),
            environment: this.cs.get('contentfulEnvironment'),
            accessToken: this.cs.get('contentfulAccessToken')
        };
        this.client = createClient(clientParams);
    }

    getName() {
        return 'ContentfulService';
    }

    /**
     * Dispatch a CMS action and transform data
     */
    getGlobalContent(): Observable<IGlobal> {
        return this.toGlobal(from(
            this.client.getEntries({
                content_type: this.cs.get('contentful.contentTypeIds.global')
            })
        ));
    }

    getContent(type: string, isCollection?: boolean): Observable<IGlobal> {
        return of({});
    }

    getPathDetails(pathName: string): Observable<ICmsPage> {
        return this.toPage(
            from(
                this.client.getEntries({
                    content_type: this.cs.get('contentful.contentTypeIds.page'),
                    'fields.name': pathName
                })
            )
        );
    }

    getModalDetails(pathName: string): Observable<ICmsPage> {
        return this.toPage(
            from(
                this.client.getEntries({
                    content_type: this.cs.get('contentful.contentTypeIds.page'),
                    'fields.name': pathName
                })
            )
        );
    }

    private toPage(
        entries: Observable<EntryCollection<any>>
    ): Observable<ICmsPage> {
        return entries.pipe(
            map((page) => page.items[0]),
            map(
                (item: Entry<any>) =>
                    ({
                        ...item.fields,
                        formFields: item.fields.formFields && this.transformFormFields(item.fields.formFields)
                    } as ICmsPage)
            )
        );
    }

    private toGlobal(
        entries: Observable<EntryCollection<IGlobal>>
    ): Observable<IGlobal> {
        return entries.pipe(
            map((page) => page.items[0]),
            map(
                (item: Entry<any>) =>
                    ({
                        ...item.fields,
                        menus: (item.fields.menus as Entry<IMenuItem>[]).map((x) => x.fields)
                    } as IMenuItem)
            )
        );
    }

    private transformFormFields(x: Entry<IFormField>[]) {
        const obj = {};
        x.forEach((y: Entry<IFormField>) => {
            obj[y.fields.name] = y.fields;
        });
        return obj;
    }
}
