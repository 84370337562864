import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppState} from '@app/app.state';
import {APP_PAGE_ROUTES, MODAL_WINDOWS} from '@app/core/models/core.model';
import {AccountBasePageComponent} from '@app/core/pages/account-base-page/account-base-page.component';
import {AuthProviderService} from '@lib/services/authenticate/auth-provider.service';
import {ModalService} from '@lib/services/modal.service';
import {IEndPoint, QueryApiService} from '@lib/services/query-api.service';
import {StorageService} from '@lib/services/storage.service';
import {UtilService} from '@lib/services/util.service';
import {Store} from '@ngrx/store';
import {EnumType} from 'json-to-graphql-query';

enum PM_EDIT_TYPE {
    CANCEL = 1,
    EDIT,
    REMOVE
}

enum PROCESS_TYPE {
    ACH = 'ACH',
    DEBIT = 'DEBIT',
    NONE = 'NONE'
}
enum PAYMENT_METHOD_TYPE {
    DEBIT_CARD = 2,
    DEBIT_CARD_NAME = 'DEBIT',
    NONE = 'NONE'
}

enum PM_SUPPORTED_TYPE {
    FORM = 'form',
    THIRD_PARTY = 'thirdParty'
}

@Component({
    selector: 'app-payment-methods',
    templateUrl: './payment-methods.component.html',
    styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent extends AccountBasePageComponent implements OnInit, OnDestroy {
    paymentMethodOption: any;
    paymentMethodOptionValues: any;
    showNewForm = false;
    paymentMethods: any;
    loanId: number;
    accountTypeOption: any;
    fg: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(''),
        type: new UntypedFormControl('', Validators.required),
        routingOrCard: new UntypedFormControl('', Validators.required),
        accountNumber: new UntypedFormControl('', Validators.required),
        accountType: new UntypedFormControl('', Validators.required)
    });
    PAYMENT_METHOD_TYPE = PAYMENT_METHOD_TYPE;
    PM_SUPPORTED_TYPE = PM_SUPPORTED_TYPE;

    constructor(
        protected store$: Store<AppState>,
        protected storage: StorageService,
        protected authProvider: AuthProviderService,
        protected qs: QueryApiService,
        protected router: Router,
        private modal: ModalService
    ) {
        super(store$, storage, authProvider, qs, router);
    }

    ngOnInit(): void {
        super.getLoanData(APP_PAGE_ROUTES.PAYMENT_METHODS, this.getPaymentMethods.bind(this));
    }

    getPaymentMethods() {
        if (!this.page) {
            return false;
        }

        this.qs.getQuery({
            endPoint: IEndPoint.LMS_ENDPOINT,
            query: this.qs.getQueryBody('loan', this.page.extendedParams.queryConfig['getPaymentMethods']['loan'], {id: this.loanId})
        }).subscribe(res => {
            this.data = res.data.loan || [];
            this.paymentMethods = this.data.paymentMethods.items.filter(pm => pm.authorized);
            this.setOptions();
        });

        return true;
    }

    setOptions() {
        this.getLookupOptions({
            name: 'type',
            values: this.appConfig.paymentMethodTypes.filter(type => type.processType !== PAYMENT_METHOD_TYPE.NONE),
            target: 'paymentMethodOption',
            filterBy: 'display',
            return: 'id'
        });

        this.getLookupOptions({
            name: 'accountType',
            values: this.page.formFields['accountType']?.options,
            target: 'accountTypeOption'
        });

        this.paymentMethodOptionValues = UtilService.arrayToObjectByKey(this.paymentMethodOption.option.values, 'id') || {};
    }

    getType(pm: any | null) : PM_SUPPORTED_TYPE | null {
        if (!pm) return null;
        return pm.processType == PROCESS_TYPE.ACH ? PM_SUPPORTED_TYPE.FORM : PM_SUPPORTED_TYPE.THIRD_PARTY;
    }

    getLookupOptions(obj: {name: string, values: any, target: any, filterBy?: string, return?: string}) {
        this[obj.target] = {
            ...this.page.formFields[obj.name],
            option: {
                allowCustom: false,
                minSearchLength: 0,
                filterBy: obj.filterBy || "value",
                return: obj.return || "key",
                isFilterable: false,
                values: obj.values
            }
        };
    }

    /**
     * PaymentMethod differs from record to schedule payment
     * Record Payment - PaymentMethodType
     * Schedule Payment - PaymentMethodId
     *
     * @param action
     * @param actions
     */
    onSubmit(pm?: any) {
        this.loading = true;

        if (this.getType(this.paymentMethodOptionValues[this.fg.controls['type'].value]) === PM_SUPPORTED_TYPE.THIRD_PARTY && !pm?.editMode) {
            this.requestThirdPartyForm();
            return false;
        }

        if (this.fg.invalid) {
            this.loading = false;
            this.fg.markAllAsTouched();
            this.fg.updateValueAndValidity();
            return false;
        }

        const input = {
            paymentMethodCollectionId: this.data.paymentMethodCollectionId,
            name: this.fg.controls['name'].value || '',
            paymentMethodTypeId: this.fg.controls['type'].value,
            routingNumber: this.fg.controls['routingOrCard'].value,
            accountNumber: this.fg.controls['accountNumber'].value,
            accountType: new EnumType(this.fg.controls['accountType'].value?.toUpperCase())
        };

        if (!pm) {
            const mutationName = 'addBankPaymentMethod';
            this.qs.doMutation({
                endPoint: IEndPoint.LMS_ENDPOINT,
                payload: this.qs.getMutationBody(mutationName, this.page.extendedParams.queryConfig.mutation[mutationName], {input})
            }).subscribe(res => {
                this.data = null;
                this.getPaymentMethods();
                this.toggleNewForm();
                this.loading = false;
            }, error => {
                this.loading = false;
            });
        } else {
            pm.editMode = false;
            pm.editing = true;
            this.loading = false;
            this.updatePm(pm, PM_EDIT_TYPE.EDIT);
        }

        return true;
    }

    toggleNewForm(pm?: any) {
        this.loading = false;
        if (pm) {
            pm.editMode = false;
            this.resetForm();
            return false;
        }
        this.showNewForm = !this.showNewForm;
        if (this.showNewForm) {
            this.resetForm();
        }
        return true;
    }

    resetForm() {
        this.fg.reset();
        this.fg.enable();
        this.fg.setErrors(null);
        this.fg.markAsPristine();
        this.fg.markAsUntouched();
        this.fg.updateValueAndValidity();
    }

    deAuthorize(pm) {
        if (pm.removing) {
            return false;
        }
        pm.removing = true;
        const config = {
            actions: {
                primary: this.updatePm.bind(this, pm),
                close: this.updatePm.bind(this, pm, PM_EDIT_TYPE.CANCEL)
            }
        };
        this.modal.show(MODAL_WINDOWS.DEAUTHORIZE_PAYMENT_METHOD, config);
        return true;
    }

    /**
     * Same method for cancel and remove
     * @param pm
     * @param isCancel
     */
    updatePm(pm, type?: number) {
        if (type === PM_EDIT_TYPE.CANCEL) {
            pm.removing = false;
            this.resetForm();
            return false;
        }
        this.modal.close();

        const input = {
            paymentMethodCollectionId: this.data.paymentMethodCollectionId,
            paymentMethodId: pm.id
        };

        if (type === PM_EDIT_TYPE.EDIT) {
            input['authorized'] = pm.authorized;
            input['name'] = this.fg.controls['name'].value;
        } else {
            input['authorized'] = !pm.authorized;
            input['name'] = pm.name;
        }

        const mutationName = 'updatePaymentMethod';
        this.qs.doMutation({
            endPoint: IEndPoint.LMS_ENDPOINT,
            payload: this.qs.getMutationBody(mutationName, this.page.extendedParams.queryConfig.mutation[mutationName], {input})
        }).subscribe(res => {
            if (type === PM_EDIT_TYPE.EDIT) {
                pm.name = this.fg.controls['name'].value;
                pm.editing = false;
            } else {
                this.paymentMethods = UtilService.removeItemFromList(this.paymentMethods, 'id', pm.id);
                pm.removing = false;
            }
        }, error => {
            pm.editing = false;
            pm.removing = false;
        });
        return true;
    }

    editMode(pm) {
        pm.editMode = true;
        this.fg.controls['name'].setValue(pm.name);
        this.fg.controls['type'].setValue(pm.paymentMethodTypeId);
        this.fg.controls['routingOrCard'].setValue(pm.routingOrCard);
        this.fg.controls['accountNumber'].setValue("****" + pm.accountNumber.slice(-4));
        this.fg.controls['accountType'].setValue(pm.accountType);
        this.fg.disable();
        this.fg.controls['name'].enable();
    }

    requestThirdPartyForm() {
        const input = {
            paymentMethodCollectionId: this.data.paymentMethodCollectionId,
            processType: new EnumType(this.paymentMethodOptionValues[this.fg.controls['type'].value].processType),
            customer: null
        };

        const mutationName = 'createCheckoutForm';
        this.qs.doMutation({
            endPoint: IEndPoint.LMS_ENDPOINT,
            payload: this.qs.getMutationBody(mutationName, this.page.extendedParams.queryConfig.mutation[mutationName], {input})
        }).subscribe(res => {
            const url = res.data.createCheckoutForm.url;
            const config = {
                styleClass: 'iframe-modal',
                url,
                actions: {
                    primary: this.onDebitFormResponse.bind(this),
                    close: this.closeDebitForm.bind(this)
                }
            };
            this.modal.show(MODAL_WINDOWS.CHECKOUT_FORM, config);
        }, error => {
            this.loading = false;
        });

    }

    closeDebitForm() {
        this.loading = false;
        this.modal.close();
    }

    onDebitFormResponse(message) {
        this.loading = false;
        switch (message) {
            case 'PAYMENT_SUCCESS':
                this.paymentMethods = this.paymentMethods || [];
                this.paymentMethods.push({
                    name: UtilService.transformFirstLetterUpperCase(this.fg.controls['name'].value || ''),
                    paymentMethodType: {
                        display: this.paymentMethodOption?.options?.filter(option => {
                            return option.processType === PAYMENT_METHOD_TYPE.DEBIT_CARD_NAME;
                        })[0].display
                    },
                    paymentMethodTypeId: PAYMENT_METHOD_TYPE.DEBIT_CARD
                });
                this.showNewForm = false;
                break;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.page = null;
        this.loading = false;
        this.loanId = null;
        this.data = null;
    }

}
