import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../base-component/base-form.component';

@Component({
  selector: 'app-section-heading',
  templateUrl: './section-heading.component.html',
  styleUrls: ['./section-heading.component.scss']
})
export class SectionHeadingComponent extends BaseFormComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
