<ng-container *ngIf="page">
    <h1 *ngIf="page.title">{{page.title}}</h1>
    <lib-spinner *ngIf="!(page.appId || noLoansFound)"></lib-spinner>
    <div class="account-overview no-loans" *ngIf="noLoansFound && page.title">
        <markdown [data]="page.kicker" *ngIf="page.kicker"></markdown>
    </div>
    <lib-data-table [cms]="page"
                    [endPoint]="iEndPoint.LMS_ENDPOINT"
                    *ngIf="page.appId"></lib-data-table>
    <div class="make-payment">
        <lib-button
                [label]="page.formFields['makePayment'].label"
                [klass]="page.formFields['makePayment'].klass"
                (click)="makePayment()"
                (keyup.enter)="makePayment()"
                *ngIf="page.formFields['makePayment']"
        ></lib-button>
    </div>
    <markdown [data]="page.subDescription" class="description"></markdown>
</ng-container>
