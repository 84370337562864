import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {BaseModalComponent} from '@lib/components/base-modal/base-modal.component';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'app-iframe-modal',
    templateUrl: './iframe-modal.component.html',
    styleUrls: ['./iframe-modal.component.scss']
})
export class IframeModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    status: string;
    origin: string;
    frameLoadComplete = false;

    /**
     * PAYMENT_SUCCESS - event received on success of vendor complete
     * LOS-CHECKOUT-FORM-COMPLETE - event received on close of DONE from vendor success page
     * @param e
     */
    @HostListener('window:message', ['$event'])
    onMessage(e) {
        if (['PAYMENT_SUCCESS', 'LOS-CHECKOUT-FORM-COMPLETE'].includes(e?.data)) {
            this.status = e?.data;
            this.config.data.data.actions.primary(e?.data);
            if ('LOS-CHECKOUT-FORM-COMPLETE' === this.status) {
                this.closeModal();
            }
        }

        if (e.origin !== this.origin) {
            return false;
        }
        const message = e.data;
        console.log("Status from checkout form:: " + message);
        // this.config.data.data.actions.primary(message);
        return true;
    }

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
        super(ref, config);
    }

    ngOnInit(): void {
        this.status = null;
        const url = this.config.data.data.url;
        this.origin = 'https://' + url.replace('https://', '').split('/')[0];
    }

    closeModal() {
        this.config.data.data.actions.close();
    }

    frameLoaded(complete) {
        this.frameLoadComplete = complete;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.config = null;
        this.frameLoadComplete = false;
    }
}
