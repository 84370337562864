import {Component, Input, OnInit} from '@angular/core';
import {BaseFormComponent} from '@lib/components/form/base-component/base-form.component';

@Component({
    selector: 'lib-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent extends BaseFormComponent implements OnInit {
    @Input() template: string;
    @Input() model: any;
    @Input() cms: any;
    tabs: string[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.tabs = Object.keys(this.model.data);
    }

}
