import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {UtilService} from '../../../services/util.service';
import {BaseFormComponent} from '../base-component/base-form.component';

/**
 * Date component input is and ISO date string format
 * UI always displays mm/dd/yy format
 */
@Component({
    selector: 'lib-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent extends BaseFormComponent implements OnInit, OnChanges {
    @Input() date = undefined;
    defaultDate: any;
    @Input() minDate: any;
    @Input() maxDate: any;
    dateControl;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.dateControl = this.group.controls[this.item.name];
        if (this.dateControl?.value) {
            this.date = UtilService.displayDate(this.dateControl.value, this.item.format || 'MM/dd/yyyy');
        }

        if (this.item.validations) {
            if (this.item.validations['min']?.value || this.item.validations['max']?.value) {
                //this.minDate = new Date(UtilService.displayDate(this.item.validations['min']?.value || '0001-01-01', this.item.format || 'MM/dd/yyyy'));
                this.minDate = new Date(this.item.validations['min']?.value || '0001-01-01');
            }
            else this.minDate = null;
            if (this.item.validations['max']?.value || this.item.validations['min']?.value) {
                //this.maxDate = new Date(UtilService.displayDate(this.item.validations['max']?.value || '9999-12-31', this.item.format || 'MM/dd/yyyy'));
                this.maxDate = new Date(this.item.validations['max']?.value || '9999-12-31');
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['minDate'] || changes['maxDate']) {
            if (changes['minDate']?.currentValue) {
                this.minDate = new Date(changes['minDate'].currentValue);
            }
            if (changes['maxDate']?.currentValue) {
                this.maxDate = new Date(changes['maxDate'].currentValue);
            }

            if (this.minDate) {
                this.defaultDate = this.minDate;
            }
        }
    }

    /**
     * Invalida date, input is not empty but this.date
     * if Input field is empty - required field
     * @param e
     */
    onBlur(e) {
        if (!e.target.value || !this.date) {
            this.dateControl.setValue(null);
            this.dateControl.markAsTouched();
            this.dateControl.updateValueAndValidity();
        }
    }

    /**
     * calls only when full allowed date is selected / entered
     * Clears on an bad date - format or non-allowed date
     */
    onModalChange() {
        if (this.date && typeof this.date === 'object') {
            this.dateControl.setValue(UtilService.formatDate(this.date));
        }
        this.dateControl.markAsTouched({onlySelf: true});
        this.dateControl.updateValueAndValidity();
    }

    onClear(e) {
        e.preventDefault();
    }

}
